import React, { useState } from "react";
import Delete from "assets/delete.svg";
import { IconBtn } from "components/Buttons";
import { D1 } from "components/Typography";
import RatingInput from "components/ListRatingInput";
import { admin_talent_overview as $ } from "strings";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import {
  postTalentQualification,
  patchTalentQualification
} from "utils/adminApi";
import AlertError from "components/AlertError";
import { useAlert } from "react-alert";
import { Button } from "pages/talent/components";
import DeleteQualificationModal from "./DeleteQualificationModal";

/**
 * QualificationItem component displays a single qualification
 */
const QualificationItem = ({
  q,
  selected,
  showSelfRatings,
  checkExists,
  user,
  setDirty
}) => {
  const alert = useAlert();
  const queryClient = useQueryClient();
  const [qualificationIsSaving, setQualificationIsSaving] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const qualificationsQueryKey = ["talent_qualifications", user];

  const updateRatingMutation = useMutation({
    mutationFn: ({ id, rating_obj }) =>
      patchTalentQualification(id, rating_obj),
    onMutate: async ({ id, rating_obj }) => {
      // Cancel any outgoing refetches
      await queryClient.cancelQueries({ queryKey: qualificationsQueryKey });

      // Snapshot the previous value
      const previousQualifications = queryClient.getQueryData(
        qualificationsQueryKey
      );
      // Optimistically update to the new value
      queryClient.setQueryData(qualificationsQueryKey, (old) => {
        return {
          ...old,
          results: old.results.map((q) => {
            if (q.id === id) {
              return {
                ...q,
                ...(rating_obj.rsu_rating !== undefined && {
                  rsu_rating: rating_obj.rsu_rating
                }),
                ...(rating_obj.talent_rating !== undefined && {
                  talent_rating: rating_obj.talent_rating
                })
              };
            }
            return q;
          })
        };
      });

      // Return a context with the snapshot
      return { previousQualifications };
    },
    onError: (err, variables, context) => {
      // Roll back to the previous value
      queryClient.setQueryData(
        qualificationsQueryKey,
        context.previousQualifications
      );
      alert.error(
        <AlertError
          error={err}
          onRetry={() => updateRatingMutation.mutate(variables)}
        />
      );
    }
  });

  // Add qualification mutation - we don't optimistically add, because it needs to
  // finish saving before the user can actually set any ratings for it.
  const addQualificationMutation = useMutation({
    mutationFn: (qualification) =>
      postTalentQualification({
        user: user,
        qualification: qualification.id
      }),
    onMutate: async (qualification) => {
      setQualificationIsSaving(true);
      setDirty(true);
    },
    onSuccess: (response, variables) => {
      setDirty(false);
    },
    onError: (err, variables, context) => {
      alert.error(
        <AlertError
          error={err}
          onRetry={() => addQualificationMutation.mutate(variables)}
        />
      );
    },
    onSettled: () => {
      setQualificationIsSaving(false);
      queryClient.invalidateQueries(qualificationsQueryKey);
    }
  });

  const handleAdd = async () => {
    if (!checkExists(q.name)) {
      await addQualificationMutation.mutate(q);
    }
  };

  const onRatingChange = async (id, rating_obj) => {
    await updateRatingMutation.mutateAsync({ id, rating_obj });
  };

  return (
    <>
      <div className="flex w-full items-center">
        <div className="h-2.5 flex-1 text-left text-sm font-bold text-midnight">
          {q.name}
        </div>

        {selected && (
          <div className="flex flex-1 flex-wrap items-center text-kasmir">
            <div className="flex items-center">
              <D1>{$.rsu_rating_label}</D1>:{" "}
              <RatingInput
                color={q.rsu_rating ? "#F6D353" : "#D8DDE6"}
                value={q.rsu_rating}
                onChange={(v) => onRatingChange(q.id, { rsu_rating: v })}
              />
            </div>
            {showSelfRatings && (
              <>
                /
                <div className="flex items-center">
                  <D1>{$.self_rating_label}</D1>:{" "}
                  <RatingInput
                    color={q.talent_rating ? "#F6D353" : "#D8DDE6"}
                    value={q.talent_rating}
                    onChange={(v) => onRatingChange(q.id, { talent_rating: v })}
                  />
                </div>
              </>
            )}
          </div>
        )}
        <div className="ml-auto">
          {selected ? (
            <IconBtn
              bgColor="bg-lightest-grey"
              onClick={() => setShowDeleteModal(true)}
              disabled={qualificationIsSaving}
            >
              <img src={Delete} height={15} width={15} alt="delete" />
            </IconBtn>
          ) : (
            <Button cancel onClick={handleAdd} disabled={qualificationIsSaving}>
              {qualificationIsSaving ? "Saving..." : "Add"}
            </Button>
          )}
        </div>
      </div>
      {showDeleteModal && (
        <DeleteQualificationModal
          onClose={() => setShowDeleteModal(false)}
          qualificationId={q.id}
        />
      )}
    </>
  );
};

export default QualificationItem;

import { useRef, useState, useEffect } from "react";
import { Dropdown } from "components/Form";
import { components } from "react-select";
import clsx from "clsx";
import UilCheck from "@iconscout/react-unicons/icons/uil-check";

/**
 * ValueContainer is a helper for CheckboxMultiSelect. This is what
 * renders the selected values in the always visible input.
 */
const ValueContainer = ({ children, ...props }) => {
  const { getValue, hasValue } = props;
  const selectedValues = getValue();

  const handleClick = (e) => {
    e.stopPropagation();
    props.selectProps.menuIsOpen
      ? props.selectProps.onMenuClose()
      : props.selectProps.onMenuOpen();
  };

  // If no values are selected, render the default placeholder/input
  if (!hasValue) {
    return (
      <components.ValueContainer {...props}>
        <div onClick={handleClick} className="w-full min-w-0 cursor-pointer">
          {children}
        </div>
      </components.ValueContainer>
    );
  }

  // Join the labels with commas
  const displayText = selectedValues.map((val) => val.label).join(", ");

  return (
    <components.ValueContainer {...props}>
      <div
        onClick={handleClick}
        className="min-w-0 max-w-full cursor-pointer truncate text-sm text-midnight"
      >
        {displayText}
      </div>
    </components.ValueContainer>
  );
};

/**
 *  MutliSelectOption is a helper for CheckboxMultiSelect. It displays the 
 *  options with the checkboxes in the drop down. 
 */
const MultiSelectOption = ({ children, ...props }) => {
    return (
      <div
        className={clsx(
          "cursor-pointer whitespace-nowrap bg-white px-1 py-1 text-sm hover:bg-lightest-grey",
          props.isFocused && "bg-lightest-grey",
          props.isDisabled
            ? "text-grey"
            : props.isSelected
              ? "text-electric-indigo"
              : "text-kasmir"
        )}
        {...props.innerProps}
      >
        <div className="flex w-full min-w-32 items-center justify-between font-lato text-sm">
          {children}
          {props.isSelected && <UilCheck />}
        </div>
      </div>
    );
};

/**
 * CheckboxMultiSelect displays a dropdown select menu. The selected values are
 * displayed as a comma separated list in the main, always visible select.
 * 
 * On opening the dropdown, all the options are displayed with checkboxes next to
 * them. Selecting and item from the dropdown toggles it, and the checkbox reflects
 * the selection state.
 * 
 * It treats something with the value of "all" as a blessed value and has special handling 
 * if "all" is included in the options.
 */
export const CheckboxMultiSelect = ({ options, onChange, onBlur, value }) => {
  const wrapperRef = useRef(null);
  const [localValue, setLocalValue] = useState(value ?? []);

  useEffect(() => {
    setLocalValue(value);
  }, [value]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        if (localValue !== value) {
          onChange(localValue);
        }
        onBlur();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [localValue, value, onChange, onBlur]);

  return (
    <div ref={wrapperRef} className="w-full">
      <Dropdown
        isMulti
        options={options}
        value={options.filter((o) => localValue?.includes(o.value))}
        hideSelectedOptions={false}
        closeMenuOnSelect={false}
        className="text-sm"
        customStyles={{
          menu: (provided) => ({
            ...provided,
            minWidth: "max-content",
            width: "auto"
          })
        }}
        onBlur={() => {
          if (localValue !== value) {
            onChange(localValue);
          }
          onBlur();
        }}
        onChange={(selectedOptions) => {
          // Handle "all" option
          const hasAll = selectedOptions?.some((opt) => opt.value === "all");
          const hadAll = localValue?.includes("all");

          if (hasAll && !hadAll) {
            // If "all" was selected, select all options
            setLocalValue(options.map((o) => o.value));
          } else if (!hasAll && hadAll) {
            // If "all" was deselected, clear all
            setLocalValue([]);
          } else {
            // Normal selection/deselection
            setLocalValue(selectedOptions?.map((opt) => opt.value) ?? []);
          }
        }}
        components={{
          Option: MultiSelectOption,
          ValueContainer,
          MultiValue: () => null, // This prevents individual MultiValue components from rendering
          ClearIndicator: () => null // This removes the clear indicator
        }}
      />
    </div>
  );
};

import React, { useContext, useEffect, useState } from "react";
import { TalentContext } from "../../TalentDataContext";
import Select from "components/Select";
import { fetchOwners } from "utils/fetch";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
  deleteTalentOwner,
  getTalentOwners,
  postTalentOwner
} from "utils/adminApi";
import AlertError from "components/AlertError";
import { ElementWithClickOutsideHandler } from "components_v2/EditableField";
import { useAlert } from "react-alert";

export const TalentOwnersSelect = () => {
  const queryClient = useQueryClient();
  const alert = useAlert();
  const { id } = useContext(TalentContext);
  const [localOwners, setLocalOwners] = useState([]);
  const [editting, setEditting] = useState(false);

  const { data } = useQuery({
    queryKey: ["adminTalentOwners", id],
    queryFn: () => getTalentOwners(id)
  });

  useEffect(() => {
    if (!data || !data.results) {
      return;
    }

    setLocalOwners(
      data.results.map((o) => {
        return {
          label: `${o.owner.first_name} ${o.owner.last_name}`,
          value: o.owner.id,
          owner: o
        };
      })
    );
  }, [data]);

  const deleteTalentOwnerMutation = useMutation({
    mutationFn: (ownerId) => deleteTalentOwner(ownerId),
    onError: (err, vars) => {
      alert.error(
        <AlertError
          error={err}
          onRetry={() => deleteTalentOwnerMutation.mutate(vars)}
        />
      );
    }
  });

  const addTalentOwnerMutation = useMutation({
    mutationFn: (owner) => postTalentOwner({ talent: id, owner: owner.value }),
    onError: (err, newOwner) => {
      alert.error(
        <AlertError
          error={err}
          onRetry={() => addTalentOwnerMutation.mutate(newOwner)}
        />
      );
    }
  });

  if (!editting) {
    if (localOwners?.length > 0) {
      return (
        <div
          onClick={() => setEditting(true)}
          className="text-sm font-bold text-midnight"
        >
          {localOwners.map((o) => o.label).join(", ")}
        </div>
      );
    }
    return (
      <div
        onClick={() => setEditting(true)}
        className="text-sm font-bold text-kasmir underline"
      >
        Add Talent Owner
      </div>
    );
  }

  const onSave = async () => {
    // Get current owners from the data to compare with localOwners
    if (!data || !data.results) {
      setEditting(false);
      return;
    }

    // Identify owners to delete (in data.results but not in localOwners)
    const ownersToDelete = data.results.filter(
      (existingOwner) =>
        !localOwners.some(
          (localOwner) => localOwner.value === existingOwner.owner.id
        )
    );

    // Identify owners to add (in localOwners but not in data.results)
    const ownersToAdd = localOwners.filter(
      (localOwner) =>
        !data.results.some(
          (existingOwner) => existingOwner.owner.id === localOwner.value
        )
    );

    for (const ownerToDelete of ownersToDelete) {
      await deleteTalentOwnerMutation.mutateAsync(ownerToDelete.id);
    }

    for (const ownerToAdd of ownersToAdd) {
      await addTalentOwnerMutation.mutateAsync(ownerToAdd);
    }

    // Invalidate the query to refresh the data
    queryClient.invalidateQueries(["adminTalentOwners", id]);

    setEditting(false);
  };

  // Return a multiselect of the owners
  if (editting) {
    return (
      <ElementWithClickOutsideHandler onClickOutside={onSave}>
        <Select
          placeholder="Add Talent Owner"
          async
          isMulti
          hideSelectedOptions
          value={localOwners}
          onChange={(newValues) => {
            // Filter out any duplicate owners by value (owner.id)
            const uniqueValues = [];
            const seenValues = new Set();

            for (const item of newValues) {
              if (!seenValues.has(item.value)) {
                seenValues.add(item.value);
                uniqueValues.push(item);
              }
            }

            setLocalOwners(uniqueValues);
          }}
          loadOptions={fetchOwners}
          components={{
            ClearIndicator: () => null // This removes the clear indicator
          }}
        />
      </ElementWithClickOutsideHandler>
    );
  }
};

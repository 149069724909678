import React, { useEffect, useState } from "react";
import clsx from "clsx";
import $ from "strings/talent";
import { Checkbox, Dropdown, Input } from "components/Form";
import {
  patchTalentTalent,
  patchTalentUser,
  patchTalentUserContactInfo,
  patchTalentUserLink,
  postTalentUserLink,
  getTalentDataFromUrlSuffix
} from "utils/api";
import {
  defaultCountries,
  parseCountry,
  PhoneInput
} from "react-international-phone";
import LocationInput from "components/LocationInput";
import { Form, Formik, useFormikContext } from "formik";
import * as yup from "yup";
import { SettingsActionBar } from "../components";
import { FormElement } from "./components";
import { PhoneNumberUtil } from "google-libphonenumber";

const phoneUtil = PhoneNumberUtil.getInstance();
const urlRegExp =
  /[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/gi;

const PRONOUNS_OPTIONS = [
  { value: null, label: <>&nbsp;</> },
  { value: "he/him", label: "He/Him" },
  { value: "she/her", label: "She/Her" },
  { value: "they/them", label: "They/Them" }
];

export const COMMUNICATION_PREFERENCES_OPTIONS = [
  { label: $.email_option, value: "email" },
  { label: $.text_option, value: "text" },
  { label: $.phone_option, value: "phone" }
];

const EMPTY_SCHEDULING_LINK = { id: "", url: "", name: "Scheduling Link" };

const parseCountryName = (countryName) => {
  let country = defaultCountries.find((c) => c[0] === countryName);
  if (country) {
    return parseCountry(country);
  } else {
    return null;
  }
};

const getSchedulingLink = (talentData) => {
  if (!talentData) return EMPTY_SCHEDULING_LINK;

  for (const link of talentData.user.user_links) {
    if (link.name === EMPTY_SCHEDULING_LINK.name) {
      return link;
    }
  }
  return EMPTY_SCHEDULING_LINK;
};

const EMPTY_FORM_STATE = {
  firstName: "",
  lastName: "",
  preferredName: "",
  pronouns: "",
  phone: "+1",
  schedulingLink: "",
  communicationPreferences: "",
  city: "",
  country: "",
  fullAddress: "",
  state: "",
  stateCode: "",
  street: "",
  streetNumber: "",
  timezoneId: "",
  timezoneName: "",
  zipcode: ""
};

const formatPhoneWithCountryCode = (phoneNumber, country) => {
  if (!phoneNumber) return "+1";
  if (phoneNumber.startsWith("+")) return phoneNumber;

  const countryData = parseCountryName(country);
  return (`+${countryData?.dialCode} ` || "+1 ") + phoneNumber;
};

const getInitialValues = (talentData) => {
  if (!talentData) {
    return EMPTY_FORM_STATE;
  }

  const talentSchedulingLink = getSchedulingLink(talentData);

  // Format phone number with country code before setting initial values
  const formattedPhone = formatPhoneWithCountryCode(
    talentData.user.user_contact_info.phone_number,
    talentData.user.user_contact_info.country
  );

  return {
    firstName: talentData.user.first_name || "",
    lastName: talentData.user.last_name || "",
    preferredName: talentData.user.preferred_name || "",
    pronouns: talentData.user.pronouns || "",
    phone: formattedPhone,
    schedulingLink: talentSchedulingLink.url,
    communicationPreferences: talentData.communication_preferences || "",
    city: talentData.user.user_contact_info.city,
    country: talentData.user.user_contact_info.country,
    fullAddress: talentData.user.user_contact_info.full_address,
    state: talentData.user.user_contact_info.state,
    stateCode: talentData.user.user_contact_info.state_code,
    street: talentData.user.user_contact_info.street,
    streetNumber: talentData.user.user_contact_info.street_number,
    timezoneId: talentData.user.user_contact_info.timezone_id,
    timezoneName: talentData.user.user_contact_info.timezone_name,
    zipcode: talentData.user.user_contact_info.zipcode
  };
};

const ContactInformation = ({
  talentData,
  setTalentData,
  onUpdate,
  setShowNavPrompt
}) => {
  const [isUpdating, setIsUpdating] = useState(false);
  const userId = talentData?.user?.id ?? "";
  const schedulingLinkId = getSchedulingLink(talentData).id;

  // When talent data changes, this component will automatically re-render
  // with the new data. We don't need to manage it with state.
  const initialValues = getInitialValues(talentData);

  const PromptUpdater = () => {
    const { dirty } = useFormikContext();
    useEffect(() => {
      const pageUrl = window.location.href;
      if (typeof dirty !== "undefined") {
        if (dirty === true && pageUrl.includes("tab=contact")) {
          setShowNavPrompt(true);
        } else {
          setShowNavPrompt(false);
        }
      }
    }, [dirty]);

    return null;
  };

  const onSave = async (values) => {
    const changedValues = Object.keys(values).filter(
      (key) => values[key] !== initialValues[key]
    );
    const talentBody = {};
    const userBody = {};
    const userContactInfoBody = {};
    const userLinksBody = {};
    for (const key of changedValues) {
      switch (key) {
        case "firstName":
          userBody.first_name = values.firstName;
          break;
        case "lastName":
          userBody.last_name = values.lastName;
          break;
        case "preferredName":
          userBody.preferred_name = values.preferredName;
          break;
        case "pronouns":
          userBody.pronouns = values.pronouns;
          break;
        case "phone":
          userContactInfoBody.phone_number = values.phone;
          break;
        case "schedulingLink":
          userLinksBody.url = values.schedulingLink;
          break;
        case "communicationPreferences":
          talentBody.communication_preferences =
            values.communicationPreferences;
          break;
        case "city":
          userContactInfoBody.city = values.city;
          break;
        case "country":
          userContactInfoBody.country = values.country;
          break;
        case "fullAddress":
          userContactInfoBody.full_address = values.fullAddress;
          break;
        case "state":
          userContactInfoBody.state = values.state;
          break;
        case "stateCode":
          // state code must be 5 characters or less
          userContactInfoBody.state_code =
            values.stateCode?.length || 0 > 5 ? "" : values.stateCode;
          break;
        case "street":
          userContactInfoBody.street = values.street;
          break;
        case "streetNumber":
          userContactInfoBody.street_number = values.streetNumber;
          break;
        case "timezoneId":
          userContactInfoBody.timezone_id = values.timezoneId;
          break;
        case "timezoneName":
          userContactInfoBody.timezone_name = values.timezoneName;
          break;
        case "zipcode":
          userContactInfoBody.zipcode = values.zipcode;
          break;
        default:
          break;
      }
    }
    if (Object.keys(talentBody).length > 0) {
      await patchTalentTalent(userId, talentBody);
    }
    if (Object.keys(userBody).length > 0) {
      await patchTalentUser(userId, userBody);
    }
    if (Object.keys(userContactInfoBody).length > 0) {
      await patchTalentUserContactInfo(userId, userContactInfoBody);
    }
    if (Object.keys(userLinksBody).length > 0) {
      userLinksBody.name = EMPTY_SCHEDULING_LINK.name;
      if (schedulingLinkId) {
        await patchTalentUserLink(schedulingLinkId, userLinksBody);
      } else {
        await postTalentUserLink(userLinksBody);
      }
    }
    await getTalentDataFromUrlSuffix(talentData.url_suffix).then(setTalentData);
    onUpdate();
  };

  const validationSchema = yup.object().shape({
    firstName: yup.string().required("Required"),
    lastName: yup.string().required("Required"),
    phone: yup
      .string()
      .required("Phone number required")
      .test("phone", "Must be a valid phone number", (phone) => {
        if (phone.length < 5) {
          return false;
        }
        try {
          phoneUtil.isValidNumber(phoneUtil.parseAndKeepRawInput(phone));
          return true;
        } catch (e) {
          return false;
        }
      }),
    schedulingLink: yup.string().matches(urlRegExp, "Enter a valid URL"),
    communicationPreferences: yup
      .string()
      .required("Select at least one option")
  });

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      validateOnMount={false}
      validateOnChange={false}
      validateOnBlur={false}
      onSubmit={() => {}}
      enableReinitialize={true}
    >
      {({
        setFieldValue,
        values,
        touched,
        setTouched,
        setFieldTouched,
        errors,
        setFieldError,
        validateForm
      }) => {
        const onChange = async (e) => {
          const { name, value } = e.target;
          await setFieldValue(name, value);
          await setFieldTouched(name, value !== initialValues[name]);
          if (errors[name]) {
            setFieldError(name, null);
          }
        };
        const onCheckboxClick = async (fieldName, option, value) => {
          const currentValues = values[fieldName];
          let currentValuesArr = currentValues?.length
            ? currentValues.split("|")
            : [];
          if (value === true && currentValuesArr.indexOf(option) === -1) {
            currentValuesArr.push(option);
            if (errors[fieldName]) {
              setFieldError(fieldName, null);
            }
          } else if (value === false && currentValuesArr.indexOf(option) > -1) {
            currentValuesArr.splice(currentValuesArr.indexOf(option), 1);
          }
          const newValues = currentValuesArr.sort().join("|");
          await setFieldValue(fieldName, newValues);
          await setFieldTouched(
            fieldName,
            newValues !== initialValues[fieldName]
          );
        };
        return (
          <Form className="form">
            <div className="grid grid-cols-1 gap-1 md:grid-cols-2 md:gap-x-4 md:gap-y-6">
              <FormElement label={$.first_name_label} errors={errors.firstName}>
                <Input
                  name="firstName"
                  value={values.firstName}
                  error={errors.firstName}
                  onChange={onChange}
                  disabled={isUpdating}
                />
              </FormElement>
              <FormElement label={$.last_name_label} errors={errors.lastName}>
                <Input
                  name="lastName"
                  value={values.lastName}
                  error={errors.lastName}
                  onChange={onChange}
                  disabled={isUpdating}
                />
              </FormElement>
              <FormElement
                label={$.preferred_first_name_label}
                sublabel={$.preferred_first_name_helper}
                errors={errors.preferredName}
              >
                <Input
                  name="preferredName"
                  value={values.preferredName}
                  error={errors.preferredName}
                  onChange={onChange}
                  disabled={isUpdating}
                />
              </FormElement>
              <FormElement
                label={$.preferred_pronouns_label}
                sublabel={$.preferred_pronouns_helper}
                errors={errors.pronouns}
              >
                <Dropdown
                  name="pronouns"
                  placeholder="Select pronouns"
                  options={PRONOUNS_OPTIONS}
                  value={values.pronouns}
                  error={errors.pronouns}
                  onChange={(e) =>
                    onChange({ target: { name: "pronouns", value: e.value } })
                  }
                  className="font-bold"
                  disabled={isUpdating}
                />
              </FormElement>
              <FormElement label={$.phone_label} errors={errors.phone}>
                <PhoneInput
                  name="phone"
                  className={clsx(
                    "text-base",
                    !!errors["phone"] ? "border-2 border-red" : ""
                  )}
                  value={values.phone}
                  defaultCountry="us"
                  preferredCountries={["us", "ca"]}
                  placeholder="Enter your phone number"
                  onChange={async (phone) => {
                    await onChange({
                      target: { name: "phone", value: phone || "+1" }
                    });
                  }}
                  disabled={isUpdating}
                />
              </FormElement>
              <FormElement label={$.location_label} errors={errors.fullAddress}>
                <LocationInput
                  name="fullAddress"
                  disabled={isUpdating}
                  defaultFullAddress={values.fullAddress}
                  onSave={(val) => {
                    setFieldValue("fullAddress", val.full_address).then();
                    setFieldValue("city", val.city).then();
                    setFieldValue("country", val.country).then();
                    setFieldValue("state", val.state).then();
                    setFieldValue("stateCode", val.state_code).then();
                    setFieldValue("street", val.street).then();
                    setFieldValue("streetNumber", val.street_number).then();
                    setFieldValue("timezoneId", val.timezone_id).then();
                    setFieldValue("timezoneName", val.timezone_name).then();
                    setFieldValue("zipcode", val.zipcode).then();
                    onChange({
                      target: { name: "fullAddress", value: val.full_address }
                    }).then();
                    const swapPhone =
                      !values.phone ||
                      defaultCountries.find(
                        (c) => c[2] === values.phone.trim().replace("+", "")
                      );
                    if (swapPhone) {
                      let c = parseCountryName(val.country);
                      if (c) {
                        const dialCode = c.dialCode;
                        setFieldValue("phone", dialCode).then();
                      }
                    }
                  }}
                />
              </FormElement>
              <FormElement
                label={$.scheduling_link_label}
                sublabel={$.scheduling_link_helper}
                errors={errors.schedulingLink}
              >
                <Input
                  name="schedulingLink"
                  value={values.schedulingLink}
                  error={errors.schedulingLink}
                  onChange={onChange}
                  disabled={isUpdating}
                />
              </FormElement>
              <div className="col-start-1">
                <FormElement
                  label={$.preferred_communication_method_label}
                  errors={errors.communicationPreferences}
                >
                  {COMMUNICATION_PREFERENCES_OPTIONS.map((option, i) => (
                    <div className="mb-2 flex" key={i}>
                      <Checkbox
                        outlined
                        key={option.value}
                        value={
                          !!values["communicationPreferences"]?.includes(
                            option.value
                          )
                        }
                        onChange={(v) =>
                          onCheckboxClick(
                            "communicationPreferences",
                            option.value,
                            v
                          )
                        }
                        disabled={isUpdating}
                      />
                      <div className="ml-2">{option.label}</div>
                    </div>
                  ))}
                </FormElement>
              </div>
            </div>
            {Object.values(touched).some(Boolean) && (
              <SettingsActionBar
                cancelDisabled={isUpdating}
                saveDisabled={isUpdating}
                onCancel={async () => {
                  for (const e in errors) {
                    await setFieldError(e, null);
                  }
                  for (const key of Object.keys(initialValues)) {
                    await setFieldValue(key, initialValues[key]);
                  }
                  await setTouched({});
                }}
                onSave={async () => {
                  setIsUpdating(true);
                  const errors = await validateForm();
                  if (!Object.keys(errors).length) {
                    await onSave(values);
                  }
                  setIsUpdating(false);
                }}
              />
            )}
            <PromptUpdater />
          </Form>
        );
      }}
    </Formik>
  );
};

export default ContactInformation;

import { Button, Modal } from "pages/talent/components";
import { Field, TalentDetailSection } from "./components";
import { useContext, useState, useEffect } from "react";
import { TalentContext } from "../TalentDataContext";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { createTalentClockify, deleteTalentClockify } from "utils/adminApi";
import AlertError from "components/AlertError";
import { useAlert } from "react-alert";

export default function Clockify() {
  const queryClient = useQueryClient();
  const alert = useAlert();
  const { talentData, onUpdateTalent, id } = useContext(TalentContext);

  const [tempClockifyId, setTempClockifyId] = useState(talentData.clockify_id);
  const [tempClockifyEmail, setTempClockifyEmail] = useState(
    talentData.clockify_email
  );
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  useEffect(() => {
    setTempClockifyId(talentData.clockify_id);
    setTempClockifyEmail(talentData.clockify_email);
  }, [talentData]);

  const { mutate: createClockify } = useMutation({
    mutationFn: (email) => {
      return createTalentClockify(id, {
        name: talentData.first_name + " " + talentData.last_name,
        email: email
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["talentAccount", id]
      });
    },
    onError: (error, variables) => {
      alert.error(
        <AlertError
          error={error}
          onRetry={() => createClockify.mutateAsync(variables)}
        />
      );
    }
  });

  const deleteClockifyMutation = useMutation({
    mutationFn: () => {
      return deleteTalentClockify(id);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["talentAccount", id]
      });
    },
    onError: (error, variables) => {
      alert.error(
        <AlertError
          error={error}
          onRetry={() => deleteClockifyMutation.mutateAsync(variables)}
        />
      );
    }
  });

  const hasClockify = talentData.clockify_id?.length > 0;

  return (
    <TalentDetailSection title="Clockify">
      <Field
        name="Clockify ID"
        placeholder="Enter ID or invite to Clockify"
        value={tempClockifyId}
        onChange={(v) => setTempClockifyId(v)}
      />
      <Field
        name="Email"
        placeholder="Enter Clockify login email"
        value={tempClockifyEmail}
        onChange={(v) => setTempClockifyEmail(v)}
      />
      {hasClockify && (
        <Button onClick={() => setShowDeleteModal(true)}>
          Delete ClockifyID
        </Button>
      )}
      {!hasClockify && tempClockifyId && (
        <Button onClick={() => onUpdateTalent({ clockify_id: tempClockifyId })}>
          Save ID
        </Button>
      )}
      {!hasClockify && !tempClockifyId && (
        <Button
          disabled={!tempClockifyEmail}
          onClick={async () => {
            await onUpdateTalent({ clockify_email: tempClockifyEmail });
            await createClockify(tempClockifyEmail);
          }}
        >
          Invite to Clockify
        </Button>
      )}
      {showDeleteModal && (
        <Modal
          title="Delete ClockifyID"
          saveBtnText="Delete"
          onClickSave={deleteClockifyMutation.mutateAsync}
          onClose={() => setShowDeleteModal(false)}
        >
          <div>
            Are you sure to want to delete? The talent's ClockifyID will be
            deleted from Pipeline. The ClockifyID will need to be deactivated or
            deleted in Clockify.
          </div>
        </Modal>
      )}
    </TalentDetailSection>
  );
}

import React from "react";
import about from "assets/about-empty-state.svg";
import EmptyState from "../components/EmptyState";

const EmptyAbout = () => {
  const description =
    "This talent hasn’t added any details about themselves yet. Encourage them to complete this section for a more comprehensive profile.";

  return (
    <EmptyState
      title="No details added yet"
      image={about}
      description={description}
    />
  );
};

export default EmptyAbout;

import React, { useEffect, useCallback } from "react";
import { useParams, useHistory, useLocation } from "react-router-dom";
import { usePageCache } from "contexts/pageCacheContext";
import { getTalentProjectsV2 } from "utils/adminApi";
import ProjectsOverview from "./ProjectsOverview";
import ProjectDetail from "./ProjectDetail";
import useCachedFetch from "hooks/useCachedFetch";

const Projects = ({ data: talentData }) => {
  const { id } = useParams();
  const { set } = usePageCache();
  const history = useHistory();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const projectId = searchParams.get("cpid");

  const apiCall = useCallback(() => {
    if (!talentData?.id) return;
    return getTalentProjectsV2({
      candidate__id: talentData.id,
      page_size: 999
    });
  }, [talentData?.id]);

  const { data, reload, pageState } = useCachedFetch(
    "admin_talent_projects",
    apiCall,
    talentData?.id
  );

  const projects = data?.results || [];
  const loading = pageState === "loading" && projects.length === 0;

  const selectedProject = projects.find((project) => project.id === projectId);

  useEffect(() => {
    // Update the page cache URL based on whether a project is selected
    if (!id) return;

    const url = selectedProject
      ? `/admin/talent/${id}/projects?cpid=${selectedProject.id}`
      : `/admin/talent/${id}/projects`;
    // set("url_talent", url);
    console.log("url", url);
  }, [set, id, selectedProject]);

  const onSelectProject = useCallback(
    (project) => {
      history.push({
        pathname: location.pathname,
        search: `?cpid=${project.id}`
      });
    },
    [history, location.pathname]
  );

  const onDeselectProject = useCallback(() => {
    history.replace({
      pathname: location.pathname,
      search: ""
    });
  }, [history, location.pathname]);

  if (selectedProject) {
    return (
      <div id="admin_project_candidates" className="h-full">
        <ProjectDetail
          project={selectedProject}
          talent={data}
          onUpdate={reload}
          onBack={onDeselectProject}
        />
      </div>
    );
  }

  return (
    <div id="admin_project_candidates" className="h-full">
      <ProjectsOverview
        projects={projects}
        loading={loading}
        onSelectProject={onSelectProject}
        onUpdate={reload}
        talent={{
          fullname: talentData?.fullname,
          id: talentData?.id
        }}
      />
    </div>
  );
};

export default Projects;

import { useContext, useState } from "react";
import { useAlert } from "react-alert";
import { useQueryClient, useMutation } from "@tanstack/react-query";
import { Input, Modal } from "pages/talent/components";
import { FormElement } from "pages/talent/Settings/components";
import AlertError from "components/AlertError";
import { Dropdown } from "components/Form";
import { postUserLink } from "utils/adminApi";
import { TalentContext } from "../TalentDataContext";
import { attachmentTypeOptions } from "./constants";

/**
 * Modal to attach a URL to a talent account.
 * If linkedin already exists, the option to add it again is not shown.
 */
const AddUrlModal = ({ onClose, linkedinExists }) => {
  const queryClient = useQueryClient();
  const alert = useAlert();
  const { id } = useContext(TalentContext);
  const [data, setData] = useState({ url: "", type: "" });

  const addUserLinkMutation = useMutation({
    mutationFn: () =>
      postUserLink({
        name: data.type,
        url: data.url,
        user: id
      }),
    onError: (err) => {
      alert.error(<AlertError error={err} />);
    },
    onSuccess: () => {
      queryClient.invalidateQueries(["adminTalentLinks", id]);

      if (["LinkedIn", "Scheduling Link"].includes(data.type)) {
        queryClient.invalidateQueries(["talentAccount", id]);
      }

      onClose();
    }
  });

  return (
    <Modal
      title="Add URL"
      saveBtnText="Upload"
      onClose={onClose}
      onClickSave={addUserLinkMutation.mutateAsync}
      buttonsDisabled={addUserLinkMutation.isLoading || !data.url || !data.type}
    >
      <div>
        <FormElement label="Attach URL">
          <Input
            placeholder="Enter URL"
            name="url"
            value={data.url}
            onChange={(v) => setData({ ...data, url: v.target.value })}
          />
        </FormElement>
        <FormElement label="Select file type">
          <Dropdown
            placeholder="Select file type"
            options={attachmentTypeOptions.filter(
              (o) =>
                o.isURLOption === true &&
                (linkedinExists ? o.value.toLowerCase() !== "linkedin" : true)
            )}
            value={data.type}
            onChange={(v) => setData({ ...data, type: v.value })}
          />
        </FormElement>
      </div>
    </Modal>
  );
};

export default AddUrlModal;

import React, { useContext } from "react";
import { TalentDetailSection, Field, YES_NO_OPTIONS } from "./components";
import { TalentContext } from "../TalentDataContext";

const Onboarding = () => {
  const { talentData, onUpdateTalent, loading } = useContext(TalentContext);

  return (
    <TalentDetailSection title="Onboarding Info">
      <Field
        name="Onboarded"
        isBoolean
        placeholder="Select yes or no"
        onChange={(v) => onUpdateTalent({ onboarded: v })}
        value={talentData.onboarded}
        options={YES_NO_OPTIONS}
        loading={loading}
      />
      <Field
        name="Background Check Passed"
        placeholder="Select yes or no"
        value={talentData.background_check_passed}
        isBoolean
        options={YES_NO_OPTIONS}
        onChange={(v) => onUpdateTalent({ background_check_passed: v })}
        loading={loading}
      />
    </TalentDetailSection>
  );
};

export default Onboarding;

import React from "react";

const EmptyState = ({ image, title, description }) => {
  return (
    <div className="mb-16 flex h-full flex-col items-center justify-center gap-2">
      <img src={image} alt="no_experiences" height="120px" />
      <h1 className="pt-2 text-base font-semibold text-midnight">{title}</h1>
      <p className="w-70 text-center text-sm text-kasmir">{description}</p>
    </div>
  );
};

export default EmptyState;

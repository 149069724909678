import React from "react";
import Date from "react-datepicker";
import clsx from "clsx";

/**
 * Renders an input, used internally for the DatePicker component, it is not meant to be used alone.
 */
const Input = React.forwardRef(
  ({ value, onClick, error, disabled, placeholder, className }, ref) => (
    <div className="relative">
      <input
        placeholder={placeholder}
        ref={ref}
        disabled={disabled}
        type="text"
        onClick={onClick}
        value={value}
        readOnly
        className={clsx(
          "placeholder-text-kasmir focus:outline-none flex h-5 w-full cursor-pointer appearance-none items-center rounded bg-transparent text-sm font-bold text-midnight placeholder:font-medium",
          className
        )}
      />
    </div>
  )
);

/**
 * Renders an input with a calendar picker.
 * It wrappers react-datepicker (https://reactdatepicker.com/). Can add more props for that library, but this component is customize to support monthly and daily, and some default styles.
 * @param {boolean} error - Flag to indicate error on this input.
 * @param {callback} onChange - Callback to catch new selected values.
 * @param {date} value - Input value, a date.
 * @param {boolean} monthly - Flag to indicate if is a monthly or daily input, by default monthly.
 * @param {object} props - Extra props to pass to the Date component.
 */
const DatePicker = ({
  error,
  onChange,
  value,
  monthly = false,
  format,
  ...props
}) => {
  return (
    <Date
      {...props}
      selected={value}
      onChange={onChange}
      showMonthYearPicker={monthly}
      showFullMonthYearPicker={monthly}
      customInput={
        <Input
          error={error}
          disabled={props.disabled}
          className={props.className}
        />
      }
      dateFormat={format ? format : monthly ? "MMM, yyyy" : "MM/dd/yyyy"}
      popperClassName="z-40 bg-white text-midnight border border-link overflow-hidden rounded text-sm"
    />
  );
};

export default DatePicker;

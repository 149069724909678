import React from "react";

import clsx from "clsx";

const Input = React.forwardRef(
  (
    {
      children,
      type = "text",
      error,
      height = "10",
      fontSize,
      fontWeight,
      ...props
    },
    ref
  ) => (
    <div
      className={clsx(
        "flex items-center overflow-hidden",
        props.className || "w-full rounded-md bg-white px-1 text-midnight",
        `h-${height}`,
        fontSize || "text-sm",
        fontWeight || "",
        error
          ? "border-2 border-red"
          : props.disabled
            ? "border border-geyser bg-link-water"
            : "border border-geyser focus:border-2 focus:border-link"
      )}
    >
      {props.prefix && (
        <span className="rounded-med my-1 inline-flex h-8 items-center bg-lightest-grey px-1.5 text-kasmir">
          {props.prefix}
        </span>
      )}
      <input
        ref={ref}
        {...props}
        className="ml-2 w-full min-w-0 flex-auto appearance-none focus:outline-none placeholder:font-normal placeholder:text-kasmir"
        onWheel={(e) => e.target.blur()}
        type={type}
      />
    </div>
  )
);

export default Input;

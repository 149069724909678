import React from "react";
import clsx from "clsx";
import { admin_talent_overview as $ } from "strings";
import RichTextEditor from "components/RichTextEditorNew";
import { isBodyEmpty } from "components/RichTextEditorNew";
import { Button, Modal } from "pages/talent/components";
import DropdownSelect from "components/DropdownMenuButton";

const getPhoneTemplate = () => {
  const template = [
    { type: "heading-two", children: [{ text: "Talent Team Phone Screen" }] }
  ];
  $.phone_template.forEach((i) => {
    template.push({
      type: "bulleted-list",
      children: [
        { text: i, bold: true },
        { text: ":", bold: false }
      ]
    });
  });

  return template;
};
const getTechScreenTemplate = () => {
  const template = [
    { type: "heading-two", children: [{ text: "Tech Screen Notes" }] }
  ];
  $.tech_screen_template.forEach((i) => {
    template.push({
      type: "bulleted-list",
      children: [
        { text: i, bold: true },
        { text: ":", bold: false }
      ]
    });
  });

  return template;
};

const NoteModal = ({
  values,
  setValues,
  title,
  id,
  saving,
  saveBtnText,
  onSave,
  onCancel
}) => {
  const isEmpty = isBodyEmpty(values.body);

  const updateVal = (k, v) => {
    setValues((vs) => ({ ...vs, [k]: v }));
  };

  const onChangeTemplate = (template) => {
    if (template === "phone") {
      updateVal(
        "body",
        isEmpty ? getPhoneTemplate() : [...values.body, ...getPhoneTemplate()]
      );
    } else if (template === "tech") {
      updateVal(
        "body",
        isEmpty
          ? getTechScreenTemplate()
          : [...values.body, ...getTechScreenTemplate()]
      );
    }
  };

  return (
    <Modal
      title={title}
      id={id}
      fullScreenOnMobile
      saveBtnText="Post note"
      showActionBar={false}
      movable
      onClose={onCancel}
      onClickSave={onSave}
    >
      <div className={clsx("flex h-full w-full flex-none flex-col")}>
        {/* Scrollable content area */}
        <div className="flex-grow overflow-y-auto">
          <RichTextEditor
            placeholder="Start typing to add a talent note..."
            value={values.body}
            alwaysOpen
            onChange={(v) => updateVal("body", v)}
            isStageChangeNote={false}
            className="h-full"
            growVertically
          />
        </div>

        {/* Fixed bottom bar */}
        <div className="flex w-full flex-row items-center justify-between gap-2.5 pb-5 pt-2.5">
          <DropdownSelect
            compact
            justifyRight={false}
            className="z-50 rounded-md bg-lightest-grey py-1 text-midnight"
            label="Autofill Template"
            options={[
              {
                label: "Recruiter Phone Screen",
                onClick: () => onChangeTemplate("phone")
              },
              {
                label: "Tech Screen",
                onClick: () => onChangeTemplate("tech")
              }
            ]}
          />
          <div className="ml-auto">
            <Button disabled={saving} onClick={onCancel} cancel>
              <span>Cancel</span>
            </Button>
          </div>
          <Button disabled={saving || isEmpty} onClick={onSave}>
            <span>{saveBtnText}</span>
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default NoteModal;

import React, { useContext } from "react";
import { admin_talent_overview as $ } from "strings";
import { toFormat } from "utils/date";
import { deleteTalentNoteV2, deleteStatusNote } from "utils/adminApi";
import { useAlert } from "react-alert";
import AlertError from "components/AlertError";
import { urlify } from "utils/str";
import { B2BLink } from "components/Typography";
import DropdownSelect from "components/DropdownMenuButton";
import PinnedIcon from "assets/icons/Pinned";
import { getUserId } from "utils/localStorageService";
import { usePatchNote } from "./EditNoteModal";
import { TalentContext } from "../TalentDataContext";
import { useMutation, useQueryClient } from "@tanstack/react-query";

const Note = ({ note, editable, highlightedKeyword, setShowNoteEdit }) => {
  const alert = useAlert();
  const { id } = useContext(TalentContext);
  const queryClient = useQueryClient();
  const patchNote = usePatchNote(id, note);

  const loggedInUserId = getUserId();

  const deleteNoteMutation = useMutation({
    mutationFn: (note) => {
      if (note.type === "status_history") {
        return deleteStatusNote(note.id);
      } else {
        return deleteTalentNoteV2(note.talent_ref, note.type, note.id);
      }
    },
    onError: (err, newVals) => {
      alert.error(
        <AlertError
          error={err}
          retry={() => deleteNoteMutation.mutateAsync(newVals)}
        />
      );
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: ["talentNotes", id] });
    }
  });

  const onDelete = async () => {
    await deleteNoteMutation.mutateAsync(note);
  };

  const onTogglePin = async () => {
    await patchNote({ pinned: !note.pinned });
  };

  const author =
    note.created_by_name === "Crelate_Populate_Talents_Script"
      ? $.crelate_note_label
      : `${note.created_by_name || note.updated_by_name || $.unknown_author_label}`;

  const noteBody = highlightedKeyword
    ? note.note_body
        .split(/(<[^>]*>)/g)
        .map((part, i) => {
          if (part.startsWith("<")) {
            return part;
          }
          return part.replace(
            new RegExp(highlightedKeyword, "gi"),
            (match) => `<span class="bg-yellow-200">${match}</span>`
          );
        })
        .join("")
    : note.note_body;

  const editOptions = [
    {
      label: "Edit",
      onClick: () => setShowNoteEdit(note)
    },
    { label: "Delete", onClick: onDelete },
    { label: note.pinned ? "Unpin" : "Pin", onClick: onTogglePin }
  ].filter((o) => {
    if (o.label === "Edit") return editable;
    if (o.label === "Delete") return note.updated_by_id === loggedInUserId;

    return true;
  });

  return (
    <div id={note.id} className="rounded-md bg-white p-2.5">
      <div>
        <div className="flex items-center justify-between text-sm font-bold text-midnight">
          <div className="flex w-full items-center">
            <div className="text-midnight">{author}</div>

            {note.project_name && note.project_ref && (
              <>
                <p className="px-1.5 text-2xs font-bold">•</p>
                <B2BLink to={`/admin/project/${note.project_ref}`}>
                  <div className="text-electric-indigo">
                    {note.project_name}
                  </div>
                </B2BLink>
              </>
            )}
            {note.pinned && (
              <div className="ml-3 flex h-5 w-5 items-center justify-center rounded-full bg-alice-blue pb-0.5">
                <PinnedIcon size="13" fill="#54698D" />
              </div>
            )}
            <div className="ml-auto flex flex-row items-center gap-2.5">
              {note.created_timestamp && (
                <div className="text-sm font-normal text-grey">
                  {toFormat(new Date(note.created_timestamp), "MM/dd/yyyy")}
                </div>
              )}
              <DropdownSelect label="Actions" compact options={editOptions} />
            </div>
          </div>
        </div>
        <div className="rich-content relative mt-2.5 min-h-0 whitespace-pre-line text-sm font-normal text-midnight">
          <div dangerouslySetInnerHTML={{ __html: urlify(noteBody) }} />
        </div>
      </div>
    </div>
  );
};

export default Note;

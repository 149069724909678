import React, { useState, useContext } from "react";
import LoadPlaceholder from "components/LoadPlaceholder";
import { getTalentFilesV2, getUserLinks } from "utils/adminApi";
import { TalentPageSection } from "../components/components";
import EmptyAttachments from "./EmptyAttachments";
import { TalentContext } from "../TalentDataContext";
import { Button } from "pages/talent/components";
import { useQuery } from "@tanstack/react-query";
import { AttachmentsList } from "./AttachmentsList";
import AddUrlModal from "./AddUrlModal";
import AddFileModal from "./AddFileModal";

const Attachments = () => {
  const { id, talentData } = useContext(TalentContext);
  const talentId = talentData.id;

  const [modal, setModal] = useState();

  const { data: attachments, isLoading: attachmentsLoading } = useQuery({
    queryFn: () => getTalentFilesV2(talentId, { talent__id: talentId }),
    select: (data) => data?.results,
    queryKey: ["adminTalentFiles", talentId],
    enabled: !!talentId,
    onError: (err) => console.err(err)
  });

  const { data: talentLinks, isLoading: linksLoading } = useQuery({
    queryFn: () => getUserLinks(id),
    queryKey: ["adminTalentLinks", id],
    select: (data) => data?.data?.results,
    enabled: !!id
  });

  const loadingContent = (
    <div className="flex flex-1 flex-col">
      <LoadPlaceholder className="mb-10 mt-10 h-4 w-60" />
      <LoadPlaceholder className="mb-2 h-4 w-60" />
      <LoadPlaceholder className="mb-2 h-4 w-40" />
      <LoadPlaceholder className="mb-2 h-4 w-32" />
    </div>
  );

  const isLoading = attachmentsLoading || linksLoading;
  const isEmpty = !isLoading && !attachments.length && !talentLinks.length;

  return (
    <>
      <TalentPageSection
        id="admin_talent_attachments"
        title="Attachments"
        fullHeight={false}
        topRightContent={
          <div className="flex flex-row gap-2.5">
            <Button noMinW onClick={() => setModal("file")}>
              Add Attachment
            </Button>
            <Button noMinW className="ml-1" onClick={() => setModal("url")}>
              Add URL
            </Button>
          </div>
        }
      >
        <div className="h-full w-full p-4">
          {isLoading ? (
            loadingContent
          ) : isEmpty ? (
            <EmptyAttachments />
          ) : (
            <AttachmentsList attachments={attachments} links={talentLinks} />
          )}
        </div>
      </TalentPageSection>
      {modal === "url" && <AddUrlModal onClose={() => setModal(null)} />}
      {modal === "file" && <AddFileModal onClose={() => setModal(null)} />}
    </>
  );
};

export default Attachments;

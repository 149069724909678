import React from "react";
import clsx from "clsx";

export const QualificationsDisplay = ({
  qualifications,
  className = "",
  large = false,
  bgColor = "bg-lightest-grey"
}) => {
  return (
    <div
      className={clsx(
        "flex flex-wrap text-sm",
        large ? "gap-2" : "gap-1.5",
        className
      )}
    >
      {qualifications.map((q, i) => (
        <div
          className={clsx(
            "rounded-md text-sm font-semibold text-midnight",
            bgColor,
            large ? "px-2.5 py-2" : "px-2 py-1.5"
          )}
          key={i}
        >
          {q?.name}
        </div>
      ))}
    </div>
  );
};

import React, { useState, useEffect } from "react";

import clsx from "clsx";

const EditableDoubleField = ({
  first,
  last,
  firstPlaceholder,
  lastPlaceholder,
  divider,
  firstRequired,
  lastRequired,
  onChange,
  inputClass,
  indicator,
  className,
  disabled,
  ...props
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const [firstInputValue, setFirstInputValue] = useState();
  const [lastInputValue, setLastInputValue] = useState();
  const [firstInputError, setFirstInputError] = useState();
  const [lastInputError, setLastInputError] = useState();

  useEffect(() => {
    if (first) setFirstInputValue(first);
    if (last) setLastInputValue(last);
  }, [first, last]);

  useEffect(() => {
    if (isEditing) {
      setTimeout(
        () => document.getElementById(`${indicator}--input1`).focus(),
        10
      );
    }
  }, [isEditing, indicator]);

  const handleKeyDown = (e) => {
    const { key } = e;
    const keys = ["Escape", "Tab", "Enter"];

    if (keys.indexOf(key) > -1) {
      setIsEditing(false);

      if (key !== "Escape") {
        handleSave();
      }
    }
  };

  const handleBlur = (e) => {
    if (
      e.relatedTarget?.id === `${indicator}--input1` ||
      e.relatedTarget?.id === `${indicator}--input2`
    )
      return;
    if (
      (firstRequired && !firstInputValue) ||
      (lastRequired && !lastInputValue)
    ) {
      setIsEditing(false);
      return;
    }
    handleSave();
  };

  const handleSave = () => {
    if (
      (firstRequired && !firstInputValue) ||
      (lastRequired && !lastInputValue)
    ) {
      return;
    }
    onChange(firstInputValue, lastInputValue);
    setIsEditing(false);
  };

  return (
    <div id={`${indicator}--parent`} className={className} {...props}>
      {isEditing ? (
        <div
          className="flex gap-1.5"
          onKeyDown={(e) => handleKeyDown(e)}
          onBlur={(e) => handleBlur(e)}
        >
          <div className="flex flex-col">
            <input
              id={`${indicator}--input1`}
              type="text"
              onChange={(e) => {
                if (firstRequired)
                  setFirstInputError(
                    e.target.value.length ? null : "This field is required"
                  );
                setFirstInputValue(e.target.value);
              }}
              value={firstInputValue}
              placeholder={firstPlaceholder}
              className={clsx(
                inputClass,
                "placeholder-text-kasmir focus:outline-none h-5 appearance-none rounded border border-link-water bg-white px-0 py-0 text-left text-sm font-medium placeholder:font-medium"
              )}
            />
            {firstInputError && (
              <div className="flex items-center text-xs text-red">
                {firstInputError}
              </div>
            )}
          </div>
          {divider && (
            <span className="mx-2 px-0 py-0 text-sm font-medium text-kasmir">
              {divider}
            </span>
          )}
          <div className="flex flex-col">
            <input
              id={`${indicator}--input2`}
              type="text"
              onChange={(e) => {
                if (lastRequired)
                  setLastInputError(
                    e.target.value.length ? null : "This field is required"
                  );
                setLastInputValue(e.target.value);
              }}
              value={lastInputValue}
              placeholder={lastPlaceholder}
              className={clsx(
                inputClass,
                "placeholder-text-kasmir focus:outline-none h-5 appearance-none rounded border border-link-water bg-white px-0 py-0 text-left text-sm font-medium placeholder:font-medium"
              )}
            />
            {lastInputError && (
              <div className="flex items-center text-xs text-red">
                {lastInputError}
              </div>
            )}
          </div>
        </div>
      ) : (
        <div
          id="child-not-editing"
          onClick={() => {
            if (disabled) return;
            setIsEditing(true);
            setFirstInputValue(first);
            setLastInputValue(last);
          }}
          className="h-5 w-full overflow-hidden truncate text-sm"
        >
          {first || firstPlaceholder} {divider || ""} {last || lastPlaceholder}
        </div>
      )}
    </div>
  );
};

export default EditableDoubleField;

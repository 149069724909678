import React, { useContext, useState } from "react";
import { admin_talent_attachments as $ } from "strings";
import { IconBtn } from "components/Buttons";
import { attachmentTypeOptions } from "./constants";
import { toFormat } from "utils/date";
import { withHttp } from "utils/str";
import {
  deleteTalentFileV2,
  deleteUserLink,
  getTalentAttachedFileDownloadUrl
} from "utils/adminApi";
import GeneralWebsite from "components/icons/links/GeneralWebsite.svg";
import Delete from "assets/delete.svg";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useAlert } from "react-alert";
import AlertError from "components/AlertError";
import { TalentContext } from "../TalentDataContext";
import UilImport from "@iconscout/react-unicons/icons/uil-import";
import UilLink from "@iconscout/react-unicons/icons/uil-link";
import DeleteAttachmentModal from "./DeleteAttachmentModal";

const AttachmentListItem = ({
  id,
  attachmentName,
  attachedBy,
  attachedOn,
  info,
  url,
  filename,
  isFile
}) => {
  const alert = useAlert();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const { id: userId, talentData } = useContext(TalentContext);
  const talentId = talentData.id;
  const queryClient = useQueryClient();
  const attachmentType = attachmentTypeOptions.find(
    (option) => option.value.toLowerCase() === attachmentName?.toLowerCase()
  );

  const deleteAttachmentMutation = useMutation({
    mutationFn: () => {
      if (isFile) return deleteTalentFileV2(id);
      return deleteUserLink(id);
    },
    onMutate: async () => {
      if (isFile) {
        await queryClient.cancelQueries(["adminTalentFiles", talentId]);
        const previousFiles = queryClient.getQueryData([
          "adminTalentFiles",
          talentId
        ]);

        queryClient.setQueryData(["adminTalentFiles", talentId], (old) => {
          if (!old) return old;
          return {
            ...old,
            results: old.results.filter((file) => file.id !== id)
          };
        });

        return { previousFiles };
      } else {
        await queryClient.cancelQueries(["adminTalentLinks", userId]);
        const previousLinks = queryClient.getQueryData([
          "adminTalentLinks",
          userId
        ]);

        queryClient.setQueryData(["adminTalentLinks", userId], (old) => {
          if (!old) return old;
          return {
            ...old,
            data: {
              ...old.data,
              results: old.data.results.filter((link) => link.id !== id)
            }
          };
        });

        return { previousLinks };
      }
    },
    onError: (err, vars, context) => {
      if (isFile && context?.previousFiles) {
        queryClient.setQueryData(
          ["adminTalentFiles", talentId],
          context.previousFiles
        );
      } else if (!isFile && context?.previousLinks) {
        queryClient.setQueryData(
          ["adminTalentLinks", userId],
          context.previousLinks
        );
      }

      alert.error(
        <AlertError
          error={err}
          retry={() => deleteAttachmentMutation.mutateAsync(vars)}
        />
      );
    },
    onSettled: () => {
      if (isFile) {
        queryClient.invalidateQueries(["adminTalentFiles", talentId]);
      } else {
        queryClient.invalidateQueries(["adminTalentLinks", userId]);
      }
      // propagate elsewhere
      queryClient.invalidateQueries(["talentAccount", userId]);
    }
  });

  return (
    <div
      className="grid w-full items-start rounded-lg bg-lightest-grey p-2.5 text-left text-sm"
      style={{ gridTemplateColumns: `repeat(${6}, minmax(0, 1fr))` }}
    >
      <div className="flex flex-row items-center gap-1.5 font-bold text-midnight">
        <img
          src={attachmentType?.icon || GeneralWebsite}
          height={14}
          width={14}
          alt={`${attachmentName || "Link"} icon`}
          className="opacity-80"
          style={{ filter: "brightness(1.8) saturate(0.85) hue-rotate(10deg)" }} // this makes it the right color (thx chatgpt)
        />
        {attachmentType?.label || attachmentName || "Other"}
      </div>
      <div className="font-medium text-kasmir">
        {attachedBy || $.unknown_value}
      </div>
      <div className="font-medium text-kasmir">
        {attachedOn
          ? toFormat(new Date(attachedOn), "MM/dd/yyyy h:mm a")
          : $.unknown_value}
      </div>
      <div className="col-span-2 font-bold text-electric-indigo">{info}</div>
      <div className="flex flex-row justify-end">
        {url ? (
          <IconBtn bgColor="bg-white">
            <a href={withHttp(url)} target="_blank" rel="noopener noreferrer">
              <UilLink size="15" color="#16325C" />
            </a>
          </IconBtn>
        ) : (
          <IconBtn bgColor="bg-white">
            <a
              download={filename}
              href={withHttp(getTalentAttachedFileDownloadUrl(id))}
            >
              <UilImport size="15" color="#16325C" />
            </a>
          </IconBtn>
        )}
        <IconBtn bgColor="bg-white" onClick={() => setShowDeleteModal(true)}>
          <img src={Delete} height={15} width={15} alt="delete" />
        </IconBtn>
      </div>
      {showDeleteModal && (
        <DeleteAttachmentModal
          onClose={() => setShowDeleteModal(false)}
          onDelete={() => {
            setShowDeleteModal(false);
            deleteAttachmentMutation.mutateAsync();
          }}
        />
      )}
    </div>
  );
};

export default AttachmentListItem;

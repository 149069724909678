import { useContext } from "react";
import { TalentContext } from "../TalentDataContext";
import { Field, TalentDetailSection } from "./components";
import { COMMUNICATION_PREFERENCES_OPTIONS } from "pages/talent/Settings/ContactInformation";
import LogoIcon from "components/icons/Logo";
import { Link } from "react-router-dom";
import { withHttp } from "utils/str";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { getTalentAgreements, patchUser } from "utils/adminApi";
import { Tooltip } from "react-tooltip";
import UilCheck from "@iconscout/react-unicons/icons/uil-check";
import AlertSuccess from "../../../../../components/AlertSuccess";
import AlertError from "components/AlertError";
import LinkedIn from "components/icons/links/LinkedIn.svg";
import Calendar from "components/icons/links/Calendar.svg";

export default function Contact() {
  const { talentData, onUpdateTalent, id, loading } = useContext(TalentContext);
  const queryClient = useQueryClient();

  const patchUserEmail = useMutation({
    mutationFn: async (email) => {
      // Optimistically update
      queryClient.setQueryData(["talentAccount", id], {
        email: email,
        primary_talent_email: email
      });
      patchUser(id, { primary_talent_email: email, email });
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: ["talentAccount", id] });
    },
    onError: (e, variables) => {
      const duplicateId = e.response?.data?.users?.[0]?.id;
      if (duplicateId) {
        const pageUrl = `/admin/contacts/${duplicateId}/contact_info`;
        alert.info(
          <AlertSuccess>
            <div>
              A record with this email already exists.{" "}
              <a href={pageUrl} className="underline">
                Click here to view it.
              </a>
            </div>
          </AlertSuccess>
        );
      } else {
        alert.error(
          <AlertError
            error={e}
            onRetry={() => patchUserEmail.mutateAsync(variables)}
          />
        );
      }
    }
  });

  const { data: agreementsData } = useQuery({
    queryFn: () => getTalentAgreements(id),
    queryKey: ["admin_talent_agreements", id]
  });

  const smsOptIn = agreementsData?.data?.textmessage;

  const links = [
    {
      name: "linkedin",
      icon: (
        <img
          src={LinkedIn}
          size="16"
          alt="linkedin"
          className="svg-midnight-to-electric-indigo"
        />
      ),
      url: talentData?.linkedin_url
    },
    {
      name: "calendar",
      icon: (
        <img
          src={Calendar}
          height="16"
          width="16"
          alt="calendar"
          className="svg-midnight-to-electric-indigo"
        />
      ),
      url: talentData?.calendar_link
    },
    {
      name: "public",
      icon: <LogoIcon size="16" />,
      url: `${window.location.origin}/talent/${talentData.url_suffix}`
    }
  ];

  const onSaveEmail = async (email) => {
    if (!email) return;

    await patchUserEmail.mutateAsync(email);
  };

  return (
    <TalentDetailSection title="Contact Info">
      <div className="flex h-7 flex-row justify-start gap-2">
        {links
          .filter((l) => l.url)
          .map((l) => {
            return (
              <div key={l.name}>
                <Link
                  className="flex h-7 w-7 items-center justify-center rounded-md bg-white"
                  to={{ pathname: withHttp(l.url) }}
                  target="_blank"
                  data-tooltip-id={`tooltip-${l.name}`}
                >
                  {l.icon}
                </Link>
                <Tooltip
                  id={`tooltip-${l.name}`}
                  opacity={1}
                  border="1px solid #F7F7FA"
                  place="top"
                  style={{
                    backgroundColor: "white",
                    padding: "8px",
                    borderRadius: "7px",
                    boxShadow: "0px 4px 15px 0px rgba(0, 0, 0, 0.08)"
                  }}
                >
                  <div className="flex w-50 break-all text-sm font-semibold text-electric-indigo underline">
                    {l.url}
                  </div>
                </Tooltip>
              </div>
            );
          })}
      </div>
      <Field
        name="User Email"
        placeholder="Enter Email"
        value={talentData.primary_talent_email}
        displayTextColor="text-electric-indigo"
        type="email"
        onChange={onSaveEmail}
        loading={loading}
      />
      <Field
        name="Phone Number"
        placeholder="Enter Phone Number"
        displayTextColor="text-electric-indigo"
        value={talentData.phone_number}
        isPhone
        onChange={(v) => onUpdateTalent({ phone_number: v })}
        tooltipIcon={smsOptIn ? <UilCheck /> : null}
        tooltipContent={
          smsOptIn ? (
            <div className="text-sm text-midnight">SMS Opt-In Confirmed</div>
          ) : null
        }
        loading={loading}
      />
      <Field
        name="Preferred Communication"
        placeholder="Select Preferred Communication"
        isMulti
        value={talentData.communication_preferences?.split("|") ?? []}
        onChange={(v) =>
          onUpdateTalent({ communication_preferences: v.join("|") })
        }
        options={COMMUNICATION_PREFERENCES_OPTIONS}
        loading={loading}
        forceColumnLayout
      />
    </TalentDetailSection>
  );
}

import React, { useState } from "react";
import { admin_talent_merge as $ } from "strings";
import Section from "components/Section";
import {
  getAccount,
  getTalentFiles,
  getTalentLeadSource,
  getTalentLinks,
  getTalentOwners,
  getTalentProjects,
  getTalentQualifications,
} from "utils/adminApi";
import useCachedFetch from "hooks/useCachedFetch";
import { useParams } from "react-router-dom";
import { LoadPlaceholderGroup } from "components/LoadPlaceholder";
import { BackLinkBtn } from "components/Buttons";
import userImg from "assets/user.svg";
import Select from "components/Select";
import { fetchAccounts } from "utils/fetch";
import UilTimes from "@iconscout/react-unicons/icons/uil-times";
import Form from "./Form";
import { components } from "react-select";

const Merge = () => {
  const [selectedTalent, setSelectedTalent] = useState();
  const { id } = useParams();
  const { data, pageState } = useCachedFetch("admin_talent", getAccount, id);

  const qualifications = useCachedFetch(
    "admin_talent_qualifications",
    getTalentQualifications,
    id,
    { page_size: 9999 }
  );

  const links = useCachedFetch("admin_talent_links", getTalentLinks, id);
  const projects = useCachedFetch(
    "admin_talent_projects",
    getTalentProjects,
    id,
    { page_size: 9999 }
  );

  const owners = useCachedFetch("admin_talent_roles", getTalentOwners, id);

  const leadSource = useCachedFetch(
    "admin_talent_roles_lead_source",
    getTalentLeadSource,
    data?.lead_source?.id
  );

  const files = useCachedFetch("admin_talent_files", getTalentFiles, id);

  if (!data || pageState !== "loaded") {
    return (
      <div className="">
        <LoadPlaceholderGroup />
      </div>
    );
  }

  return (
    <Section id="admin_talent_merge" className="h-full bg-link-water">
      <BackLinkBtn
        to={`/admin/talent/${id}/overview`}
        text={`Back to ${data.user.first_name} ${data.user.last_name}`}
      />
      <h1 className="mt-2 text-base font-bold text-midnight">{$.title}</h1>
      <div className="mb-2 w-1/2 text-sm font-bold text-midnight">
        {$.info_text}
      </div>
      <div className="flex space-x-2">
        <TalentPreview talent={data} />
        {selectedTalent ? (
          <TalentPreview
            talent={selectedTalent.account}
            onDeselect={() => setSelectedTalent(null)}
          />
        ) : (
          <div className="flex-1">
            <Select
              placeholder={$.talent_placeholder}
              onChange={(v) => setSelectedTalent(v)}
              value={selectedTalent}
              async
              loadOptions={fetchAccounts}
              components={{
                DropdownIndicator: () => null,
                Option: ({ data, children, ...rest }) => (
                  <components.Option {...rest}>
                    <div className="flex items-center justify-between">
                      {children}
                      <div className="text-kasmir">
                        {data.account.user.primary_talent_email}
                      </div>
                    </div>
                  </components.Option>
                )
              }}
            />
          </div>
        )}
      </div>
      {selectedTalent && (
        <Form
          idA={id}
          idB={selectedTalent.account.user.id}
          talentA={data}
          linksA={links.data.results}
          qualificationsA={qualifications.data.results}
          projectsA={projects.data.results}
          ownersA={owners.data.results}
          leadSourceA={leadSource.data}
          talentB={selectedTalent.account}
          filesA={files.data.results}
          onCancel={() => setSelectedTalent(null)}
        />
      )}
    </Section>
  );
};

const TalentPreview = ({ talent, onDeselect }) => (
  <div className="flex-1 bg-white text-midnight rounded-sm flex text-sm font-bold p-2 mt-1">
    <div className="flex-no-shrink mr-2">
      <img
        src={talent.profile_photo || userImg}
        className="rounded w-16 h-16"
        alt={talent.user.first_name}
      />
    </div>
    <div className="flex-1">
      <div className="flex items-center justify-between">
        <div className="font-bold">
          {talent.user.first_name} {talent.user.last_name}
        </div>
        {onDeselect && (
          <button
            className="focus:outline-none flex items-center justify-center h-6 text-midnight font-bold"
            onClick={onDeselect}
          >
            <UilTimes size="18" />
            {$.deselect_btn}
          </button>
        )}
      </div>
      <div>
        {$.id_label}: {talent.user.id}
      </div>
      <div>
        {$.email_label}: {talent.user.primary_talent_email}
      </div>
    </div>
  </div>
);

export default Merge;

import React from "react";

const CycleIcon = ({ size = "14" }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 14 14"
      fill="none"
    >
      <path
        d="M3.99014 6.77969L3.54294 6.8566C3.429 6.87653 3.32646 6.78538 3.33215 6.67145C3.39482 5.39535 4.08698 4.17623 5.27478 3.48691C6.75026 2.63523 8.55331 2.85741 9.77529 3.91702C9.89492 4.01957 10.0715 4.01387 10.1826 3.90278L10.8064 3.27898C10.8662 3.21916 10.8976 3.14225 10.8947 3.0568C10.8919 2.97135 10.8577 2.89729 10.795 2.84032C9.1116 1.3449 6.5936 1.01733 4.53419 2.20512C2.86501 3.16789 1.90794 4.89118 1.84812 6.68569C1.84528 6.79678 1.74558 6.87653 1.63734 6.8566L1.22432 6.78254C0.956567 6.73696 0.768571 7.04744 0.939477 7.26392L2.37223 9.10115C2.49187 9.25497 2.72544 9.25497 2.84507 9.10115L4.28068 7.26107C4.44589 7.04459 4.26074 6.73411 3.99014 6.77969Z"
        fill="#54698D"
      />
      <path
        d="M13.0589 6.73677L11.6289 4.89954C11.5093 4.74573 11.2757 4.74573 11.1561 4.89954L9.7205 6.73962C9.55244 6.95325 9.74044 7.26658 10.0082 7.22101L10.4554 7.1441C10.5693 7.12416 10.6719 7.21531 10.6662 7.32925C10.6035 8.60534 9.91134 9.82446 8.72355 10.5138C7.24807 11.3655 5.44502 11.1433 4.22304 10.0837C4.10341 9.98113 3.92681 9.98682 3.81572 10.0979L3.18907 10.7246C3.12925 10.7844 3.09792 10.8613 3.10076 10.9467C3.10361 11.0322 3.13779 11.1063 3.20046 11.1632C4.88388 12.6586 7.40188 12.9862 9.46129 11.7984C11.1305 10.8357 12.0875 9.11236 12.1474 7.31785C12.1502 7.20676 12.2499 7.12701 12.3581 7.14695L12.7712 7.22101C13.0418 7.26373 13.2298 6.95325 13.0589 6.73677Z"
        fill="#54698D"
      />
    </svg>
  );
};

export default CycleIcon;

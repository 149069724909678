import { useContext } from "react";
import { Field, TalentDetailSection } from "./components";
import $$ from "strings/talent";
import { TalentContext } from "../TalentDataContext";

const employmentStatusOptions = [
  { label: $$.w2_employee_option, value: "w2_employee" },
  { label: $$.ft_freelancer_option, value: "fulltime_freelancer" },
  { label: $$.pt_freelancer_option, value: "parttime_freelancer" },
  { label: $$.student_option, value: "student" },
  { label: $$.searching_for_w2_option, value: "searching_for_w2" }
];

const workingTimezonesOptions = [
  { label: "ET", value: "eastern" },
  { label: "CT", value: "central" },
  { label: "MT", value: "mountain" },
  { label: "PT", value: "pacific" }
];

const onsiteFrequencyOptions = [
  { label: $$.regular_days_option, value: "regular" },
  { label: $$.occasional_meetings_option, value: "occasional" },
  { label: $$.special_events_option, value: "special" },
  { label: $$.not_interested_travel_option, value: "not_interested" }
];

const travelDistanceOptions = [
  { label: $$.local_travel_option, value: "local" },
  { label: $$.domestic_travel_option, value: "domestic" },
  { label: $$.international_travel_option, value: "international" }
];

const businessHoursAvailableOptions = [
  { label: $$.yes_option, value: "yes" },
  { label: $$.depends_on_day_option, value: "depends_on_day" },
  { label: $$.no_option, value: "no" }
];

export default function Availability() {
  const { talentData, onUpdateTalent, loading } = useContext(TalentContext);

  return (
    <TalentDetailSection title="Availability">
      <Field
        name="FTE Interest"
        placeholder="Select yes or no"
        value={talentData.ft_freelance_interest}
        onChange={(v) => onUpdateTalent({ ft_freelance_interest: v })}
        isBoolean
        loading={loading}
      />
      <Field
        name="Working Timezones"
        placeholder="Select Timezone"
        onChange={(v) => onUpdateTalent({ working_timezones: v })}
        isMulti
        value={talentData.working_timezones}
        options={workingTimezonesOptions}
        loading={loading}
      />
      <Field
        name="Business Hours Availability"
        placeholder="Select yes or no"
        onChange={(v) => onUpdateTalent({ business_hours_available: v })}
        options={businessHoursAvailableOptions}
        value={talentData.business_hours_available}
        loading={loading}
        forceColumnLayout
      />
      <Field
        name="Employment Status"
        placeholder="Select Status"
        value={talentData.employment_status}
        isMulti
        forceColumnLayout
        onChange={(v) => onUpdateTalent({ employment_status: v })}
        options={employmentStatusOptions.map((o) => ({
          ...o,
          disabled:
            (o.value === "fulltime_freelancer" &&
              talentData.employment_status?.indexOf("parttime_freelancer") >
                -1) ||
            (o.value === "parttime_freelancer" &&
              talentData.employment_status?.indexOf("fulltime_freelancer") > -1)
        }))}
        loading={loading}
      />
      <Field
        name="Travel Distance"
        placeholder="Select Distance"
        value={talentData.travel_distance_preferences}
        options={travelDistanceOptions}
        onChange={(v) => onUpdateTalent({ travel_distance_preferences: v })}
        isMulti
        loading={loading}
        forceColumnLayout
      />
      <Field
        name="Onsite Frequency"
        placeholder="Select Frequency"
        value={talentData.onsite_frequency}
        options={onsiteFrequencyOptions}
        onChange={(v) => {
          const updateVals = { onsite_frequency: v };
          if (v.includes("not_interested")) {
            updateVals["travel_distance_preferences"] = null;
          }
          onUpdateTalent(updateVals);
        }}
        isMulti
        loading={loading}
        forceColumnLayout
      />
    </TalentDetailSection>
  );
}

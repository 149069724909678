import React from "react";
import attachments from "assets/attachments.svg";
import EmptyState from "../components/EmptyState";

const EmptyAttachments = () => {
  const description =
    "Upload files or add links to portfolios, resumes, or other relevant documents \
  to keep everything organized.";

  return (
    <EmptyState
      title="No attachments yet"
      image={attachments}
      description={description}
    />
  );
};

export default EmptyAttachments;

import React, { useContext } from "react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { deleteTalentQualificationV2 } from "utils/adminApi";
import AlertError from "components/AlertError";
import { useAlert } from "react-alert";
import { Modal } from "pages/talent/components";
import { TalentContext } from "../TalentDataContext";

/** Confirmation modal to delete a qualification */
const DeleteQualificationModal = ({ onClose, qualificationId }) => {
  const alert = useAlert();
  const { id } = useContext(TalentContext);
  const queryClient = useQueryClient();
  const qualificationsQueryKey = ["talent_qualifications", id];
  const deleteQualificationMutation = useMutation({
    mutationFn: () => deleteTalentQualificationV2(qualificationId),
    onMutate: async () => {
      await queryClient.cancelQueries({ queryKey: qualificationsQueryKey });
      const previousQualifications = queryClient.getQueryData(
        qualificationsQueryKey
      );

      queryClient.setQueryData(qualificationsQueryKey, (old) => {
        return {
          ...old,
          results: old.results.filter((q) => q.id !== qualificationId)
        };
      });

      return { previousQualifications };
    },
    onSuccess: onClose,
    onError: (err, _, context) => {
      queryClient.setQueryData(
        qualificationsQueryKey,
        context.previousQualifications
      );
      alert.error(
        <AlertError
          error={err}
          onRetry={() =>
            deleteQualificationMutation.mutateAsync(qualificationId)
          }
        />
      );
    },
    onSettled: () => {
      queryClient.invalidateQueries(qualificationsQueryKey);
    }
  });

  return (
    <Modal
      title="Delete Qualification"
      saveBtnText="Delete"
      onClose={onClose}
      onClickSave={deleteQualificationMutation.mutateAsync}
    >
      Are you sure you want to delete this qualification? This action cannot be
      undone and the qualification will be permanently removed.
    </Modal>
  );
};

export default DeleteQualificationModal;

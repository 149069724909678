import React, {useCallback, useEffect, useState} from "react";
import $ from "strings/talent";
import {
  getProjectCompletion,
  getTalentProfileCompletion
} from "../../../utils/misc";


const CompletenessWizard = ({
  talentData,
  isPublicProfile,
  onClickHandlers: {
    onAboutMeClick,
    onProjectsClick,
    onProfilePhotoClick,
    onSkillRatingsClick,
    onToolRatingsClick,
    onIndustryRatingsClick
  } = {},
}) => {
  const [completeness, setCompleteness] = useState(0);
  const [aboutMeChecked, setAboutMeChecked] = useState(false);
  const [projectsChecked, setProjectsChecked] = useState(false);
  const [profilePhotoChecked, setProfilePhotoChecked] = useState(false);
  const [skillRatingsChecked, setSkillRatingsChecked] = useState(false);
  const [toolRatingsChecked, setToolRatingsChecked] = useState(false);
  const [industryRatingsChecked, setIndustryRatingsChecked] = useState(false);

  useEffect(() => {
    const { percentage } = getTalentProfileCompletion(talentData);
    setCompleteness(percentage);
    setAboutMeChecked(talentData.about_me?.length >= 150);
    setProjectsChecked(talentData.talent_created_projects.map(getProjectCompletion).filter(el => el >= 51).length >= 3);
    setProfilePhotoChecked(!!talentData.user?.profile_photo);
    setSkillRatingsChecked(talentData.qualifications.filter(el =>
        el.qualification?.type?.toLowerCase() === 'skill' && el.display_on_talent_profile === true
      ).length >= 5
    );
    setToolRatingsChecked(talentData.qualifications.filter(el =>
        el.qualification?.type?.toLowerCase() === 'tool' && el.display_on_talent_profile === true
      ).length >= 5
    );
    setIndustryRatingsChecked(talentData.qualifications.filter(el =>
      el.qualification?.type?.toLowerCase() === 'industry' && el.display_on_talent_profile === true
    ).length >= 1
    );
  }, [talentData]);

  const filledProgressBarRef = useCallback((node) => {
    if (node !== null) {
      node.style.width = `${completeness}%`; // Set the width based on completeness
    }
  }, [completeness]);

  const sections = [
    {
      title: "About Me",
      subtitle: "Your 3-5 sentence elevator pitch to clients",
      onClick: onAboutMeClick,
      checked: aboutMeChecked,
    },
    {
      title: "Projects",
      subtitle: "Showcase at least 3 past or current projects with contributions & results listed",
      onClick: onProjectsClick,
      checked: projectsChecked,
    },
    {
      title: "Profile Photo",
      subtitle: "Put a face to the name and add a professional headshot",
      onClick: onProfilePhotoClick,
      checked: profilePhotoChecked,
    },
    {
      title: "Skill Ratings",
      subtitle: "Display at least 5 skills to get matched to the right opportunities",
      onClick: onSkillRatingsClick,
      checked: skillRatingsChecked,
    },
    {
      title: "Tool Ratings",
      subtitle: "Display at least 5 tools to get matched to the right opportunities",
      onClick: onToolRatingsClick,
      checked: toolRatingsChecked,
    },
    {
      title: "Industry Ratings",
      subtitle: "Display at least 1 industry to get matched to the right opportunities",
      onClick: onIndustryRatingsClick,
      checked: industryRatingsChecked,
    },
  ];

  const Checkmark = ({checked, size=20}) =>
    <>{
      checked ? (<svg xmlns="http://www.w3.org/2000/svg" width={size}
                      height={size} viewBox="0 0 16 16" fill="none">
        <g clipPath="url(#clip0_2693_8810)">
          <circle cx="8" cy="8" r="8" fill="#00A92F"/>
          <path d="M4.80017 8.80001L6.59217 10.4L11.2002 5.60001"
                stroke="white"
                strokeWidth="1.5" strokeLinecap="round"
                strokeLinejoin="round"/>
        </g>
        <defs>
          <clipPath id="clip0_2693_8810">
            <rect width={size} height={size} fill="white"/>
          </clipPath>
        </defs>
      </svg>) : (<svg xmlns="http://www.w3.org/2000/svg" width={size}
                      height={size} viewBox="0 0 16 16" fill="none">
        <circle cx="8" cy="8" r="7.5" stroke="#0E1539"/>
      </svg>)
    }</>;

  return (
    <>{!isPublicProfile && completeness < 100 &&
      <div className="bg-alice-blue rounded-extra-large p-4 flex flex-col gap-y-3">
        <div className="flex flex-col">
          <div
            className="text-lg font-bold">{$.completeness_wizard_title}</div>
          <div className="text-xs">{$.completeness_wizard_subtitle}</div>
        </div>
        <div className="flex justify-between gap-x-4">
          <div className="h-1 bg-white rounded-full my-auto w-full">
            <div
              ref={filledProgressBarRef}
              className="h-1 bg-electric-indigo rounded-full w-20"
            >&nbsp;</div>
          </div>
          <div className="text-sm">
            {completeness}%
          </div>
        </div>
        <div className="flex flex-col gap-y-2">
          {sections.map((section, index) => (
            <div
              key={index}
              className="flex bg-white rounded-lg p-2.5 gap-x-2 cursor-pointer select-none"
              onClick={section.onClick || (() => {
              })}
            >
              <div>
                <Checkmark checked={section.checked}/>
              </div>
              <div className="flex flex-col gap-y-0.5 w-full">
                <div className="flex flex-row justify-between">
                  <div className="font-semibold text-sm">{section.title}</div>
                  <div className="flex">
                    <div className="my-auto">
                      <svg xmlns="http://www.w3.org/2000/svg" width="8"
                           height="12"
                           viewBox="0 0 6 10" fill="none">
                        <path d="M1 1L5 5L1 9" stroke="#0E1539"/>
                      </svg>
                    </div>
                  </div>
                </div>
                <div className="text-xs">{section.subtitle}</div>
              </div>
            </div>
          ))}
        </div>
      </div>}</>
  )
};

export default CompletenessWizard;

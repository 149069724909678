import React, { useContext } from "react";
import { TalentPageSection } from "../components/components";
import { TalentContext } from "../TalentDataContext";
import { urlify } from "utils/str";
import EmptyAbout from "./EmptyAbout";

const Overview = () => {
  const { talentData } = useContext(TalentContext);

  return (
    <div className="flex flex-col bg-white">
      <TalentPageSection
        title="About the talent"
        id="admin_talent_about"
        fullHeight={false}
      >
        {talentData.about_me ? (
          <div
            className="px-4 pb-4 text-sm font-normal"
            dangerouslySetInnerHTML={{ __html: urlify(talentData.about_me) }}
          />
        ) : (
          <EmptyAbout />
        )}
      </TalentPageSection>
    </div>
  );
};

export default Overview;

import React, { createContext } from "react";
import { useParams } from "react-router-dom";
import {
  getTalentPreferencesV2,
  getAccountV2,
  patchTalentPreferencesV2,
  getTalentOnboardingV2,
  patchTalentOnboardingV2,
  patchAccountV2,
  getContactOverviewV2,
  patchContactOverviewV2
} from "utils/adminApi";
import { useQuery, useQueryClient, useMutation } from "@tanstack/react-query";
import AlertError from "components/AlertError";

const SHOW_DEBUG_LOGS = false;

export const TalentContext = createContext();

/**
 * A custom hook that handles the various talent endpoints that we
 * have. This makes what is happening a *little* opaque, but basically, by passing this hook
 * the GET gn and the PATCH fn - we can link the queries and mutations so that calling mutate
 * will optimistically update the cache, and the UI will display the value.
 *
 * If the mutation fails, we can reset the cache and present the relevant error message.
 * This is not required to use react-query, or the cache, but given that we have 4 sets of GET/PATCH
 * endpoints for the talent endpoint this saves a lot of duplicate code.
 *
 * @param {*} queryKey - a descriptive cache key
 * @param {*} getFn  - the GET fn
 * @param {*} patchFn - the PATCH fn
 * @param {*} id - the id for the fn
 */
const useApiGetPatch = (queryKey, getFn, patchFn, id) => {
  const queryClient = useQueryClient();

  // This is our simple query function. It is keyed in the cache off the
  // queryKey + id. react-query handles all the caching magic.
  const { data, isLoading: loading } = useQuery({
    queryKey: [queryKey, id],
    queryFn: () => getFn(id),
    enabled: !!id
  });

  // Use mutation looks more complicated because we want optimistic updates and
  // cache invalidation. During onMutate we optimistically update the cache, saving
  // the previous state so we can restore it if needed.
  const mutation = useMutation({
    mutationFn: (values) => {
      return patchFn(id, values);
    },
    onMutate: async (newData) => {
      // Cancel any outgoing refetches so they don't overwrite our optimistic update
      await queryClient.cancelQueries({ queryKey: [queryKey, id] });

      const previousData = queryClient.getQueryData([queryKey, id]);
      queryClient.setQueryData([queryKey, id], {
        ...previousData,
        ...newData
      });
      return { previousData, newData };
    },
    onSuccess: (data) => {
      queryClient.setQueryData([queryKey, id], data);
    },
    onError: (err, variables, context) => {
      queryClient.setQueryData([queryKey, id], context.previousData);
      alert.error(
        <AlertError
          error={err}
          onRetry={() => mutation.mutateAsync(variables)}
        />
      );
    },
    // Always refetch after error or success:
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: [queryKey, id] });
    }
  });

  return { data, mutation, loading };
};

/**
 * There are a number of components that all need access to the same data, and
 * additionally want to update that data. The TalentDataProvider consolidates those
 * queries and mutations in the same place so we do not need to pass all the data down.
 * Additionally, it means we don't need to know what query a variable named "talentData"
 * corresponds to.
 *
 * The TalentDataProvider provides a common interface to read and write those fields.
 * Info that is specific to a particular function doesn't necessarily belong here.
 */
export const TalentDataProvider = ({ children }) => {
  const { id } = useParams();

  const {
    data: talentData,
    mutation: updateAccount,
    loading: talentDataLoading
  } = useApiGetPatch("talentAccount", getAccountV2, patchAccountV2, id);

  const {
    data: talentPreferencesData,
    mutation: updateTalentPreferences,
    loading: preferencesLoading
  } = useApiGetPatch(
    "talentPreferences",
    getTalentPreferencesV2,
    patchTalentPreferencesV2,
    talentData?.id
  );

  const {
    data: onboardingData,
    mutation: updateTalentOnboarding,
    loading: onboardingDataLoading
  } = useApiGetPatch(
    "talentOnboarding",
    getTalentOnboardingV2,
    patchTalentOnboardingV2,
    talentData?.id
  );

  const {
    data: contactData,
    mutation: updateContactData,
    loading: contactDataLoading
  } = useApiGetPatch(
    "contactOverview",
    getContactOverviewV2,
    patchContactOverviewV2,
    id
  );

  /**
   * Pass in an {key: value} mapping of fields that need to be updated and they
   * will be distributed to their appropriate endpoints.
   */
  const onUpdateTalent = async (updates) => {
    if (
      !talentData ||
      !talentPreferencesData ||
      !onboardingData ||
      !contactData
    ) {
      console.error("Data not ready");
      return;
    }

    if (SHOW_DEBUG_LOGS) {
      console.log("Updating talent with", updates);
    }

    // Group updates by their data source
    const groupedUpdates = Object.entries(updates).reduce(
      (acc, [key, value]) => {
        if (Object.keys(onboardingData).includes(key)) {
          acc.onboarding[key] = value;
        } else if (Object.keys(talentPreferencesData).includes(key)) {
          acc.preferences[key] = value;
        } else if (Object.keys(talentData).includes(key)) {
          acc.account[key] = value;
        } else if (Object.keys(contactData).includes(key)) {
          acc.contactData[key] = value;
        } else {
          console.error(
            `Key doesn't belong to any data source: ${key}, value: ${value}`
          );
        }
        return acc;
      },
      {
        onboarding: {},
        preferences: {},
        account: {},
        contactData: {}
      }
    );

    // Execute all mutations in parallel
    const results = await Promise.all([
      // Only call mutations if there are updates for that source
      Object.keys(groupedUpdates.onboarding).length > 0
        ? updateTalentOnboarding.mutateAsync(groupedUpdates.onboarding)
        : Promise.resolve(),
      Object.keys(groupedUpdates.preferences).length > 0
        ? updateTalentPreferences.mutateAsync(groupedUpdates.preferences)
        : Promise.resolve(),
      Object.keys(groupedUpdates.account).length > 0
        ? updateAccount.mutateAsync(groupedUpdates.account)
        : Promise.resolve(),
      Object.keys(groupedUpdates.contactData).length > 0
        ? updateContactData.mutateAsync(groupedUpdates.contactData)
        : Promise.resolve()
    ]);

    return results;
  };

  if (SHOW_DEBUG_LOGS) {
    console.log("Contact data", contactData);
    console.log("Talent data", talentData);
    console.log("Preferences data", talentPreferencesData);
    console.log("Onboarding", onboardingData);
  }

  const mergedTalentData = {
    ...contactData,
    ...talentData,
    ...talentPreferencesData,
    ...onboardingData
  };

  return (
    <TalentContext.Provider
      value={{
        id,
        talentData: mergedTalentData,
        loading:
          contactDataLoading ||
          talentDataLoading ||
          preferencesLoading ||
          onboardingDataLoading,
        onUpdateTalent,
        updateAccount
      }}
    >
      {children}
    </TalentContext.Provider>
  );
};

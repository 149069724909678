import React from "react";
import { Link, useParams } from "react-router-dom";
import UilArrowUpLeft from "@iconscout/react-unicons/icons/uil-arrow-up-left";
import { toFormat } from "utils/date";
import { CalendarIcon } from "components/icons";
import ProfileIcon from "components/icons/Profile";
import CycleIcon from "components/icons/Cycle";
import { TalentCompleteness } from "./TalentCompleteness";
import { TalentOwnersSelect } from "./TalentOwners";

const UpperNavBar = ({ to, label, children }) => {
  return (
    <div className="flex w-full items-center rounded-xl bg-white p-2.5">
      <Link to={to} className="flex">
        <UilArrowUpLeft size="18" className="mr-2" />
        <div className="text-sm">{label}</div>
      </Link>
      {children && <div className="ml-auto">{children}</div>}
    </div>
  );
};

const IconTextDetail = ({ icon, title, value }) => {
  return (
    <div className="flex flex-row items-center gap-2 px-2.5">
      {icon && (
        <div className="flex h-5 w-5 items-center rounded-md bg-alice-blue p-1">
          {icon}
        </div>
      )}
      <div className="text-sm font-medium text-kasmir">{title}: </div>
      <div className="text-sm font-semibold text-midnight">{value}</div>
    </div>
  );
};

/**
 * The nav bar that runs across the admin talent page. Displays a back button and top level details
 * like created at, updated at, owners.
 */
export const TalentUpperNavBar = ({ data, talentProfileCompletion }) => {
  let { id } = useParams();
  const params = useParams();
  if (!id) {
    id = params["0"].split("/")[3];
  }

  return (
    <UpperNavBar to="/admin/talent" label="Back to Talent Search">
      {data && (
        <div className="flex flex-row items-center divide-x divide-kasmir text-xs font-light text-kasmir">
          <div className="px-2.5">
            <TalentCompleteness
              talentProfileCompletion={talentProfileCompletion}
            />
          </div>
          <IconTextDetail
            title="Last update"
            icon={<CycleIcon size="16" />}
            value={
              data?.updated_at &&
              toFormat(new Date(data.updated_at), "MM/dd/yyyy")
            }
          />
          <IconTextDetail
            title="Talent Owner"
            icon={<ProfileIcon />}
            value={<TalentOwnersSelect />}
          />
          <IconTextDetail
            title="Created"
            icon={<CalendarIcon size="12" />}
            value={
              data?.created_at &&
              toFormat(new Date(data.created_at), "MM/dd/yyyy")
            }
          />
        </div>
      )}
    </UpperNavBar>
  );
};

export default UpperNavBar;

import { useContext, useState, useCallback } from "react";
import { useDropzone } from "react-dropzone";
import { useAlert } from "react-alert";
import { useQueryClient } from "@tanstack/react-query";
import { Modal } from "pages/talent/components";
import { FormElement } from "pages/talent/Settings/components";
import { Dropdown } from "components/Form";
import { TalentContext } from "../TalentDataContext";
import { attachmentTypeOptions } from "./constants";
import { postTalentFile } from "utils/adminApi";
import AlertError from "components/AlertError";
import UilTimes from "@iconscout/react-unicons/icons/uil-times";

/**
 * Modal to upload a file to the talent account.
 */
const AddFileModal = ({ onClose }) => {
  const queryClient = useQueryClient();
  const [saving, setSaving] = useState(false);
  const [selectedFile, setSelectedFile] = useState();
  const alert = useAlert();
  const { id, talentData } = useContext(TalentContext);
  const [fileType, setFileType] = useState();

  const onCancel = () => {
    setFileType();
    setSelectedFile();
    onClose();
  };

  const onDrop = useCallback((acceptedFiles) => {
    setSelectedFile(acceptedFiles[0]);
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: [
      ".doc",
      ".docx",
      ".pdf",
      ".xls",
      ".xlsx",
      ".ppt",
      ".pptx",
      ".txt",
      ".png",
      ".jpeg",
      ".jpg"
    ],
    multiple: false
  });

  const onSaveFile = async () => {
    setSaving(true);

    const reader = new FileReader();

    reader.onabort = () => {
      alert.error("file reading was aborted");
      console.error("file reading was aborted");
    };
    reader.onerror = () => {
      alert.error("file reading has failed");
      console.error("file reading has failed");
    };
    reader.onload = () => {
      const binaryStr = reader.result;
      upload(
        selectedFile,
        binaryStr,
        async (response) => {
          setSelectedFile(null);
          setFileType(null);
          setSaving(false);
          queryClient.invalidateQueries(["adminTalentFiles", talentData.id]);
          queryClient.invalidateQueries(["talentAccount", id]);
          onClose();
        },
        () => {}
      );
    };

    reader.readAsDataURL(selectedFile);
  };

  const upload = async (file, binaryStr, onSuccess, onFailed) => {
    const idx = file.path.lastIndexOf(".");
    let ftype = "";
    if (idx > -1) {
      ftype = file.path.slice(idx + 1).toUpperCase();
    }
    try {
      const response = await postTalentFile({
        talent_id: id,
        filename: file.path,
        file_type: ftype,
        category: fileType,
        file: binaryStr.replace(/data:.*base64,/g, "")
      });
      onSuccess(response);
    } catch (e) {
      console.error(e);
      alert.error(<AlertError error={e} />);
    }
  };

  return (
    <Modal
      title="Add Attachment"
      saveBtnText={saving ? "Saving..." : "Upload"}
      onClose={onClose}
      onClickCancel={onCancel}
      onClickSave={onSaveFile}
      buttonsDisabled={!selectedFile || !fileType || saving}
    >
      <div className="flex flex-col gap-2.5">
        <div
          {...getRootProps()}
          className="flex h-36 w-full flex-col items-center justify-center gap-2.5 rounded-md border-2 border-dashed border-geyser"
        >
          <input {...getInputProps()} />
          {isDragActive ? (
            <div className="text-sm font-semibold text-midnight">
              Drop file here
            </div>
          ) : (
            <>
              <div className="text-sm font-semibold text-midnight">
                Drag & Drop or{" "}
                <span className="text-electric-indigo">Choose file</span> to
                upload
              </div>
              <div className="text-xs font-medium text-kasmir">
                Maximum file size 50MB.
              </div>
            </>
          )}
        </div>
        <FormElement label="Select file type">
          <Dropdown
            placeholder="Select file type"
            options={attachmentTypeOptions.filter(
              (o) => o.isFileOption === true
            )}
            value={fileType}
            onChange={(v) => setFileType(v.value)}
          />
        </FormElement>
        {selectedFile && (
          <div className="flex w-full flex-row justify-between rounded-md bg-lightest-grey p-2.5">
            <div className="text-sm text-midnight">{selectedFile.name}</div>
            <UilTimes
              size="16"
              onClick={() => {
                setSelectedFile(undefined);
              }}
            />
          </div>
        )}
      </div>
    </Modal>
  );
};

export default AddFileModal;

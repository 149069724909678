import React, { useState, useEffect, useMemo } from "react";
import clsx from "clsx";
import { admin_talent_list_algolia as $ } from "strings";
import userImg from "assets/user.svg";
import Header from "components/ListHeader";
import Input from "components/ListEditable";
import UilBookmark from "@iconscout/react-unicons/icons/uil-file-bookmark-alt";
import UilProject from "@iconscout/react-unicons/icons/uil-briefcase-alt";
import UilClock from "@iconscout/react-unicons/icons/uil-stopwatch";
import UilAward from "@iconscout/react-unicons/icons/uil-award";
import UilBill from "@iconscout/react-unicons/icons/uil-bill";
import UilUser from "@iconscout/react-unicons/icons/uil-user-check";
import UilLinkedIn from "@iconscout/react-unicons/icons/uil-linkedin";
import UilEnvelope from "@iconscout/react-unicons/icons/uil-envelope";
import UilSuitcase from "@iconscout/react-unicons/icons/uil-suitcase";
import { Link } from "react-router-dom";
import Rating from "components/Rating";
import Select from "components/CompactSelect";
import AlertError from "components/AlertError";
import Indicator from "components/ListIndicator";
import { useAlert } from "react-alert";
import {
  patchAccount,
  patchTalentProject,
  postTalentProject
} from "utils/adminApi";
import { toFormat, getDaysText } from "utils/date";
import { withHttp } from "utils/str";
import UilFile from "@iconscout/react-unicons/icons/uil-file";
import {
  getHighlightedText,
  getFullHighlightedText
} from "utils/highlightText";
import { usePageCache } from "contexts/pageCacheContext";
import UilUsersAlt from "@iconscout/react-unicons/icons/uil-users-alt";
import UilCalendar from "@iconscout/react-unicons/icons/uil-calender";

const grades = ["A+", "A", "A-", "B+", "B", "B-", "C"];
const gradeOptions = grades.map((g, i) => ({
  label: g,
  value: 8 - i
}));

const ItemHighlight = ({ matchLevel, value, partial, icon="suitcase" }) => {
  return (matchLevel === "full" || matchLevel === "partial") && (
    <div className="my-2 flex items-center">
      <div className="pr-4">
        <div className="flex h-8 w-8 items-center justify-center rounded-full bg-link text-white">
          {icon === "envelope" ? <UilEnvelope size="20" /> : <UilSuitcase size="20" />}
        </div>
      </div>
      <div className="whitespace-pre-line">
        {!partial ? getFullHighlightedText(value) : getHighlightedText(value)}
      </div>
    </div>
  )
};

const Item = ({
  index,
  data,
  setData,
  searchMatch,
  search,
  keywordBannerEnabled,
  setKeywordBannerEnabled
}) => {
  const { set } = usePageCache();
  const alert = useAlert();
  const [tmpRole, setTmpRole] = useState({});
  const [itemContainsKeywords, setItemContainsKeywords] = useState(false);
  const [states, setStates] = useState({});

  useEffect(() => {
    // if item contains a highlighted keyword,
    // display highlighting AND
    // allow subsequent items to display the keyword banner
    setItemContainsKeywords(false);
    setKeywordBannerEnabled(false);
    const hlr = data._highlightResult;
    const checkMatchLevel = ml => ml === "full" || ml === "partial";
    if (!hlr) return;
    if (
      checkMatchLevel(hlr.note?.matchLevel) ||
      checkMatchLevel(hlr.attached_file?.matchLevel) ||
      checkMatchLevel(hlr.user_name?.matchLevel) ||
      checkMatchLevel(hlr.user_email?.matchLevel) ||
      (Array.isArray(hlr.projects) ?
        hlr.projects?.some(p => checkMatchLevel(p.name?.matchLevel))
        : checkMatchLevel(hlr.projects?.name?.matchLevel)) ||
      (Array.isArray(hlr.projects_created) &&
        hlr.projects_created.some(
          p => (checkMatchLevel(p.name?.matchLevel) || checkMatchLevel(p.results?.matchLevel))
        )
      )
    ) {
      setItemContainsKeywords(true);
      setKeywordBannerEnabled(true);
    }
  }, [data._highlightResult]);

  const setItemData = (result) => {
    setData((d) => {
      const newData = [...d];

      newData[index] =
        typeof result === "function" ? result(newData[index]) : result;

      return newData;
    });
  };

  const qualFiltered = useMemo(
    () =>
      search
        .filter(
          (s) => s.id === "qualifications" && s.data && s.data.qualification
        )
        .map((s) => s.data.qualification.value),
    [search]
  );

  const usingSelf = useMemo(
    () =>
      search
        .filter(
          (s) =>
            s.id === "qualifications" &&
            s.data &&
            s.data.useSelf &&
            s.data.qualification
        )
        .map((s) => s.data.qualification.value),
    [search]
  );

  const qualifications = useMemo(() => {
    if (!data.qualifications) {
      return null;
    }

    const qs = Object.keys(data.qualifications);

    qs.sort((qa, qb) => {
      const a = data.qualifications[qa];
      const b = data.qualifications[qb];
      const incA = qualFiltered.includes(qa);
      const incB = qualFiltered.includes(qb);

      if (!incB && incA) {
        return -1;
      }

      if (incB && !incA) {
        return 1;
      }

      if (!b.rsu && a.rsu) {
        return -1;
      }

      if (b.rsu && !a.rsu) {
        return 1;
      }

      if (b.rsu && a.rsu) {
        return b.rsu - a.rsu;
      }

      if (!b.rsu && !a.rsu) {
        return b.self - a.self;
      }

      return 0;
    });

    return qs.slice(0, 3);
  }, [data.qualifications, qualFiltered]);

  const projects = useMemo(() => {
    if (!data.projects) {
      return null;
    }

    const ps = data.projects
      .filter((p) => p.status === "placed" && p.stage === "filled")
      .sort((a, b) => a.name.localeCompare(b.name));

    return ps;
  }, [data.projects]);

  const onChange = async (key, value, algoliaKey, timestampKey) => {
    const origVal = data[algoliaKey || key];
    const origDate = data[(timestampKey || algoliaKey || key) + "_updated_at"];

    setItemData({
      ...data,
      [algoliaKey || key]: value,
      [(timestampKey || algoliaKey || key) + "_updated_at"]: Date.now()
    });
    setStates({ ...states, [key]: "saving" });

    const call = async () => {
      try {
        await patchAccount(data.user_id, { [key]: value });
        setStates({ ...states, [key]: "saved" });
      } catch (e) {
        setStates({ ...states, [key]: "error" });
        setItemData({
          ...data,
          [algoliaKey || key]: origVal,
          [(timestampKey || algoliaKey || key) + "_updated_at"]: origDate
        });
        console.error(e);
        alert.error(<AlertError error={e} onRetry={call} />);
      }
    };
    await call();
  };

  const onExpChange = async (key, value, algoliaKey) => {
    const origVal = data.latests_experience[algoliaKey || key];

    setItemData({
      ...data,
      latests_experience: {
        ...data.latests_experience,
        [algoliaKey || key]: value
      }
    });
    setStates({ ...states, [key]: "saving" });

    const call = async () => {
      try {
        await patchTalentProject(data.latests_experience.id, { [key]: value });
        setStates({ ...states, [key]: "saved" });
      } catch (e) {
        setStates({ ...states, [key]: "error" });
        setItemData({
          ...data,
          latests_experience: {
            ...data.latests_experience,
            [algoliaKey || key]: origVal
          }
        });
        console.error(e);
        alert.error(<AlertError error={e} onRetry={call} />);
      }
    };
    await call();
  };

  const handleCreateRole = async (key, value) => {
    const newTmp = { ...tmpRole, [key]: value };
    setTmpRole(newTmp);

    const call = async () => {
      try {
        setStates({ ...states, [key]: "saving" });
        const response = await postTalentProject({
          ...newTmp,
          user: data.user_id,
          active: true,
          start_date: toFormat(new Date(), "yyyy-MM-dd"),
          description: newTmp.name
        });

        setItemData((d) => ({
          ...d,
          latests_experience: {
            position: newTmp.name,
            company_name: newTmp.company,
            id: response.id
          }
        }));
        setTmpRole({});
        setStates({ ...states, [key]: "saved" });
      } catch (e) {
        setStates({ ...states, [key]: "error" });
        setItemData((d) => ({
          ...d,
          latests_experience: {}
        }));
        setTmpRole({});
        console.error(e);
        alert.error(<AlertError error={e} onRetry={call} />);
      }
    };

    if (
      (key === "name" && tmpRole.company) ||
      (key === "company" && tmpRole.name)
    ) {
      setStates({ ...states, [key]: "saving" });
      await call();
    }
  };

  return (
    <div className="flex w-full flex-col border-b border-link-water bg-white px-6 py-3 md:flex-row">
      <div className="mr-3 flex w-full flex-shrink-0 flex-row items-center md:w-1/6 md:flex-col md:items-stretch lg:w-2/12">
        <Link
          to={`/admin/talent/${data.user_id}/overview`}
          className="mr-3 inline-block"
        >
          <img
            src={data.profile_photo || userImg}
            className={clsx(
              "h-12 w-12 flex-shrink-0 rounded lg:h-20 lg:w-20",
              !data.excluded &&
                data.availability_utilization >= 40 &&
                "border-4 border-orange-darker",
              !data.excluded &&
                data.availability_utilization < 40 &&
                data.projects_count > 0 &&
                "border-4 border-yellow-dark",
              data.excluded && "border-4 border-red"
            )}
            alt={data.user_name}
          />
          {data.excluded && (
            <div className="-mt-1 w-12 rounded bg-red py-1 text-center font-lato text-xs text-white lg:w-20">
              {$.excluded_label}
            </div>
          )}
          {!data.excluded && data.availability_utilization >= 40 && (
            <div className="-mt-1 w-12 rounded bg-orange-darker py-1 text-center font-lato text-xs text-white lg:w-20">
              {data.availability_utilization === 40
                ? $.fully_utilized_label
                : $.overutilized_label}
            </div>
          )}
          {!data.excluded &&
            data.availability_utilization < 40 &&
            data.projects_count > 0 && (
              <div className="-mt-1 w-12 rounded bg-yellow-dark py-1 text-center font-lato text-xs text-white lg:w-20">
                {$.on_active_projects_label}
              </div>
            )}
        </Link>
        <div className="-mt-1 md:-mt-0">
          <Link
            to={`/admin/talent/${data.user_id}/overview`}
            className="inline-block"
          >
            <h2 className="mb-q text-sm font-bold text-midnight">
              {data._highlightResult.user_name &&
              (data._highlightResult.user_name.matchLevel === "full" ||
                data._highlightResult.user_name.matchLevel === "partial")
                ? getFullHighlightedText(data._highlightResult.user_name.value)
                : data.user_name}
            </h2>
          </Link>
          <div className="flex items-center">
            {data.linkedin_link && (
              <>
                <a
                  className="text-link"
                  href={withHttp(data.linkedin_link)}
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  <UilLinkedIn size="27" />
                </a>
              </>
            )}
            {data.linkedin_link && data.calendar_link && (
              <span className="mx-1 text-kasmir">|</span>
            )}
            {data.calendar_link && (
              <>
                <a
                  className="text-link"
                  href={withHttp(data.calendar_link)}
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  <UilCalendar size="27" />
                </a>
              </>
            )}
          </div>
        </div>
      </div>
      <div className="flex flex-1 flex-col">
        <div className="flex w-full flex-col md:flex-row">
          <div className="w-full md:w-1/3">
            <Header Icon={UilBookmark} text={$.role_title}>
              <Indicator
                state={
                  states.company === "saving" || states.name === "saving"
                    ? "saving"
                    : states.company === "saved" || states.name === "saved"
                      ? "saved"
                      : states.company === "error" || states.name === "error"
                        ? "error"
                        : ""
                }
              />
            </Header>
            {data.latests_experience && data.latests_experience.id && (
              <div className="mb-1 flex flex-wrap items-center">
                <Input
                  text={data.latests_experience.position}
                  defaultValue={data.latests_experience.position}
                  onChange={(v) => onExpChange("name", v, "position")}
                />
                <span className="mx-1 text-sm font-bold text-midnight">at</span>
                <Input
                  text={data.latests_experience.company_name}
                  defaultValue={data.latests_experience.company_name}
                  onChange={(v) => onExpChange("company", v, "company_name")}
                />
              </div>
            )}
            {(!data.latests_experience || !data.latests_experience.id) && (
              <div className="mb-1 flex flex-wrap items-center">
                <Input
                  text={tmpRole.name || "Position"}
                  defaultValue={tmpRole.name}
                  onChange={(v) => handleCreateRole("name", v)}
                />
                <span className="mx-1 text-sm font-bold text-midnight">at</span>
                <Input
                  text={tmpRole.company || "Company"}
                  defaultValue={tmpRole.company}
                  onChange={(v) => handleCreateRole("company", v)}
                />
              </div>
            )}
            <Header Icon={UilBill} text={$.minimum_rate_title}>
              <Indicator state={states.minimum_rate} />
            </Header>
            <div className="mb-1 flex items-center">
              <Input
                type="number"
                text={
                  data.minimum_rate
                    ? `$${data.minimum_rate}/hour`
                    : "Unknown Rate"
                }
                defaultValue={data.minimum_rate}
                onChange={(v) => onChange("minimum_rate", v)}
              />
              {data.minimum_rate_updated_at && (
                <span className="ml-1 text-xs font-bold text-midnight">
                  ({getDaysText(data.minimum_rate_updated_at)})
                </span>
              )}
            </div>
            <Link
              to={`/admin/talent/${data.user_id}/projects`}
              className="inline-block"
            >
              <Header Icon={UilProject} text={$.projects_title} />
            </Link>
            {projects && !!projects.length && (
              <div className="flex flex-col">
                {projects.slice(0, 3).map((p, i) => (
                  <div key={p.id}>
                    <Link
                      className="text-sm font-bold text-link"
                      to={`/admin/project/${p.project}`}
                    >
                      {p.name}
                    </Link>
                  </div>
                ))}
                {projects && projects.length > 1 && (
                  <Link
                    className="flex h-8 w-8 rounded border border-transparent text-base font-bold text-midnight hover:border-link-water"
                    to={`/admin/talent/${data.user_id}/projects`}
                  >
                    ...
                  </Link>
                )}
              </div>
            )}
            {(!projects || !projects.length) && (
              <div className="text-sm text-midnight">
                Not active on any projects.
              </div>
            )}
          </div>
          <div className="w-full md:w-1/3">
            <Header Icon={UilClock} text={$.availability_title}>
              <Indicator state={states.availability_capacity} />
            </Header>
            <div className="mb-1 flex items-center">
              <Input
                type="number"
                text={
                  Number.isInteger(data.availability_hours)
                    ? `${data.availability_hours} hours per week`
                    : Number.isInteger(data.availability_capacity)
                      ? `${data.availability_capacity} hours per week`
                      : "Unknown Avail."
                }
                disabled
                defaultValue={
                  data.availability_hours
                    ? data.availability_hours
                    : data.availability_capacity
                }
                onChange={(v) =>
                  onChange(
                    "availability_capacity",
                    v,
                    "availability_capacity",
                    "availability"
                  )
                }
              />
              {data.availability_updated_at && (
                <span className="ml-1 text-xs font-bold text-midnight">
                  ({getDaysText(data.availability_updated_at)})
                </span>
              )}
            </div>
            <Header Icon={UilUser} text={$.grade_title}>
              <Indicator state={states.talent_grade} />
            </Header>
            <div className="mb-1 flex items-center">
              <Select
                className={clsx("-ml-1", data.talent_grade ? "w-8" : "w-28")}
                value={
                  data.talent_grade
                    ? {
                        label: grades[8 - data.talent_grade],
                        value: data.talent_grade
                      }
                    : { label: "No Talent Grade", value: null }
                }
                onChange={(v) => onChange("talent_grade", v ? v.value : null)}
                options={gradeOptions}
              />
              {data.talent_grade_updated_at && (
                <span className="ml-1 mt-1 text-xs font-medium text-midnight">
                  ({getDaysText(data.talent_grade_updated_at)})
                </span>
              )}
            </div>
            <Header Icon={UilUsersAlt} text={$.owners_title}>
              <Indicator />
            </Header>
            <div className="mb-1 flex items-center text-sm font-bold text-midnight">
              {Array.isArray(data.owners) && data.owners.slice(0, 2).join(", ")}
              {Array.isArray(data.owners) && data.owners.length > 2 && (
                <>, ...</>
              )}
              {(!data.owners || !data.owners?.length) && (
                <span className="mb-1 font-bold text-kasmir">
                  {$.no_owners_text}
                </span>
              )}
            </div>
          </div>
          <div className="w-full md:w-1/3">
            <Header Icon={UilAward} text={$.qualifications_title} />
            {(!qualifications || !qualifications.length) && (
              <>
                <div className="text-sm font-medium">
                  No Qualifications Saved
                </div>
                <Link
                  className="text-sm font-medium text-kasmir underline"
                  to={`/admin/talent/${data.user_id}/overview`}
                >
                  Add Qualifications
                </Link>
              </>
            )}
            {qualifications && qualifications.length > 0 && (
              <>
                <div className="text-sm font-medium">
                  {qualifications.map((q) => (
                    <div key={q} className="mb-2 flex items-center">
                      <div className="w-1/2 text-midnight">
                        {data.qualifications[q].name}
                      </div>
                      {!usingSelf.includes(q) &&
                      (data.qualifications[q].rsu ||
                        !data.qualifications[q].self) ? (
                        <div className="flex flex-1 items-center text-kasmir">
                          RSU-rating:
                          <Rating count={data.qualifications[q].rsu} />
                        </div>
                      ) : (
                        <div className="flex flex-1 items-center text-kasmir">
                          Self-rating:
                          <Rating count={data.qualifications[q].self} />
                        </div>
                      )}
                    </div>
                  ))}
                </div>
                <Link
                  className="text-sm font-medium text-kasmir underline"
                  to={`/admin/talent/${data.user_id}/overview`}
                >
                  {$.all_qualifications_link}
                </Link>
              </>
            )}
          </div>
        </div>
        {itemContainsKeywords ? (
          <div className="w-full">
            <Header Icon={UilFile} text="Keyword Matches" />
            <div className="text-sm text-midnight">
              {data._highlightResult.note &&
                (data._highlightResult.note.matchLevel === "full" ||
                  data._highlightResult.note.matchLevel === "partial") && (
                  <div className="my-2 flex items-center">
                    <div className="mr-3 flex flex-shrink-0 items-center">
                      {data.note_created_by && (
                        <div className="text-md flex h-8 w-8 items-center justify-center rounded-full bg-medium-green font-light text-white">
                          {data.note_created_by
                            .split(" ")
                            .map((w) => w[0])
                            .join("")}
                        </div>
                      )}
                      <div className="ml-1 text-sm text-kasmir">
                        {toFormat(
                          new Date(data.note_created_at * 1000),
                          "MM/dd/yy"
                        )}
                      </div>
                    </div>
                    <Link
                      className="cursor-pointer whitespace-pre-line"
                      onClick={() => {
                        let noteId = `${data.objectID.split("-").splice(5, 5).join("-")}`;
                        set(`talent_highlightednote__${data.user_id}`, {
                          id: noteId,
                          keyword: searchMatch.replace(/["']/g, "")
                        });
                      }}
                      to={`/admin/talent/${data.user_id}/notes`}
                    >
                      {getHighlightedText(data._highlightResult.note.value)}
                    </Link>
                  </div>
                )}
              {data._highlightResult.attached_file &&
                <ItemHighlight
                  matchLevel={data._highlightResult.attached_file.matchLevel}
                  value={data._highlightResult.attached_file.value}
                  partial={true}
                />
              }
              {data._highlightResult.user_email &&
                <ItemHighlight
                  matchLevel={data._highlightResult.user_email.matchLevel}
                  value={data._highlightResult.user_email.value}
                  icon="envelope"
                />
              }
              {data._highlightResult.projects &&
                (!Array.isArray(data._highlightResult.projects)
                  ? <ItemHighlight
                    matchLevel={data._highlightResult.projects.name.matchLevel}
                    value={data._highlightResult.projects.name.value}
                  />
                  : data._highlightResult.projects.map(
                      hit => <ItemHighlight matchLevel={hit.name.matchLevel} value={hit.name.value} />
                    )
                )
              }
              {data._highlightResult.projects_created &&
                data._highlightResult.projects_created.map(
                  hit => (<>
                    <ItemHighlight matchLevel={hit.name?.matchLevel} value={hit.name?.value} />
                    <ItemHighlight matchLevel={hit.results?.matchLevel} value={hit.results?.value} />
                  </>)
                )
              }
            </div>
          </div>
        ) : (
          <div />
        )}
        {!itemContainsKeywords && keywordBannerEnabled && (
          <div className="border-1 mt-2 rounded-md bg-orange p-2 text-xs">
            {$.highlight_warning_text}
          </div>
        )}
      </div>
    </div>
  );
};

export default Item;

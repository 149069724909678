import React, { useEffect, useRef, useState } from "react";
import clsx from 'clsx';
import useScript from "hooks/useScript";
import axios from "axios";
import { CancelBtn, SaveBtn, RemoveBtn } from "components/Buttons";

const addressComponents = {
  street_number: "street_number",
  route: "street",
  neighborhood: "neighborhood",
  sublocality_level_1: "locality",
  locality: "city",
  administrative_area_level_1: "state",
  country: "country",
  postal_code: "zipcode",
};
const mapAddress = (place, timezone, full_address) => {
  const address = {
    location_id: place.place_id,
    street_number: "",
    street: "",
    neighborhood: "",
    locality: "",
    city: "",
    state: "",
    state_code: "",
    country: "",
    zipcode: "",
    timezone_id: timezone ? timezone.timeZoneId : "",
    timezone_name: timezone ? timezone.timeZoneName : "",
    full_address,
  };

  place.address_components.forEach((add) => {
    const type = add.types[0];

    if (addressComponents[type]) {
      address[addressComponents[type]] = add.long_name;

      if (addressComponents[type] === "state") {
        address["state_code"] = add.short_name;
      }
    }
  });

  return address;
};

const AddressInput = ({
  onSave,
  onDelete,
  canDelete,
  defaultFullAddress,
  disabled,
  onDisabledClick,
  placeholder = "Not Set"
}) => {
  const [addressValue, setAddressValue] = useState();
  const [isDirty, setIsDirty] = useState(false);

  const [loaded] = useScript(
    `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_KEY}&libraries=places`
  );

  const mapLoaded = useRef();
  const inputRef = useRef();

  useEffect(() => {
    if (!defaultFullAddress) return;
    const val = defaultFullAddress.trim();
    setAddressValue(val);
  }, [defaultFullAddress]);

  useEffect(() => {
    const handle = async (place) => {
      const response = await axios.get(
        `https://maps.googleapis.com/maps/api/timezone/json?timestamp=${Math.round(
          new Date() / 1000
        )}&location=${place.geometry.location.lat()},${place.geometry.location.lng()}&key=${
          process.env.REACT_APP_GOOGLE_KEY
        }`
      );
      setAddressValue(mapAddress(place, response.data, inputRef.current.value));
      setIsDirty(true);
    };

    if (loaded && !mapLoaded.current) {
      const autocomplete = new window.google.maps.places.Autocomplete(
        inputRef.current
      );

      autocomplete.setFields([
        "place_id",
        "address_components",
        "geometry",
        "icon",
        "name",
        "timezone"
      ]);

      autocomplete.setTypes(["geocode"]);

      autocomplete.addListener("place_changed", () => {
        var place = autocomplete.getPlace();
        if (!place.geometry) {
          // User entered the name of a Place that was not suggested and
          // pressed the Enter key, or the Place Details request failed.
          return;
        }

        handle(place);
      });

      mapLoaded.current = true;
    }
  }, [loaded]);

  return (
    <div className="flex w-full">
      <div className={clsx("align-center flex w-full items-center")}>
        <div className="my-px w-full items-center">
          <input
            maxLength="250"
            ref={inputRef}
            placeholder={placeholder}
            defaultValue={addressValue || ""}
            className="h-6 w-full appearance-none rounded border-2 border-white bg-white text-sm font-bold text-midnight focus:border-2 focus:border-geyser focus:outline-none placeholder:font-normal placeholder:text-grey"
            style={{ textOverflow: "ellipsis" }}
            onClick={() => {
              if (disabled && onDisabledClick) {
                onDisabledClick();
                return;
              }
            }}
            disabled={disabled}
          />
        </div>
        {!isDirty && addressValue && canDelete && (
          <RemoveBtn
            className="mb-px"
            onClick={() => {
              setIsDirty(false);
              inputRef.current.value = null;
              onDelete();
            }}
          />
        )}
      </div>
      {isDirty && (
        <>
          <SaveBtn
            onClick={() => {
              onSave(addressValue);
              setIsDirty(false);
            }}
          />
          <CancelBtn
            onClick={() => {
              setIsDirty(false);
              inputRef.current.value = defaultFullAddress;
            }}
          />
        </>
      )}
    </div>
  );
};

export default AddressInput;

import React, { useMemo } from "react";
import { CompanyExperienceListItem } from "components/ExperiencesListItems";
import { orderBy } from "lodash";
import { TalentPageSection } from "../components/components";
import EmptyExperiences from "./EmptyExperiences";

const Experiences = ({ data }) => {
  const talentExperiences = useMemo(() => {
    if (!data) {
      return undefined;
    }

    const existingProjectIds = {};
    const projects = {};

    const orderedExperiences = orderBy(
      data.results,
      [
        // Projects with no date info last
        (project) => (project.end_date || project.start_date ? 0 : 1),
        // ongoing experiences first
        (project) => (project.end_date ? 1 : 0),
        // then by end date, then by start date - if no end date
        (project) => project.end_date || project.start_date
      ],
      ["asc", "asc", "desc"]
    );
    for (const project of orderedExperiences) {
      existingProjectIds[project.project_id] = true;
      const key = project.company?.name || "Company unknown";
      if (!projects[key]) {
        projects[key] = [];
      }
      projects[key].push({
        ...project,
        managed_by_rsu: !!project.project_id
      });
    }

    return projects;
  }, [data]);

  return (
    <TalentPageSection id="admin_talent_experiences" title="Experience">
      <div className="flex h-full flex-col px-4 text-midnight">
        {talentExperiences && Object.entries(talentExperiences).length > 0 ? (
          <div className="flex flex-col gap-2.5 pb-16">
            {Object.entries(talentExperiences).map(
              ([companyName, projects], i) => (
                <CompanyExperienceListItem
                  key={i}
                  companyName={companyName}
                  projects={projects}
                  showCompletenessBadge={true}
                  compact
                />
              )
            )}
          </div>
        ) : (
          <EmptyExperiences />
        )}
      </div>
    </TalentPageSection>
  );
};

export default Experiences;

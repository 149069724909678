import React, { useState, useMemo } from "react";
import AttachmentListItem from "./AttachmentListItem";
import clsx from "clsx";
import UilDirection from "@iconscout/react-unicons/icons/uil-direction";
import UilUp from "@iconscout/react-unicons/icons/uil-angle-up";
import UilDown from "@iconscout/react-unicons/icons/uil-angle-down";

/**
 * Renders the table that displays all talent attachments and files.
 */
export const AttachmentsList = ({ attachments, links }) => {
  const [sortField, setSortField] = useState("attachedOn");
  const [sortDirection, setSortDirection] = useState("-"); // "-" for descending, "" for ascending

  // Combine attachments and links into a single list with normalized data
  const combinedItems = useMemo(() => {
    const normalizedAttachments = attachments.map((f) => ({
      id: f.id,
      type: f.category,
      attachedBy: f.created_by,
      attachedOn: new Date(f.created_at),
      info: `${f.file_type === "TalentLink" || f.file_type === "URL" ? f.url : f.filename}${!!f.size ? `, ${f.size}` : ""}`,
      filename: f.filename,
      isFile: true,
      originalData: f
    }));

    const normalizedLinks = links.map((l) => ({
      id: l.id,
      type: l.name,
      attachedBy: l.updated_by,
      attachedOn: new Date(l.updated_at),
      info: l.url,
      url: l.url,
      isFile: false,
      originalData: l
    }));

    const combined = [...normalizedAttachments, ...normalizedLinks];

    // Sort the combined list
    return combined.sort((a, b) => {
      // Sort by date
      if (sortField === "attachedOn") {
        const dateA = a.attachedOn;
        const dateB = b.attachedOn;

        if (sortDirection === "-") {
          return dateB - dateA; // Descending
        } else {
          return dateA - dateB; // Ascending
        }
      }
      return 0;
    });
  }, [attachments, links, sortField, sortDirection]);

  // Handle sort toggle
  const handleSort = (field) => {
    if (sortField === field) {
      // Toggle direction if the same field is clicked
      setSortDirection(sortDirection === "-" ? "" : "-");
    } else {
      // Set new field with default descending direction
      setSortField(field);
      setSortDirection("-");
    }
  };

  // Render sort button
  const renderSortButton = (field, label) => {
    const isSorted = sortField === field;

    return (
      <div className="flex items-center">
        <span>{label}</span>
        <button
          onClick={() => handleSort(field)}
          className="group ml-1 h-6 w-6 focus:outline-none"
        >
          <span
            className={clsx(
              "flex h-6 w-6 appearance-none items-center justify-center rounded focus:outline-none active:bg-geyser",
              isSorted
                ? "text-link hover:text-link-dark"
                : "text-geyser hover:text-kasmir"
            )}
            tabIndex="-1"
          >
            {!isSorted && <UilDirection size="22" />}
            {isSorted && sortDirection === "-" && <UilUp size="22" />}
            {isSorted && sortDirection === "" && <UilDown size="22" />}
          </span>
        </button>
      </div>
    );
  };

  return (
    <div className="w-full rounded-lg bg-white pb-16">
      <div
        className="mb-3 grid w-full border-b-2 px-4 py-2.5 text-sm font-semibold text-midnight"
        style={{
          gridTemplateColumns: `repeat(${6}, minmax(0, 1fr))`
        }}
      >
        <div>Type</div>
        <div>Attached By</div>
        {renderSortButton("attachedOn", "Attached On")}
        <div className="col-span-2">Attachment Info</div>
        <div className="text-right">Actions</div>
      </div>
      <div className="flex flex-col gap-1 px-2.5">
        {combinedItems.map((item) => (
          <AttachmentListItem
            key={`${item.isFile ? "file" : "link"}-${item.id}`}
            id={item.id}
            attachmentName={item.type}
            attachedBy={item.attachedBy}
            attachedOn={item.attachedOn.toISOString()}
            info={item.info}
            filename={item.filename}
            url={item.url}
            isFile={item.isFile}
          />
        ))}
      </div>
    </div>
  );
};

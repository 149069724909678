import React, { useContext, useState } from "react";
import { useHistory } from "react-router-dom";
import AlertError from "components/AlertError";
import { useAlert } from "react-alert";
import { deserialize, serialize } from "utils/htmlSerializer";
import { patchTalentNoteV2, patchStatusNote } from "utils/adminApi";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { TalentContext } from "../TalentDataContext";
import NoteModal from "./NoteModal";

export const usePatchNote = (talentId, note) => {
  const alert = useAlert();
  const queryClient = useQueryClient();

  const patchNoteMutation = useMutation({
    mutationFn: ({ pinned, body }) => {
      let toSend = {};

      if (pinned !== undefined) {
        toSend["pinned"] = pinned;
      }

      if (body !== undefined) {
        toSend[note.type === "status_history" ? "description" : "note_body"] =
          body;
      }

      if (note.type === "status_history") {
        return patchStatusNote(note.id, toSend);
      } else {
        return patchTalentNoteV2(note.talent_ref, note.type, note.id, toSend);
      }
    },
    onError: (err, newVals) => {
      alert.error(
        <AlertError
          error={err}
          retry={() => patchNoteMutation.mutateAsync(newVals)}
        />
      );
    },
    onSettled: () => {
      // Always refetch after error or success to ensure we're in sync with the server
      queryClient.invalidateQueries({ queryKey: ["talentNotes", talentId] });
    }
  });

  return patchNoteMutation.mutateAsync;
};

const EditNoteModal = ({ note, onClose }) => {
  const history = useHistory();
  const { id, talentData } = useContext(TalentContext);
  const patchNote = usePatchNote(id, note);
  const [saving, setSaving] = useState();

  const [values, setValues] = useState({
    body: deserialize(
      new DOMParser().parseFromString(
        note.note_body && note.note_body.includes("<p>")
          ? note.note_body
          : (note.note_body || "")
              .split("\n")
              .map((t) => "<p>" + t + "</p>")
              .join(""),
        "text/html"
      ).body
    )
  });

  const onSubmit = async () => {
    setSaving(true);

    const newBody = serialize({ children: values.body });

    if (newBody === note.note_body) {
      setSaving(false);
      onClose();
      return;
    }

    try {
      await patchNote({ body: newBody });
      history.push(`/admin/talent/${id}/notes`);

      let el = document.getElementById(note.id);
      el?.scrollIntoView({
        behavior: "smooth",
        block: "nearest",
        inline: "nearest"
      });

      onClose();
    } catch (e) {
      setSaving(false);
      // Error is handled by the onError callback in the mutation
    }
  };

  return (
    <NoteModal
      title={`Edit Note for ${talentData.fullname}`}
      id="edit-note"
      saveBtnText="Save"
      saving={saving}
      values={values}
      setValues={setValues}
      onSave={onSubmit}
      onCancel={onClose}
    />
  );
};

export default EditNoteModal;

import { admin_talent_attachments as $ } from "strings";
import $$ from "strings/talent";
import Behance from "components/icons/links/Behance.svg";
import Dribble from "components/icons/links/Dribble.svg";
import Facebook from "components/icons/links/Facebook.svg";
import GeneralWebsite from "components/icons/links/GeneralWebsite.svg";
import LinkedIn from "components/icons/links/LinkedIn.svg";
import Medium from "components/icons/links/Medium.svg";
import Pinterest from "components/icons/links/Pinterest.svg";
import Substack from "components/icons/links/Substack.svg";
import TikTok from "components/icons/links/TikTok.svg";
import Vimeo from "components/icons/links/Vimeo.svg";
import WordPress from "components/icons/links/WordPress.svg";
import XTwitter from "components/icons/links/XTwitter.svg";
import Youtube from "components/icons/links/Youtube.svg";
import Calendar from "components/icons/links/Calendar.svg";
import FreelanceProfile from "components/icons/links/Freelacne Profile.svg";
import PersonalWebsite from "components/icons/links/Personal website.svg";
import Blog from "components/icons/links/Blog.svg";
import PRArticle from "components/icons/links/PR Article.svg";
import WorkSample from "components/icons/links/Work Sample.svg";
import Portfolio from "components/icons/links/Portfolio.svg";
import File from "components/icons/links/File.svg";

export const attachmentTypeOptions = [
  {
    label: $.resume_option,
    value: "Talent Resume",
    isURLOption: true,
    isFileOption: true,
    icon: File
  },
  {
    label: $.portfolio_option,
    value: "Portfolio",
    isURLOption: true,
    isFileOption: true,
    icon: Portfolio
  },
  {
    label: $.work_sample_option,
    value: "Work Sample",
    isURLOption: true,
    isFileOption: true,
    icon: WorkSample
  },
  {
    label: $.pr_article_option,
    value: "PR Article",
    isURLOption: true,
    isFileOption: true,
    icon: PRArticle
  },
  {
    label: $.blog_option,
    value: "Blog Website",
    isURLOption: true,
    isFileOption: false,
    icon: Blog
  },
  {
    label: $.website_option,
    value: "Website",
    isURLOption: true,
    isFileOption: false,
    icon: PersonalWebsite
  },
  {
    label: $.freelance_profile_option,
    value: "Freelance Profile",
    isURLOption: true,
    isFileOption: false,
    icon: FreelanceProfile
  },
  {
    label: $.linkedin_option,
    value: "LinkedIn",
    isURLOption: true,
    isFileOption: false,
    icon: LinkedIn
  },
  {
    label: $.scheduling_link_option,
    value: "Scheduling Link",
    isURLOption: true,
    isFileOption: false,
    icon: Calendar
  },
  {
    label: $.twitter_option,
    value: "Twitter",
    isURLOption: false,
    isFileOption: false,
    icon: XTwitter
  },
  {
    label: $$.general_website_label,
    value: "general_website",
    isURLOption: true,
    isFileOption: false,
    icon: GeneralWebsite
  },
  {
    label: $$.x_twitter_label,
    value: "x_twitter",
    isURLOption: true,
    isFileOption: false,
    icon: XTwitter
  },
  {
    label: $$.facebook_label,
    value: "facebook",
    isURLOption: true,
    isFileOption: false,
    icon: Facebook
  },
  {
    label: $$.tiktok_label,
    value: "tiktok",
    isURLOption: true,
    isFileOption: false,
    icon: TikTok
  },
  {
    label: $$.linkedin_label,
    value: "linkedin",
    isURLOption: true,
    isFileOption: false,
    icon: LinkedIn
  },
  {
    label: $$.dribble_label,
    value: "dribble",
    isURLOption: true,
    isFileOption: false,
    icon: Dribble
  },
  {
    label: $$.behance_label,
    value: "behance",
    isURLOption: true,
    isFileOption: false,
    icon: Behance
  },
  {
    label: $$.pinterest_label,
    value: "pinterest",
    isURLOption: true,
    isFileOption: false,
    icon: Pinterest
  },
  {
    label: $$.vimeo_label,
    value: "vimeo",
    isURLOption: true,
    isFileOption: false,
    icon: Vimeo
  },
  {
    label: $$.youtube_label,
    value: "youtube",
    isURLOption: true,
    isFileOption: false,
    icon: Youtube
  },
  {
    label: $$.medium_label,
    value: "medium",
    isURLOption: true,
    isFileOption: false,
    icon: Medium
  },
  {
    label: $$.substack_label,
    value: "substack",
    isURLOption: true,
    isFileOption: false,
    icon: Substack
  },
  {
    label: $$.wordpress_label,
    value: "wordpress",
    isURLOption: true,
    isFileOption: false,
    icon: WordPress
  }
];

import React, { useState, useRef, useEffect } from "react";
import UilDown from "@iconscout/react-unicons/icons/uil-angle-down";
import UilUp from "@iconscout/react-unicons/icons/uil-angle-up";
import clsx from "clsx";
import UilCheck from "@iconscout/react-unicons/icons/uil-check";

/**
 * The following is a dropdown button that operates as a filter.
 * The values are expected to be a list of values in `options`.
 * 
 * There can additionally be an "all" option - which functionally
 * works as if there is no filter applied.
 * 
 * See the notes section on the admin talent page for sample usage.
 */
export const MultiSelectDropdown = ({
  values,
  setValues,
  options,
  compact = false,
  renderDisplayLabel
}) => {
  // We need to update `options` with onClick handlers, becase
  // that is what the DropdownButtonMenu expects

  const renderOptionLabel = (option) => {
    const checked = values.includes(option.value);

    return (
      <div className="flex items-center">
        <div
          className={clsx(
            "mr-1.5 flex h-4 w-4 flex-shrink-0 items-center justify-center rounded text-sm text-white",
            checked ? "bg-electric-indigo" : "bg-geyser"
          )}
        >
          {checked && <UilCheck />}
        </div>
        <div
          className={clsx(
            option.value === "all" ? "text-midnight" : "text-kasmir"
          )}
        >
          {option.label}
        </div>
      </div>
    );
  };

  const onClick = (option) => {
    if (option.value === "all") {
      if (values.includes("all")) {
        setValues([]);
      } else {
        setValues(options.map((o) => o.value));
      }
    } else {
      if (values.includes(option.value)) {
        setValues(values.filter((v) => v !== option.value && v !== "all"));
      } else {
        setValues([...values, option.value]);
      }
    }
  };

  const filterOptions = options.map((o) => {
    return { ...o, onClick: () => onClick(o) };
  });

  const label = renderDisplayLabel
    ? renderDisplayLabel(values)
    : values?.map((v) => options.find((o) => o.value === v).label).join(", ");

  return (
    <DropdownSelect
      options={filterOptions}
      closeOnOptionSelect={false}
      label={label}
      compact={compact}
      renderOptionLabel={renderOptionLabel}
      className="h-8 rounded-md bg-white px-2 text-sm font-medium text-midnight"
    />
  );
};

const DropdownSelect = ({
  options,
  label,
  compact = false,
  renderOptionLabel,
  closeOnOptionSelect = true,
  className,
  justifyRight = true
}) => {
  const buttonRef = useRef(null);
  const menuRef = useRef(null);
  const dropdownRef = useRef(null);
  const [isOpen, setOpen] = useState(false);
  const [showAbove, setShowAbove] = useState(false);

  // Set initial position when opening
  useEffect(() => {
    const calculatePosition = () => {
      if (!buttonRef.current) return false;

      const buttonRect = buttonRef.current.getBoundingClientRect();
      const windowHeight = window.innerHeight;
      const menuHeight = 40 * options.length + 40;
      const spaceBelow = windowHeight - buttonRect.bottom;
      const spaceAbove = buttonRect.top;

      return spaceBelow < menuHeight && spaceAbove > spaceBelow;
    };

    if (isOpen) {
      setShowAbove(calculatePosition());
    }
  }, [isOpen, options.length]); // Only recalculate when opening

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setOpen(false);
      }
    };

    if (isOpen) {
      document.addEventListener("mousedown", handleClickOutside);

      // Create intersection observer to detect if dropdown is cut off
      const observer = new IntersectionObserver(
        ([entry]) => {
          if (!entry.isIntersecting) {
            // If dropdown is not fully visible, try showing it above
            const buttonRect = buttonRef.current.getBoundingClientRect();
            const menuHeight = 40 * options.length;
            const spaceAbove = buttonRect.top;

            // Only show above if there's enough space and it's currently showing below
            if (spaceAbove >= menuHeight && !showAbove) {
              setShowAbove(true);
            }
          }
        },
        {
          root: null,
          threshold: 1.0
        }
      );

      if (dropdownRef.current) {
        observer.observe(dropdownRef.current);
      }

      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
        observer.disconnect();
      };
    }
  }, [isOpen, options.length, showAbove]);

  return (
    <div
      ref={menuRef}
      className={clsx(
        className ||
          (compact
            ? "rounded-md bg-lightest-grey py-0.5 text-midnight"
            : "h-9.5 rounded-lg bg-alice-blue text-xs text-electric-indigo sm:text-sm sm:font-bold"),
        "relative flex cursor-pointer items-center px-2.5",
        justifyRight && "ml-auto"
      )}
    >
      <div
        ref={buttonRef}
        className="flex w-full flex-row justify-between"
        onClick={() => {
          setOpen(!isOpen);
        }}
      >
        <div className={clsx(compact ? "text-sm font-bold" : "")}>{label}</div>
        {isOpen ? <UilUp size="20" /> : <UilDown size="20" />}
      </div>
      {isOpen && (
        <div
          ref={dropdownRef}
          className={clsx(
            "min-w-full rounded-md border bg-white p-1 text-sm",
            "absolute right-0 z-10 flex cursor-pointer flex-col gap-1",
            showAbove ? "bottom-full mb-1" : "top-full mt-1"
          )}
        >
          {options?.map((option, i) => (
            <div
              className={clsx(
                "flex whitespace-nowrap rounded-md p-0.5 px-1 font-normal text-kasmir hover:bg-lightest-grey"
              )}
              onClick={() => {
                option.onClick();
                if (closeOnOptionSelect) setOpen(false);
              }}
              key={i}
            >
              {renderOptionLabel ? renderOptionLabel(option) : option.label}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default DropdownSelect;

import React, { useEffect, useState } from "react";
import clsx from "clsx";
import { admin_project_overview as $ } from "strings";
import { Prompt } from "react-router-dom";
import RichTextEditor from "components/RichTextEditorNew";
import UilArrow from "@iconscout/react-unicons/icons/uil-arrow-circle-right";
import StageFilledForm, {
  getFilledNote,
  onFilledSave
} from "./StageFilledForm";
import StageShadowPitchedForm, {
  getShadowPitchedNote,
  onShadowPitchedSave
} from "./StageShadowPitchedForm";
import StageMatchingSupportForm, {
  getMatchingSupportNote,
  onMatchingSupportSave
} from "./StageMatchingSupportForm";
import StageSubmittedForm, {
  getSubmittedNote,
  onSubmittedSave
} from "./StageSubmittedForm";
import StageLostForm, { getLostNote, onLostSave } from "./StageLostForm";
import StagePassiveForm, {
  getPassiveNote,
  onPassiveSave
} from "./StagePassiveForm";
import StageCompletedForm, {
  getCompletedNote,
  onCompletedSave
} from "./StageCompletedForm";
import { isBodyEmpty } from "components/RichTextEditorNew";
import { getAdminUser } from "utils/localStorageService";
import AlertError from "components/AlertError";
import { useAlert } from "react-alert";
import { serialize } from "utils/htmlSerializer";
import { postProjectNote, getProjectV2 } from "utils/adminApi";
import { usePageCache } from "contexts/pageCacheContext";
import useConfirmClose from "hooks/useConfirmClose";

const stageOptions = {
  filled: "Filled",
  shadow_pitched: "Proposal Submitted",
  matching_support: "Matching Support",
  submitted: "Talent Pitched",
  lost: "Lost",
  passive: "Passive",
  completed: "Completed"
};

const NoteForm = ({ data, setStage, setData, stage, addNote }) => {
  const user = getAdminUser();
  const alert = useAlert();
  const [saving, setSaving] = useState();
  const [context, setContext] = useState();
  const { set, cache } = usePageCache();
  const projectId = data?.id;
  const [values, setValues] = useState(
    cache["admin_project_overview_note_draft__" + projectId] || {
      body: [{ type: "paragraph", children: [{ text: "" }] }]
    }
  );
  const isStageChangeNote = stage && Object.keys(stageOptions).includes(stage);

  const updateVal = (k, v) => {
    setValues((vs) => ({ ...vs, [k]: v }));
  };

  const isEmpty = isBodyEmpty(values.body);
  const { canClose, confirmMsg } = useConfirmClose(!isEmpty);

  useEffect(() => {
    Object.keys(values).forEach((v) => {
      if (data[v] && data[v] !== values[v]) {
        if (v === "seniorities") {
          const seniorities = data["seniorities"].map((s) => ({
            value: s.seniority,
            label: s.name
          }));
          updateVal("seniorities", seniorities);
        } else {
          updateVal(v, data[v]);
        }
      }
    });
  }, [data]);

  useEffect(() => {
    if (projectId) {
      set("admin_project_overview_note_draft__" + projectId, values);
    }
  }, [values, projectId]);

  const onSubmit = async () => {
    setSaving(true);

    let body = isEmpty ? [] : values.body;

    if (stage === "filled") {
      body = body.concat(getFilledNote(values));
    }

    if (stage === "shadow_pitched") {
      body = body.concat(getShadowPitchedNote(values));
    }

    if (stage === "matching_support") {
      body = body.concat(getMatchingSupportNote(values));
    }

    if (stage === "submitted") {
      body = body.concat(getSubmittedNote(values));
    }

    if (stage === "lost") {
      body = body.concat(getLostNote(values));
    }

    if (stage === "passive") {
      body = body.concat(getPassiveNote(values));
    }

    if (stage === "completed") {
      body = body.concat(getCompletedNote(values));
    }

    const toSend = {
      body: serialize({ children: body }),
      project: data.id,
      created_by_id: user.id,
      created_by: user.first_name + " " + user.last_name
    };

    const call = async () => {
      let updatedProjectData;
      try {
        if (stage === "filled") {
          updatedProjectData = await onFilledSave(values, context, data.id);
        }
        if (stage === "shadow_pitched") {
          updatedProjectData = await onShadowPitchedSave(
            values,
            context,
            data.id,
            data.seniorities
          );
        }
        if (stage === "matching_support") {
          updatedProjectData = await onMatchingSupportSave(
            values,
            context,
            data.id
          );
        }
        if (stage === "submitted") {
          await onSubmittedSave(values, context, data.id);
        }
        if (stage === "lost") {
          await onLostSave(values, context, data.id);
        }
        if (stage === "passive") {
          await onPassiveSave(values, context, data.id);
        }
        if (stage === "completed") {
          updatedProjectData = await onCompletedSave(values, context, data.id);
        }
        const n = await postProjectNote(toSend);
        setValues({ body: [{ type: "paragraph", children: [{ text: "" }] }] });
        addNote({
          note_body: n.body,
          created_by_name: n.created_by,
          id: n.id,
          created_timestamp: n.created_on,
          updated_at: n.updated_at,
          updated_by_id: n.created_by_id,
          project_id: n.project
        });

        if (updatedProjectData) {
          const {
            availability_hours,
            availability_hours_maximum,
            ideal_rate,
            ideal_rate_maximum,
            minimum_rate,
            maximum_rate,
            seniorities,
            estimated_end_date,
            estimated_start_date,
            top_requirements
          } = updatedProjectData;

          setData({
            ...data,
            availability_hours,
            availability_hours_maximum,
            estimated_start_date,
            estimated_end_date,
            ideal_rate,
            ideal_rate_maximum,
            minimum_rate,
            maximum_rate,
            seniorities,
            top_requirements,
            stage
          });
        } else if (stage) {
          setData({ ...data, stage });
        }
        if (stage) {
          setStage(null);
          setContext(null);
        }
        set("admin_project_overview_note_draft__" + projectId, null);
        set("admin_project_overview_note_stage__" + projectId, null);
      } catch (e) {
        console.error(e);
        alert.error(<AlertError error={e} />);
      }
      setSaving(false);
    };

    await call();
  };

  const fetchTopRequirements = async () => {
    let response = await getProjectV2(projectId);
    return response.top_requirements;
  };

  return (
    <>
      <Prompt when={!isEmpty} message={confirmMsg} />
      <RichTextEditor
        placeholder={
          stage
            ? "Add note to Stage Change " +
              (stage === "completed" ? "(Required)" : "(Optional)")
            : "Save Information to " + data.name
        }
        value={values.body}
        onChange={(v) => updateVal("body", v)}
        isStageChangeNote={isStageChangeNote}
      />
      {stage === "filled" && (
        <StageFilledForm
          isNoteForm={true}
          {...{ values, updateVal, setContext, setValues, projectId: data.id }}
        />
      )}
      {stage === "shadow_pitched" && (
        <StageShadowPitchedForm
          {...{
            values,
            updateVal,
            setContext,
            setValues,
            projectId: data.id,
            originalSeniorities: data.seniorities
          }}
        />
      )}
      {stage === "matching_support" && (
        <StageMatchingSupportForm
          {...{ values, updateVal, setContext, setValues, projectId: data.id }}
        />
      )}
      {stage === "submitted" && (
        <StageSubmittedForm
          isNoteForm={true}
          {...{ values, updateVal, setContext, setValues, projectId: data.id }}
        />
      )}
      {stage === "lost" && (
        <StageLostForm
          {...{ values, updateVal, setContext, setValues, projectId: data.id }}
        />
      )}
      {stage === "passive" && (
        <StagePassiveForm
          {...{ values, updateVal, setContext, setValues, projectId: data.id }}
        />
      )}
      {stage === "completed" && (
        <StageCompletedForm
          {...{ values, updateVal, setContext, setValues, projectId: data.id }}
        />
      )}
      <div
        className={clsx(
          "flex items-center justify-start rounded-b border-b border-l border-r px-2 pb-1",
          isStageChangeNote
            ? "stage-change-form-bottom border-link"
            : "border-geyser"
        )}
      >
        {stage && (
          <span className="flex items-center text-sm font-bold text-link">
            {$.stage_label}: {stageOptions[stage]}
          </span>
        )}
        <div className="flex flex-1 items-center justify-end">
          {(!isEmpty || stage) && (
            <button
              onClick={() => {
                if (canClose()) {
                  updateVal("body", [
                    { type: "paragraph", children: [{ text: "" }] }
                  ]);
                  setStage(null);
                  setValues({
                    body: [{ type: "paragraph", children: [{ text: "" }] }]
                  });
                  set("admin_project_overview_note_draft__" + projectId, null);
                  set("admin_project_overview_note_stage__" + projectId, null);
                }
              }}
              type="button"
              className="flex items-center text-sm font-bold text-kasmir"
            >
              {$.cancel_btn}
            </button>
          )}
          <button
            onClick={onSubmit}
            type="button"
            className={clsx(
              "ml-4 flex items-center text-sm font-bold",
              saving ||
                (isEmpty && !stage) ||
                (stage === "lost" && !values.loss_reason) ||
                (stage === "passive" && !values.passive_reason) ||
                (stage === "completed" &&
                  (!values.terminated_by ||
                    !values.client_sentiment ||
                    !values.termination_reason ||
                    isEmpty))
                ? "cursor-not-allowed text-caded"
                : "text-link"
            )}
            disabled={
              saving ||
              (isEmpty && !stage) ||
              (stage === "lost" && !values.loss_reason) ||
              (stage === "passive" && !values.passive_reason) ||
              (stage === "completed" &&
                (!values.terminated_by ||
                  !values.client_sentiment ||
                  !values.termination_reason ||
                  isEmpty))
            }
          >
            {saving ? $.saving_btn : $.submit_btn}
            <UilArrow size="20" className="ml-1" />
          </button>
        </div>
      </div>
    </>
  );
};

export default NoteForm;

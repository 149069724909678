import { useState } from "react";
import DropdownSelect from "components/DropdownMenuButton";
import Availability from "./Availability";
import Basics from "./Basics";
import Clockify from "./Clockify";
import Contact from "./Contact";
import Onboarding from "./Onboarding";
import ProfileCard from "./ProfileCard";
import { useContext } from "react";
import { TalentContext } from "../TalentDataContext";
import ExcludeTalentModal from "../../ExcludeTalentModal";
import { InviteToPipelineModal } from "./InviteToPipelineModal";
import DeleteTalentModal from "../../DeleteTalentModal";

/**
 * The scrolly pane on the left that shows the talent details.
 */
const TalentDetailPane = ({ data, user }) => {
  const { id, talentData, onUpdateTalent } = useContext(TalentContext);
  const [showInviteModal, setShowInviteModal] = useState(false);
  const [showExludeModal, setShowExcludeModal] = useState(false);
  const [showDeleteTalentModal, setShowDeleteTalentModal] = useState(false);

  const actionOptions = [
    {
      label: "Send Talent Account Invite",
      onClick: () => setShowInviteModal(true)
    },
    {
      label: "Merge Profile",
      onClick: () => {
        window.location.href = `/admin/talent/${id}/merge`;
      }
    },
    {
      label: talentData.excluded ? "Include in search" : "Exclude from search",
      onClick: () =>
        talentData.excluded
          ? onUpdateTalent({
              excluded: false,
              excluded_reason: null
            })
          : setShowExcludeModal(true)
    },
    { label: "Delete Talent", onClick: () => setShowDeleteTalentModal(true) }
  ];

  return (
    <div className="scrollbar flex h-full w-full flex-col gap-2.5 overflow-y-auto rounded-xl bg-white p-2.5 pb-8">
      {data && (
        <>
          <DropdownSelect label="Actions" compact options={actionOptions} />
          <ProfileCard />
          <Basics />
          <Contact />
          <Availability />
          <Onboarding />
          <Clockify />
        </>
      )}
      {showExludeModal && (
        <ExcludeTalentModal
          talentData={data}
          user={user}
          onClose={() => setShowExcludeModal(false)}
        />
      )}
      {showInviteModal && (
        <InviteToPipelineModal
          talentData={data}
          onClose={() => setShowInviteModal(false)}
        />
      )}
      {showDeleteTalentModal && (
        <DeleteTalentModal
          onClose={() => setShowDeleteTalentModal(false)}
          data={talentData}
        />
      )}
    </div>
  );
};

export default TalentDetailPane;

import React, { useState, useContext } from "react";
import { useHistory } from "react-router-dom";
import { isBodyEmpty } from "components/RichTextEditorNew";
import { getAdminUser } from "utils/localStorageService";
import AlertError from "components/AlertError";
import { useAlert } from "react-alert";
import { serialize } from "utils/htmlSerializer";
import { postTalentNote } from "utils/adminApi";
import useConfirmClose from "hooks/useConfirmClose";
import { TalentContext } from "../TalentDataContext";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import NoteModal from "./NoteModal";

const AddNoteModal = ({ onClose }) => {
  const history = useHistory();
  const queryClient = useQueryClient();
  const { talentData, id } = useContext(TalentContext);
  const user = getAdminUser();
  const alert = useAlert();
  const [saving, setSaving] = useState();
  const [values, setValues] = useState({
    body: [{ type: "paragraph", children: [{ text: "" }] }]
  });

  const addNoteMutation = useMutation({
    mutationFn: (newNote) => {
      return postTalentNote(newNote);
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: ["talentNotes", id] });
    },
    onError: (err, vars) => {
      alert.error(
        <AlertError
          error={err}
          retry={() => addNoteMutation.mutateAsync(vars)}
        />
      );
    }
  });

  const updateVal = (k, v) => {
    setValues((vs) => ({ ...vs, [k]: v }));
  };

  const isEmpty = isBodyEmpty(values.body);
  const { canClose } = useConfirmClose(!isEmpty);

  const onSubmit = async () => {
    setSaving(true);

    const toSend = {
      body: serialize({ children: isEmpty ? [] : values.body }),
      talent: id,
      created_by_id: user.id,
      created_by: user.first_name + " " + user.last_name
    };

    await addNoteMutation.mutateAsync(toSend, {
      onSuccess: (data) => {
        setValues({ body: [{ type: "paragraph", children: [{ text: "" }] }] });

        // Only navigate if not already on notes or skills page
        const currentPath = history.location.pathname;
        if (
          !currentPath.includes("/notes") &&
          !currentPath.includes("/skills")
        ) {
          history.push(`/admin/talent/${id}/notes`);
        }

        // The new note will be at the top
        const notesContainer = document.getElementById("talent-detail-tab");
        if (notesContainer) {
          notesContainer.scrollTo({
            top: 0,
            behavior: "smooth"
          });
        }

        setSaving(false);
        onClose();
      }
    });
  };

  const onDiscard = () => {
    if (canClose()) {
      updateVal("body", [{ type: "paragraph", children: [{ text: "" }] }]);
      setValues({
        body: [{ type: "paragraph", children: [{ text: "" }] }]
      });
    }

    onClose();
  };

  return (
    <NoteModal
      title={`Add Note for ${talentData.fullname}`}
      id="add-note-modal"
      saveBtnText="Post Note"
      saving={saving}
      values={values}
      setValues={setValues}
      onSave={onSubmit}
      onCancel={onDiscard}
    />
  );
};

export default AddNoteModal;

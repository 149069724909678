import Section from "components/Section";
import React from "react";
import clsx from "clsx";

export const TalentPageSection = ({
  children,
  title,
  id,
  topRightContent,
  fullHeight = true
}) => {
  return (
    <Section
      id={id}
      className={clsx(
        "flex flex-col gap-4 rounded-md bg-lightest-grey",
        fullHeight && "min-h-full"
      )}
    >
      <div className="flex flex-row justify-between px-4 pt-4">
        <div className="text-lg font-bold text-midnight">{title}</div>
        {topRightContent && topRightContent}
      </div>
      {children}
    </Section>
  );
};

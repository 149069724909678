import React, { useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { admin_talent_excluded_form as $ } from "strings";
import { Textarea } from "components/Form";
import Select from "components/Select";
import { patchTalentOnboardingV2, postTalentNote } from "utils/adminApi";
import { useAlert } from "react-alert";
import AlertError from "components/AlertError";
import AlertSuccess from "../../../components/AlertSuccess";
import { getAdminUser } from "utils/localStorageService";
import { Modal } from "pages/talent/components";
import { FormElement } from "pages/talent/Settings/components";

const excludedReasonOptions = [
  {
    label: "Skills or Experience Misalignment",
    value: "skills_experience_misalignment"
  },
  { value: "current_client_fte", label: "Current Client FTE" },
  { value: "too_expensive", label: "Too Expensive" },
  { value: "negative_client_feedback", label: "Negative Client Feedback" },
  { value: "rsu_values_misalignment", label: "RSU Values Misalignment" },
  { value: "no_longer_interested", label: "No Longer Interested" },
  { value: "other", label: "Other" }
];

const ExcludeTalentModal = ({ onClose, onSuccess, talentData, user }) => {
  const alert = useAlert();
  const adminUser = getAdminUser();
  const [disableOther, setDisableOther] = useState();

  const { handleSubmit, errors, formState, control, watch } = useForm({
    defaultValues: { excluded_reason: "", excluded_details: "" },
    mode: "onBlur",
    reValidateMode: "onBlur"
  });

  const watchReason = watch("excluded_reason");
  const watchDetails = watch("excluded_details");

  useEffect(() => {
    watchReason.value === "other" && watchDetails === ""
      ? setDisableOther(true)
      : setDisableOther(false);
  }, [watchReason, watchDetails]);

  const onSubmit = async (values) => {
    const toSend = {
      excluded: true
    };
    if (!values.excluded_reason) return;
    toSend.excluded_reason = values.excluded_reason.value;

    let note = `Excluded Reason: ${values.excluded_reason.label}`;
    if (values.excluded_details) {
      note += `; ${values.excluded_details}`;
    }

    try {
      const response = await patchTalentOnboardingV2(talentData.id, toSend);
      await postTalentNote({
        body: note,
        talent: user,
        note_tag: "excluded",
        created_by_id: adminUser.id,
        created_by: adminUser.first_name + " " + adminUser.last_name
      });
      let msg = "Talent excluded successfully.";
      alert.success(<AlertSuccess message={msg} />, { timeout: 2000 });
      onSuccess(response);
    } catch (e) {
      console.error(e);
      alert.error(<AlertError error={e} onRetry={() => onSubmit(values)} />);
    }
  };

  return (
    <Modal
      isDirty={formState.isDirty}
      title={$.excluded_form_title}
      onClose={onClose}
      onClickSave={handleSubmit(onSubmit)}
      buttonsDisabled={
        formState.isSubmitting || !formState.isDirty || disableOther
      }
      id="admin_talent_excluded_form"
    >
      <form className="flex flex-col">
        <div className="pb-4">
          Write the reason why you want to exclude the user from the search.
        </div>
        <FormElement
          label={$.excluded_reason_label}
          errors={errors.excluded_reason}
        >
          <Controller
            control={control}
            rules={{ required: $.validation_required }}
            name="excluded_reason"
            as={<Select />}
            error={errors.excluded_reason}
            placeholder={$.select_option}
            options={excludedReasonOptions}
          />
        </FormElement>
        <FormElement
          label={$.excluded_details_label}
          errors={errors.excluded_details}
        >
          <Controller
            control={control}
            name="excluded_details"
            as={<Textarea />}
            error={errors.excluded_details}
            placeholder={$.excluded_details_placeholder}
          />
        </FormElement>
      </form>
    </Modal>
  );
};

export default ExcludeTalentModal;

import React from "react";
import { Modal } from "pages/talent/components";

const DeleteAttachmentModal = ({ onClose, onDelete }) => {
  return (
    <Modal
      isDirty={false}
      title="Delete Attachment"
      saveBtnText="Delete"
      onClickSave={() => {
        onDelete();
      }}
      onClickCancel={onClose}
    >
      <div>
        Are you sure you want to delete this attachment? This action cannot be
        undone and the file will be permanently removed.
      </div>
    </Modal>
  );
};

export default DeleteAttachmentModal;
/**
 * Content for a tooltip that shows values justify-between-ed
 */
export const FieldMappingToolTip = ({ values }) => {
  return (
    <div className="flex w-60 flex-col gap-2 bg-white font-medium">
      {Object.entries(values).map(([key, value], i) => (
        <div className="flex flex-row justify-between text-sm" key={i}>
          <div className="text-left text-kasmir">{key}: </div>
          <div className="text-right text-midnight">{value}</div>
        </div>
      ))}
    </div>
  );
};

import React, { useEffect, useCallback, useState, useRef } from "react";
import clsx from "clsx";
import $ from "strings/talent";
import UilTimes from "@iconscout/react-unicons/icons/uil-times";
import Section from "components/Section";
import useConfirmClose from "hooks/useConfirmClose";
import { TransparentButton } from "components/Buttons";
import { Button } from "./index";
import cornerHandle from "assets/grabby-corner.svg";

/**
 * Renders a modal close button.
 * @param {callback} onClick - Callback to trigger on click.
 */
const CloseBtn = ({ onClick }) => (
  <button onClick={onClick} className="group flex h-full focus:outline-none">
    <span
      className="inline-flex h-8 w-8 items-center justify-center rounded text-lg text-cove hover:bg-link-water focus:outline-none active:bg-geyser group-focus:ring"
      tabIndex="-1"
    >
      <UilTimes size="24" />
    </span>
  </button>
);

/**
 * Common modal content component used in both the standard modal and movable modal.
 * This should not be invoked directly, it is used in <Modal /> below.
 */
const ModalContent = ({
  title,
  titleChildren,
  onClose,
  canClose,
  children,
  fullScreenOnMobile,
  showActionBar,
  movable,
  handleMouseDown,
  showDelete,
  buttonsDisabled,
  onClickDelete,
  deleteBtnText,
  onClickCancel,
  cancelBtnText,
  onClickSave,
  saveBtnText
}) => (
  <div className="p-5">
    <div
      className={clsx(
        "modal-header flex items-center border-b-2 pb-3.5",
        movable && "cursor-grab"
      )}
      onMouseDown={handleMouseDown}
    >
      <div className="text-xl font-bold text-midnight">{title}</div>
      <div className="ml-auto mr-0 flex items-center justify-end gap-x-4">
        {!!titleChildren && <div>{titleChildren}</div>}
        <CloseBtn
          onClick={() => {
            if (canClose()) {
              onClose();
            }
          }}
        />
      </div>
    </div>
    <div
      className={clsx(
        "overflow-y-auto py-3.5",
        "text-sm font-medium text-kasmir",
        fullScreenOnMobile && "flex-grow pb-56 sm:pb-3.5"
      )}
    >
      {children}
    </div>
    {showActionBar && (
      <div
        className={clsx(
          fullScreenOnMobile
            ? "fixed bottom-0 left-0 right-0 bg-white px-3.5 pb-12 pt-2 sm:static sm:px-0 sm:pb-0"
            : "flex-col-reverse",
          "flex w-full items-center gap-2.5 sm:flex-row"
        )}
      >
        {showDelete && (
          <TransparentButton
            disabled={buttonsDisabled}
            className="text-sm text-electric-indigo underline"
            onClick={onClickDelete}
          >
            <span>{deleteBtnText || "Delete"}</span>
          </TransparentButton>
        )}
        <div className={clsx(fullScreenOnMobile ? "ml-auto" : "sm:ml-auto")}>
          <Button
            disabled={buttonsDisabled}
            onClick={onClickCancel || onClose}
            cancel
          >
            <span>{cancelBtnText || $.cancel_btn}</span>
          </Button>
        </div>
        <Button disabled={buttonsDisabled} onClick={onClickSave}>
          <span>{saveBtnText || $.save_btn}</span>
        </Button>
      </div>
    )}
  </div>
);

/**
 * Renders a modal. It includes some features like confirmation prompt on close and a close button. It is used in all forms.
 * @param {component} children - Children to render inside, like a form with all its inputs.
 * @param {callback} onClose - Callback to trigger on close button click.
 * @param {string} id - Identifier to use with the Section component.
 * @param {boolean} isDirty - Flag to enable the confirmation feature.
 * @param {boolean} fullScreenOnMobile - Flag to indicate if the modal should take full screen on mobile.
 */
const Modal = ({
  children,
  onClose,
  id,
  isDirty,
  title,
  titleChildren,
  onClickSave,
  onClickCancel,
  buttonsDisabled,
  saveBtnText,
  showDelete,
  cancelBtnText,
  deleteBtnText,
  onClickDelete,
  fullScreenOnMobile = false,
  showActionBar = true,
  className = "",
  movable = false
}) => {
  const { canClose } = useConfirmClose(isDirty);
  const [isSmallScreen, setIsSmallScreen] = useState(false);

  // Check if small screen for mobile behavior
  useEffect(() => {
    const checkScreenSize = () => {
      // Use the same breakpoint that Tailwind uses for 'sm:'
      setIsSmallScreen(window.innerWidth < 640);
    };

    // Initial check
    checkScreenSize();

    // Listen for window resize
    window.addEventListener("resize", checkScreenSize);

    // Cleanup
    return () => window.removeEventListener("resize", checkScreenSize);
  }, []);

  const onEscape = useCallback(
    (event) => {
      if (event.keyCode === 27 && canClose()) {
        onClose();
      }
    },
    [canClose, onClose]
  );

  useEffect(() => {
    // Prevent scrolling
    document.body.style.overflow = "hidden";
    document.addEventListener("keydown", onEscape);

    return () => {
      document.removeEventListener("keydown", onEscape);
      document.body.style.overflow = "auto";
    };
  }, [onEscape]);

  // Force non-movable on small screens when fullScreenOnMobile is true
  const shouldBeMovable = movable && !(isSmallScreen && fullScreenOnMobile);

  // Common props for both modal types
  const contentProps = {
    title,
    titleChildren,
    onClose,
    canClose,
    children,
    fullScreenOnMobile,
    showActionBar,
    showDelete,
    buttonsDisabled,
    onClickDelete,
    deleteBtnText,
    onClickCancel,
    cancelBtnText,
    onClickSave,
    saveBtnText
  };

  if (shouldBeMovable) {
    return (
      <MovableWrapper>
        {(handleProps) => (
          <div
            className={clsx(
              "h-full rounded-xl",
              className,
              "relative mb-0 mt-0 flex max-h-full w-full flex-col overflow-hidden bg-white shadow-xl",
              "text-sm text-kasmir"
            )}
            style={{ height: "100%" }}
          >
            <ModalContent {...contentProps} movable={true} {...handleProps} />
          </div>
        )}
      </MovableWrapper>
    );
  }

  return (
    <div
      className={clsx(
        fullScreenOnMobile
          ? "h-dvh sm:h-screen sm:px-10 sm:py-12"
          : "h-screen px-10 py-12",
        "fixed left-0 top-0 z-50 flex w-screen items-center justify-center text-midnight"
      )}
    >
      <div
        onClick={() => {
          if (canClose()) {
            onClose();
          }
        }}
        className="absolute left-0 top-0 z-40 h-full w-full"
        style={{ backgroundColor: "rgba(31, 34, 39, 0.5)" }}
      />
      <Section
        id={id}
        className="z-50 mx-auto flex max-h-full w-full lg:w-3/5 xl:w-3/5 2xl:w-1/2"
      >
        <div
          className={clsx(
            fullScreenOnMobile
              ? "h-screen sm:h-auto sm:rounded-xl"
              : "h-auto rounded-xl",
            className,
            "mb-0 mt-0 flex max-h-full w-full flex-col overflow-hidden bg-white"
          )}
        >
          <ModalContent
            {...contentProps}
            movable={false}
            handleMouseDown={() => {}}
          />
        </div>
      </Section>
    </div>
  );
};

/**
 * Allows resizing, moving around, and interacting with the page behind it.
 * This wrapper should not be invoked directly, it is used in <Modal movable={true} />
 */
const MovableWrapper = ({ children }) => {
  const modalContentRef = useRef(null);
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const [size, setSize] = useState({ width: 675, height: 415 });
  const [isDragging, setIsDragging] = useState(false);
  const [isResizing, setIsResizing] = useState(false);
  const [initialPos, setInitialPos] = useState({ x: 0, y: 0 });
  const [initialSize, setInitialSize] = useState({ width: 0, height: 0 });
  const [initialMousePos, setInitialMousePos] = useState({ x: 0, y: 0 });
  const [hasInitialized, setHasInitialized] = useState(false);

  // Center the modal on initial render with our predefined dimensions
  useEffect(() => {
    const centerModal = () => {
      if (!hasInitialized) {
        // Calculate the center position based on our predefined size
        const viewportWidth = window.innerWidth;
        const viewportHeight = window.innerHeight;

        // Calculate the center position
        const centerX = Math.max(0, (viewportWidth - size.width) / 2);
        const centerY = Math.max(0, (viewportHeight - size.height) / 2);

        setPosition({ x: centerX, y: centerY });
        setHasInitialized(true);
      }
    };

    centerModal();

    // Re-center on window resize if not yet interacted with
    window.addEventListener("resize", centerModal);
    return () => window.removeEventListener("resize", centerModal);
  }, [hasInitialized, size.width, size.height]);

  const handleMouseDown = (e) => {
    // Only enable dragging when clicking on the header
    if (e.target.closest(".modal-header")) {
      e.preventDefault();

      // Store the initial position of the modal and mouse
      setInitialPos({
        x: position.x,
        y: position.y
      });

      setInitialMousePos({
        x: e.clientX,
        y: e.clientY
      });

      setIsDragging(true);

      // Change cursor during drag
      document.body.style.cursor = "grabbing";
    }
  };

  const handleResizeMouseDown = (e) => {
    e.preventDefault();
    e.stopPropagation();

    // Store the initial size and position of the modal and mouse
    setInitialSize({
      width: size.width,
      height: size.height
    });

    setInitialMousePos({
      x: e.clientX,
      y: e.clientY
    });

    setIsResizing(true);

    // Change cursor during resize
    document.body.style.cursor = "se-resize";
  };

  const handleMouseMove = useCallback(
    (e) => {
      // Check if cursor is within viewport
      const isWithinViewport =
        e.clientX >= 0 &&
        e.clientX <= window.innerWidth &&
        e.clientY >= 0 &&
        e.clientY <= window.innerHeight;

      // If cursor has moved outside the viewport, stop dragging/resizing
      if (!isWithinViewport) {
        setIsDragging(false);
        setIsResizing(false);
        document.body.style.cursor = "default";
        return;
      }

      if (isDragging) {
        // Calculate new position based on mouse movement
        const dx = e.clientX - initialMousePos.x;
        const dy = e.clientY - initialMousePos.y;

        setPosition({
          x: initialPos.x + dx,
          y: initialPos.y + dy
        });
      } else if (isResizing) {
        // Calculate new size based on mouse movement
        const dx = e.clientX - initialMousePos.x;
        const dy = e.clientY - initialMousePos.y;

        // Minimum size constraints
        const minWidth = 300;
        const minHeight = 200;

        setSize({
          width: Math.max(minWidth, initialSize.width + dx),
          height: Math.max(minHeight, initialSize.height + dy)
        });
      }
    },
    [isDragging, isResizing, initialMousePos, initialPos, initialSize]
  );

  const handleMouseUp = useCallback(() => {
    setIsDragging(false);
    setIsResizing(false);
    document.body.style.cursor = "default";
  }, []);

  // Add/remove event listeners for dragging and resizing
  useEffect(() => {
    document.addEventListener("mousemove", handleMouseMove);
    document.addEventListener("mouseup", handleMouseUp);

    return () => {
      document.removeEventListener("mousemove", handleMouseMove);
      document.removeEventListener("mouseup", handleMouseUp);
    };
  }, [handleMouseMove, handleMouseUp]);

  return (
    <div
      className="pointer-events-none fixed left-0 top-0 h-screen w-screen"
      style={{ zIndex: 50 }}
    >
      <div
        ref={modalContentRef}
        style={{
          position: "fixed",
          left: `${position.x}px`,
          top: `${position.y}px`,
          width: `${size.width}px`,
          height: `${size.height}px`,
          pointerEvents: "auto",
          zIndex: 50
        }}
        className="lg:w-auto xl:w-auto 2xl:w-auto"
      >
        {children({
          handleMouseDown
        })}
        {
          <div
            className="resize-handle absolute bottom-0 right-0 h-5 w-5 cursor-se-resize"
            onMouseDown={handleResizeMouseDown}
          >
            <img
              src={cornerHandle}
              className="absolute bottom-1 right-1 h-4 w-4"
              alt="cornerHandle"
            />
          </div>
        }
      </div>
    </div>
  );
};

export default Modal;


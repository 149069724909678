import React from "react";
import { Modal } from "pages/talent/components";
import { FormElement } from "pages/talent/Settings/components";
import { Input } from "components/Form";

export const InviteToPipelineModal = ({ talentData, onClose }) => {
  const onInviteToPipeline = () => {};
  return (
    <Modal
      title={`Invite ${talentData.fullname} to create a profile on Pipeline?`}
      saveBtnText="Send Invite"
      onClickSave={onInviteToPipeline}
      onClickCancel={onClose}
      onClose={onClose}
    >
      <div className="flex w-full flex-col">
        <div className="pb-4">
          Please confirm this information before sending an invitation. Update
          the talent's profile to modify any information shown below.
        </div>
        <div className="flex w-full flex-row gap-2.5">
          <FormElement label="First Name">
            <Input
              name="firstName"
              value={talentData.first_name}
              disabled
              className="w-full"
            />
          </FormElement>
          <FormElement label="Last Name">
            <Input
              name="lastName"
              value={talentData.last_name}
              disabled
              className="w-full"
            />
          </FormElement>
        </div>
        <FormElement label="Email Address">
          <Input name="email" value={talentData.email_address} disabled />
        </FormElement>
      </div>
    </Modal>
  );
};

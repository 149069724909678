import React, { useEffect, useState, useMemo } from "react";
import { useParams, Link } from "react-router-dom";
import clsx from "clsx";
import * as yup from "yup";
import { Form, Formik } from "formik";
import { Textarea } from "components/Form";
import $ from "strings/talent";
import {
  Button,
  FormErrorMessage,
  Qualification
} from "../talent/components/index";
import UilArrowRight from "@iconscout/react-unicons/icons/uil-arrow-right";
import {
  getCandidateEngagementsPreview,
  postCandidateReview
} from "utils/api";
import { Loader } from "./onboard/screens";
import Logo from "components/Logo";
import { useAlert } from "react-alert";
import AlertError from "components/AlertError";
import GenericConfirmationImage from "./assets/confirmation_generic.png";
import { ThumbsDownIcon, ThumbsUpIcon } from "components/icons";


const TALENT_REVIEW_QUALIFICATIONS = [
  {id: 'professionalism_rating', name: "Professionalism", tooltip: $.professionalism_tooltip},
  {id: 'dependability_rating', name: "Dependability", tooltip: $.dependability_tooltip},
  {id: 'quality_of_work_rating', name: "Quality of Work", tooltip: $.quality_of_work_tooltip},
  {id: 'domain_expertise_rating', name: "Domain Expertise", tooltip: $.domain_expertise_tooltip},
]


const TalentReview = () => {
  const [initialValues, setInitialValues] = useState({});
  const [talentData, setTalentData] = useState();
  const [formSubmitting, setFormSubmitting] = useState(false);
  const [showSuccessScreen, setShowSuccessScreen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const { id: candidateId } = useParams();
  const alert = useAlert();
  
  const projectContactId = useMemo(() => {
    return new URLSearchParams(window.location.search).get("referrer") || undefined;
  }, []);

  const loadCandidateData = async (candidateId, projectContactId) => {
    await getCandidateEngagementsPreview(candidateId, projectContactId)
      .then((response) => {
        if (response.status === 204) {
          setShowSuccessScreen(true);
          setIsLoading(false);
        } else {
          setTalentData(response.data);
          setIsLoading(false);
        }
      })
      .catch(() => {
        // TODO: error state?
      });
  }

  useEffect(() => {
    if (!candidateId || !projectContactId) {
      return;
    }
    loadCandidateData(candidateId, projectContactId);
    setInitialValues({
      engagement_poc: projectContactId,
      candidate: candidateId,
      is_recommended: null,
      public_feedback: '',
      private_feedback: '',
      professionalism_rating: null,
      dependability_rating: null,
      quality_of_work_rating: null,
      domain_expertise_rating: null
    })
  }, [candidateId, projectContactId]);

  const onClickSubmit = async (values) => {
    try {
      const response = await postCandidateReview(values);
      setShowSuccessScreen(true);
    } catch (e) {
      console.error({ e, values });
      alert.error(<AlertError error="Something went wrong. Please try again, or contact us if the issue continues." />);
    }
  }

  const validationSchema = yup.object().shape({
    is_recommended: yup
      .boolean()
      .required("Required field"),
    professionalism_rating: yup
      .number()
      .min(1)
      .required("Required field"),
    dependability_rating: yup
      .number()
      .min(1)
      .required("Required field"),
    quality_of_work_rating: yup
      .number()
      .min(1)
      .required("Required field"),
    domain_expertise_rating: yup
      .number()
      .min(1)
      .required("Required field"),
  });

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={() => {}}
      validationSchema={validationSchema}
      validateOnChange={false}
      validateOnBlur={false}
      enableReinitialize={true}
    >
      {({
        setFieldValue,
        values,
        touched,
        setTouched,
        setFieldTouched,
        errors,
        setFieldError,
        validateForm
      }) => {
        const onChange = async (name, value) => {
          await setFieldValue(name, value);
          await setFieldTouched(name, value !== initialValues[name]);
          if (errors[name]) {
            setFieldError(name, null);
          }
        };
        return (
          <div className="font-lato flex justify-center items-center text-center w-full sm:h-full sm:max-h-full overflow-scroll sm:overflow-hidden bg-alice-blue p-4 sm:py-16 sm:px-28">
            {isLoading ?
              <Loader /> : !showSuccessScreen ?
              <div className="w-full h-full bg-white rounded-extra-large p-4 sm:py-8 sm:px-12 text-midnight gap-y-4 overflow-hidden sm:overflow-y-auto flex flex-col items-center">
                <div className="absolute left-8 sm:left-36">
                  <Logo id="Logo" className="h-8 w-8 sm:h-12 sm:w-12" />
                </div>
                <div className="flex flex-col gap-2 p-2 text-center items-center justify-center">
                  {talentData.photo && 
                    <div>
                      <img src={talentData.photo} className="rounded-full w-16 sm:w-20 h-16 sm:h-20" alt={`${talentData.first_name} ${talentData.last_name}`} />
                    </div>
                  }
                  <div className="text-xl sm:text-2.5xl font-bold">{$.engagement_review_title} {talentData.first_name} {talentData.last_name}</div>
                  <div className="text-base sm:text-lg pb-2">{$.engagement_review_subtitle}</div>
                </div>
                <Form className="form">
                
                <div className="bg-lightest-grey mb-4 rounded-extra-large p-4 gap-y-2 flex flex-col items-center">
                  <div>
                    <div className="text-lg sm:text-1.5xl font-bold">{$.private_feedback_title}</div>
                    <div className="pt-1 pb-2 text-sm sm:text-base text-kasmir">{$.private_feedback_msg}</div>
                  </div>
                  <div className="flex flex-col items-center">
                    <div className="text-base sm:text-lg font-bold">{$.private_feedback_question.replace('#', talentData.first_name)}</div>
                    <div className="flex gap-4 pt-4">
                      <div className="flex flex-col gap-2">
                        <Button
                          secondary
                          noMinW={true}
                          large
                          className={clsx("w-14", values.is_recommended !== true && "border-none")}
                          onClick={() => onChange('is_recommended', true)}
                        >
                          <ThumbsUpIcon isSelected={values.is_recommended === true} />
                        </Button>
                        <div className="text-sm sm:text-base mt-1">Yes</div>
                      </div>
                      <div className="flex flex-col gap-2">
                        <Button
                          secondary
                          noMinW={true}
                          large
                          className={clsx("w-14", values.is_recommended !== false && "border-none")}
                          onClick={() => onChange('is_recommended', false)}
                        >
                          <ThumbsDownIcon isSelected={values.is_recommended === false} />
                        </Button>
                        <div className="text-sm sm:text-base mt-1">No</div>
                      </div>
                    </div>
                    <FormErrorMessage error={errors.is_recommended} />
                  </div>
                  <div className="w-full px-2">
                    <div className="text-base sm:text-lg font-bold">{$.private_feedback_other}</div>
                      <Textarea
                        type="textarea"
                        value={values.private_feedback}
                        error={errors.private_feedback}
                        name="private_feedback"
                        className="resize-none text-sm mt-4"
                        unbolded
                        placeholder={$.private_feedback_textarea_prompt}
                        onChange={(e) => onChange(e.target.name, e.target.value)}
                      />
                  </div>
                </div>

                <div className="bg-lightest-grey mb-4 rounded-extra-large p-4 gap-y-2 flex flex-col items-center">
                  <div className="flex flex-col">
                    <div className="text-lg sm:text-1.5xl font-bold">{$.public_review_title}</div>
                    <div className="pt-1 pb-2 text-sm sm:text-base text-kasmir">{$.public_review_msg}</div>
                  </div>
                  <div className="w-full">
                    <div className="flex flex-col py-2">
                      <div className="text-base sm:text-lg font-bold">{$.public_review_question_1.replace('#', talentData.first_name)}</div>
                      <div className="pt-1 pb-2 text-sm sm:text-base text-kasmir">{$.public_review_question_1_sub}</div>
                    </div>
                    <div className="bg-white w-full rounded-xl w-full flex flex-col gap-2 p-4 mb-2 mt-4 text-left">
                      <div className="grid grid-cols-5 text-mini text-kasmir">
                        <div className="col-span-2">Skill name</div>
                        <div className="col-span-3 mr-2 flex gap-8 ml-auto mr-0">
                          <span>Bad</span>
                          <span>Good</span>
                          <span>Excellent</span>
                        </div>
                      </div>
                      {TALENT_REVIEW_QUALIFICATIONS.map(q => 
                        <Qualification
                          key={q.id}
                          qualification={q}
                          rating={values[q.id] || null}
                          onRatingChange={onChange}
                          showCheckbox={false} 
                          showTooltip={window.innerWidth >= 650 ? true : false}
                          tooltipText={q.tooltip}
                        />
                      )}
                    </div>
                    <FormErrorMessage error={(
                      errors.professionalism_rating ||
                      errors.quality_of_work_rating ||
                      errors.dependability_rating ||
                      errors.domain_expertise_rating
                    ) && 'Provide a rating on each skill.'} />
                  </div>
                  <div className="w-full py-2">
                    <div className="text-base sm:text-lg font-bold">{$.public_review_question_2}</div>
                    <div className="pt-1 pb-2 text-sm sm:text-base text-kasmir">{$.public_review_question_2_sub}</div>
                      <Textarea
                        type="textarea"
                        value={values.public_feedback}
                        error={errors.public_feedback}
                        name="public_feedback"
                        className="resize-none text-sm mt-4"
                        unbolded
                        placeholder={$.public_review_textarea_prompt}
                        onChange={(e) => onChange(e.target.name, e.target.value)}
                      />
                  </div>
                </div>
                <div className="flex items-center justify-center py-2">
                  <Button
                    large={window.innerWidth > 650 ? true : false}
                    disabled={formSubmitting}
                    className="animatedBtn flex items-center"
                    onClick={async () => {
                      setFormSubmitting(true);
                      const errors = await validateForm();
                      if (!Object.keys(errors).length) {
                        await onClickSubmit(values);
                      }
                      setFormSubmitting(false);
                    }}
                  >
                    <span>{$.engagement_review_btn}</span>
                    <UilArrowRight size="16"/>
                  </Button>
                </div>
               </Form> 
              </div> :
              <div className="confirmation bg-alice-blue font-lato px-8 py-4 w-full h-full flex flex-col justify-center items-center text-center">
                <div className="w-full sm:w-1/2 items-center justify-center py-2">
                  <img className="m-auto" alt="RSU icon" src={GenericConfirmationImage} />
                </div>
                <div className="w-full sm:w-1/2 gap-2 items-center justify-center">
                  <div className="text-darkest-navy text-2xl sm:text-3xl font-bold py-2">
                    {$.review_submitted_msg}
                  </div>
                  <div className="text-midnight text-base sm:text-lg font-normal py-2">
                    {$.review_submitted_sub}
                    <a
                      href="mailto:support@rightsideup.co"
                      className="text-electric-indigo underline hover:text-electric-indigo-lighter"
                    >{$.support_email}</a>
                  </div>
                  <div className="my-2 flex">
                      <Link
                        to={{ pathname: $.growth_marketing_services_url }}
                        target="_blank"
                        className="mx-auto"
                      >
                        <Button className="animatedBtn bg-electric-indigo text-sm sm:text-base rounded gap-1">
                          {$.growth_marketing_services_btn}
                        </Button>
                      </Link>
                  </div>
                </div>
              </div>
            }
        </div>
      )
    }}
    </Formik>
  );
};

export default TalentReview;

import React, { useContext, useState } from "react";
import { Button } from "pages/talent/components";
import { TalentPageSection } from "../components/components";
import { NOTE_TYPE_FILTERS, NotesView } from "../Notes";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { TalentContext } from "../TalentDataContext";
import Qualifications from "./Qualifications";
import useQualifications from "hooks/useQualifications";
import { useQuery } from "@tanstack/react-query";
import { getTalentQualificationsV2 } from "utils/adminApi";
import { Checkbox } from "components/Form";

const Skills = ({ setModal }) => {
  const history = useHistory();
  const { id } = useContext(TalentContext);
  const { qualifications } = useQualifications(true);
  const [showSelfRatings, setShowSelfRatings] = useState(true);

  const { data: talentQualifications, isLoading } = useQuery({
    queryFn: () => getTalentQualificationsV2(id, { page_size: 9999 }),
    queryKey: ["talent_qualifications", id],
    enabled: !!id,
    select: (d) => d?.results
  });

  return (
    <div className="flex flex-col gap-3 bg-white">
      <TalentPageSection
        title="Qualifications"
        id="admin_talent_qualifications"
        topRightContent={
          <div className="items-center rounded-md bg-white px-2.5 py-2">
            <Checkbox
              onChange={() => setShowSelfRatings(!showSelfRatings)}
              value={showSelfRatings}
            >
              Show Self Ratings
            </Checkbox>
          </div>
        }
      >
        {isLoading ? (
          <div className="h-full w-full rounded-md bg-white" />
        ) : (
          <Qualifications
            user={id}
            talentQualifications={talentQualifications}
            showSelfRatings={showSelfRatings}
            qualificationsOptions={qualifications.qualificationsArray}
          />
        )}
      </TalentPageSection>
      <TalentPageSection
        title="Screening Notes"
        id="admin_screening_talent_notes"
        topRightContent={
          <div className="flex flex-row gap-2.5">
            <Button
              noMinW
              cancel
              onClick={() => history.push(`/admin/talent/${id}/notes`)}
            >
              View All Notes
            </Button>
            <Button noMinW onClick={() => setModal({ type: "add_note" })}>
              Add Note
            </Button>
          </div>
        }
      >
        <NotesView
          setModal={setModal}
          availableFilters={NOTE_TYPE_FILTERS.filter(
            (f) => f.value !== "other"
          )}
          customFilter={(n) => {
            // Get the relevant filters (recruiters, tech screen, and probing questions)
            const relevantFilters = NOTE_TYPE_FILTERS.filter((f) =>
              ["recruiter", "tech_screen", "probing_questions"].includes(
                f.value
              )
            );

            // Convert note body to lowercase for filtering
            const bodyLower = n.note_body.toLowerCase();

            // Check if the note matches any of the relevant filters
            return relevantFilters.some((filter) =>
              filter.filter(n.type, bodyLower)
            );
          }}
        />
      </TalentPageSection>
    </div>
  );
};

export default Skills;

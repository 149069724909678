import { useContext, useState, useMemo, useEffect } from "react";
import { TalentPhotoWithUtilization } from "components_v2";
import { TalentContext } from "../TalentDataContext";
import { Field } from "./components";
import EditableDoubleField from "components/EditableDoubleField";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
  getTalentProjects,
  patchTalentProject,
  postTalentProject
} from "utils/adminApi";
import { toFormat } from "utils/date";
import AlertError from "components/AlertError";
import AddressInput from "components/AddressInput";
import { useAlert } from "react-alert";

const CurrentRoleEdit = ({ title, id }) => {
  const queryClient = useQueryClient();
  const alert = useAlert();
  const [currentRole, setCurrentRole] = useState({
    currentTitle: title ? title.split(" at ")[0] : "",
    currentCompany: title ? title.split(" at ")[1] : ""
  });

  useEffect(() => {
    setCurrentRole({
      currentTitle: title ? title.split(" at ")[0] : "",
      currentCompany: title ? title.split(" at ")[1] : ""
    });
  }, [title]);

  const { data: projects } = useQuery({
    queryKey: ["talentProjects", id],
    queryFn: () => getTalentProjects(id, { page_size: 9999 })
  });

  const updateProjectMutation = useMutation({
    mutationFn: ({ project_id, name, company }) =>
      patchTalentProject(project_id, { name, company }),
    onError: (error, variables) => {
      alert.error(
        <AlertError
          error={error}
          onRetry={() => updateProjectMutation.mutateAsync(variables)}
        />
      );
    },
    onSuccess: () =>
      queryClient.invalidateQueries({ queryKey: ["talentProjects", id] })
  });

  const createProjectMutation = useMutation({
    mutationFn: ({ name, company, id }) =>
      postTalentProject({
        name,
        company,
        user: id,
        active: true,
        start_date: toFormat(new Date(), "yyyy-MM-dd"),
        description: name
      }),
    onError: (error, variables) => {
      alert.error(
        <AlertError
          error={error}
          onRetry={() => createProjectMutation.mutateAsync(variables)}
        />
      );
    },
    onSuccess: () =>
      queryClient.invalidateQueries({ queryKey: ["talentProjects", id] })
  });

  const activeProject = useMemo(() => {
    if (!projects) return;
    if (projects.results && Array.isArray(projects.results)) {
      return projects.results.find((p) => p.active === true);
    }
  }, [projects]);

  const updateRole = async (name, company) => {
    await updateProjectMutation.mutateAsync({
      project_id: activeProject?.id,
      name,
      company
    });
  };

  const createRole = async (name, company) => {
    if (!name || !company) return;
    await createProjectMutation.mutateAsync({
      id,
      name,
      company
    });
  };

  const onSaveRole = (name, company) => {
    setCurrentRole({ currentTitle: name, currentCompany: company });
    activeProject ? updateRole(name, company) : createRole(name, company);
  };

  return (
    <EditableDoubleField
      className="w-full"
      first={currentRole?.currentTitle}
      last={currentRole?.currentCompany}
      firstPlaceholder="Role Unknown"
      lastPlaceholder="Company"
      firstRequired={true}
      lastRequired={false}
      divider={"at"}
      onChange={(first, last) => {
        onSaveRole(first, last);
      }}
      inputClass="w-full h-6"
      indicator="role"
    />
  );
};

export default function ProfileCard() {
  const { talentData, onUpdateTalent, id, updateAccount } =
    useContext(TalentContext);

  const onSaveLocation = async (location) => {
    const {
      full_address,
      location_id,
      city,
      state,
      state_code,
      country,
      zipcode,
      timezone_id,
      timezone_name
    } = location;

    await updateAccount.mutateAsync({
      full_address,
      location_id,
      city,
      state,
      state_code,
      country,
      zipcode,
      timezone_id,
      timezone_name
    });
  };

  return (
    <div className="flex w-full flex-row gap-4 py-2.5 text-sm font-medium text-kasmir">
      <div className="w-20 flex-none">
        <TalentPhotoWithUtilization data={talentData} />
      </div>
      <div className="flex min-w-0 flex-1 flex-col">
        <div className="mb-1 flex flex-row items-baseline gap-2.5">
          <div className="text-xl font-bold text-midnight">
            {talentData.fullname}
          </div>
          <div className="text-sm text-midnight">
            {talentData.pronouns ? `(${talentData.pronouns})` : ""}
          </div>
        </div>
        <Field
          name="Preferred name:"
          value={talentData.preferred_name}
          onChange={(v) => onUpdateTalent({ preferred_name: v })}
          stackLeft
          placeholder={talentData.first_name}
        />
        <CurrentRoleEdit title={talentData.current_title} id={id} />
        <AddressInput
          defaultFullAddress={talentData.full_address}
          onSave={(v) => onSaveLocation(v)}
          canDelete={false}
          placeholder="Set location"
        />
      </div>
    </div>
  );
}

import React from "react";
import clsx from "clsx";

const Button = ({
  children,
  secondary,
  danger,
  disabled,
  loading,
  cancel,
  noMinW,
  large,
  className = "",
  ...props
}) => (
  <button
    type={props.type || "button"}
    {...props}
    disabled={disabled || loading}
    className={clsx(
      "focus:outline-none group flex h-10",
      noMinW ? "" : "min-w-36",
      "rounded-lg",
      className
    )}
  >
    <span
      className={clsx(
        "focus:outline-none my-auto inline-flex items-center justify-center whitespace-nowrap rounded-lg group-focus:ring",
        large
          ? "h-12 text-lg font-bold"
          : "h-9.5 text-xs sm:text-sm sm:font-bold",
        noMinW ? "px-2.5" : "min-w-36 px-11.5",
        disabled || loading
          ? "cursor-not-allowed bg-neutral-light text-white"
          : secondary
            ? "border border-electric-indigo bg-white text-electric-indigo hover:border-electric-indigo-lighter hover:text-electric-indigo-lighter"
            : cancel
              ? "bg-alice-blue text-electric-indigo hover:bg-alice-blue-lighter"
              : "bg-electric-indigo text-white hover:bg-electric-indigo-lighter",
        className
      )}
      tabIndex="-1"
    >
      {children}
    </span>
  </button>
);

export default Button;

import { Field, TalentDetailSection } from "./components";
import { FieldMappingToolTip } from "components_v2";
import $ from "strings/talent";
import { TalentContext } from "../TalentDataContext";
import { useContext } from "react";
import { postTalentNote } from "utils/adminApi";
import { getAdminUser } from "utils/localStorageService";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { moveDays, toFormat } from "utils/date";
import { InfoIcon, StarIcon } from "components/icons";
import { rateFlexibilityOptions } from "../../List/Page";
import DollarIcon from "components/icons/Dollar";

const SENIORITY_OPTIONS = [
  { label: $.not_set_option, value: null },
  { label: "Associate", value: "Associate" },
  { value: "Manager", label: "Manager" },
  { value: "Senior Manager", label: "Senior Manager" },
  { value: "Director", label: "Director" },
  { value: "VP", label: "VP" },
  { value: "CMO", label: "CMO" }
];

const grades = ["A+", "A", "A-", "B+", "B", "B-", "C"];
const ungradedOption = [{ label: "Ungraded", value: null }];
const GRADE_OPTIONS = ungradedOption.concat(
  grades.map((g, i) => ({
    label: g,
    value: 8 - i
  }))
);

const SPECIALITIES_OPTIONS = [
  { label: $.performance_marketing_option, value: "performance_marketing" },
  { label: $.lifecycle_crm_option, value: "lifecycle_crm" },
  { label: $.content_marketing_option, value: "content_marketing" },
  { label: $.product_marketing_option, value: "product_marketing" },
  { label: $.analytics_option, value: "analytics" },
  { label: $.marketing_ops_option, value: "marketing_ops" },
  { label: $.affiliate_option, value: "affiliate" },
  { label: $.ecommerce_option, value: "ecommerce" },
  { label: $.design_option, value: "design" },
  { label: $.leadership_option, value: "leadership" },
  { label: $.brand_marketing_option, value: "brand_marketing" }
];

export default function Basics() {
  const { talentData, onUpdateTalent, id, loading } = useContext(TalentContext);
  const queryClient = useQueryClient();
  const adminUser = getAdminUser();

  const addTalentNote = useMutation({
    mutationFn: (newNote) => {
      postTalentNote(newNote);
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: ["talent_notes", id] });
    },
    onError: (err) => console.error(err)
  });

  const onUpdateTalentGrade = async (grade) => {
    let addExcludedNote = false;
    const toSend = {
      talent_grade: grade
    };
    if (grade === 2 && talentData.excluded === false) {
      toSend.excluded = true;
      toSend.excluded_reason = "skills_experience_misalignment";
      addExcludedNote = true;
    }

    await onUpdateTalent(toSend);

    if (addExcludedNote) {
      await addTalentNote.mutateAsync({
        body: "Excluded Reason: Skills or Experience Misalignment; Talent Grade C",
        talent: id,
        note_tag: "excluded",
        created_by_id: adminUser.id,
        created_by: adminUser.first_name + " " + adminUser.last_name
      });
    }
  };

  return (
    <TalentDetailSection title="Basics">
      <Field
        name="Talent Grade"
        placeholder="Select Grade"
        options={GRADE_OPTIONS}
        value={talentData.talent_grade}
        onChange={onUpdateTalentGrade}
        loading={loading}
      />
      <Field
        name="Seniority"
        placeholder="Select Seniority"
        value={
          talentData.seniority // evidently this used to be able be an array?
            ? Array.isArray(talentData.seniority)
              ? talentData.seniority[0]
              : talentData.seniority.split("|")?.[0]
            : null
        }
        options={SENIORITY_OPTIONS}
        onChange={(v) => onUpdateTalent({ seniority: v })}
        loading={loading}
      />
      <Field
        name="Avg / Ideal Pay Rate"
        placeholder="Select Pay Rate"
        isDollar
        tooltipIcon={<DollarIcon size="16" />}
        loading={loading}
        tooltipContent={
          <FieldMappingToolTip
            values={{
              "Rate flexibility":
                talentData.rate_flexibility
                  ?.map(
                    (r) =>
                      rateFlexibilityOptions.find((o) => o.value === r).label
                  )
                  ?.join(", ") ?? "N/A"
            }}
          />
        }
        value={talentData.minimum_rate}
        onChange={(v) => onUpdateTalent({ minimum_rate: v })}
        customWrapper={(children) => {
          return (
            <div className="flex flex-row gap-1">
              <div>
                {talentData.average_pay_rate
                  ? `$${talentData.average_pay_rate.toFixed(0)}`
                  : "N/A"}
              </div>
              {"/"}
              {children}
            </div>
          );
        }}
      />
      <Field
        name="Utilization / Capacity"
        placeholder="Select Capacity"
        type="number"
        value={talentData.availability_capacity}
        loading={loading}
        customWrapper={(children) => {
          return (
            <div className="flex flex-row gap-1">
              <div className="whitespace-nowrap">{`${talentData.availability_utilization} / `}</div>
              {children}
            </div>
          );
        }}
        onChange={(v) => {
          onUpdateTalent({
            availability_capacity: v,
            availability_expiration_date: moveDays(
              new Date().toISOString().slice(0, -1),
              60
            )
          });
        }}
        tooltipIcon={<InfoIcon />}
        tooltipContent={
          <FieldMappingToolTip
            values={{
              Availability:
                (talentData.availability_capacity || 0) -
                (talentData.availability_utilization || 0),
              "Updated On":
                talentData.availability_updated_at &&
                toFormat(
                  new Date(talentData.availability_updated_at),
                  "MM/dd/yyyy h:mma"
                ),
              "Updated By": talentData.availability_updater
            }}
          />
        }
      />
      <Field
        name="Capacity Valid Until"
        placeholder="Select Date"
        isDate
        displayFormat="MMMM d, yyyy"
        loading={loading}
        onChange={(v) =>
          onUpdateTalent({
            availability_expiration_date: toFormat(v, "yyyy-MM-dd'T'hh:mm")
          })
        }
        value={
          talentData.availability_expiration_date
            ? new Date(talentData.availability_expiration_date)
            : null
        }
      />
      <Field
        name="Years of Experience"
        placeholder="Select Years"
        value={talentData.years_of_experience}
        onChange={(v) => onUpdateTalent({ years_of_experience: v })}
        isNumber
        loading={loading}
      />
      <Field
        name="Specialties"
        placeholder="Select Specialties"
        isMulti
        options={SPECIALITIES_OPTIONS}
        value={talentData.specialties}
        onChange={(v) => onUpdateTalent({ specialties: v })}
        loading={loading}
        forceColumnLayout
      />
      <Field
        name="Client Rating"
        value={
          talentData.average_rating
            ? talentData.average_rating.toFixed(2)
            : undefined
        }
        placeholder="N/A"
        customWrapper={(children) => {
          return (
            <div className="flex flex-row items-center gap-1">
              <StarIcon isSelected size="16" />
              {children}
            </div>
          );
        }}
        loading={loading}
        editable={false}
      />
    </TalentDetailSection>
  );
}

import React, { useState, useEffect, useMemo } from "react";
import clsx from "clsx";
import { NavLink, Switch, Route, useParams } from "react-router-dom";
import Overview from "./Overview/index";
import Notes from "./Notes/index";
import Attachments from "./Attachments/index";
import Experiences from "./Experiences";
import Projects from "./Projects";
import {
  getAccountV2,
  getTalentCreatedProjectsV2,
  getTalentQualificationsV2
} from "utils/adminApi";
import Section from "components/Section";
import { getTalentProfileCompletion } from "utils/misc";
import useCachedFetch from "hooks/useCachedFetch";
import DeleteTalentModal from "../DeleteTalentModal";
import Clockify from "../Clockify";
import InvitationForm from "../InvitationForm";
import UpperNavBar, { TalentUpperNavBar } from "./components/NavBar";
import TalentDetailPane from "./DetailPane";
import { TalentDataProvider } from "./TalentDataContext";
import AddNoteModal from "./Notes/AddNoteModal";
import EditNoteModal from "./Notes/EditNoteModal";
import Skills from "./Skills";

const Page = () => {
  const [modal, setModal] = useState();
  const { id } = useParams();

  const { data, setData } = useCachedFetch("admin_talent_v2", getAccountV2, id);

  const { data: experiencesData } = useCachedFetch(
    "admin_talent_experiences",
    getTalentCreatedProjectsV2,
    id
  );

  const talentQualifications = useCachedFetch(
    "admin_talent_qualifications_v2",
    getTalentQualificationsV2,
    id,
    { page_size: 9999 }
  );

  const talentProfileCompletion = useMemo(() => {
    if (!data || !experiencesData || !talentQualifications) return;
    let talentData = {
      ...data,
      talent_created_projects: experiencesData?.results,
      qualifications: talentQualifications?.data?.results
    };
    return getTalentProfileCompletion(talentData);
  }, [data, experiencesData, talentQualifications]);

  useEffect(() => {
    if (!data) return;
    document.title = "Pipeline - " + data?.fullname;
  }, [data]);

  const CenterNavBar = () => {
    const NavItem = ({ to, label }) => {
      return (
        <NavLink
          exact
          to={to}
          className={clsx(
            "flex w-full max-w-25 cursor-pointer items-center rounded-md py-2 text-center text-sm font-semibold text-midnight"
          )}
          activeClassName="bg-white"
        >
          <div className="w-full text-center">{label}</div>
        </NavLink>
      );
    };

    const items = [
      {
        label: "Overview",
        to: `/admin/talent/${id}/overview`
      },
      {
        label: "Notes",
        to: `/admin/talent/${id}/notes`
      },
      {
        label: "Skills",
        to: `/admin/talent/${id}/skills`
      },
      {
        label: "Experiences",
        to: `/admin/talent/${id}/experiences`
      },
      {
        label: "Projects",
        to: `/admin/talent/${id}/projects`
      },
      {
        label: "Attachments",
        to: `/admin/talent/${id}/attachments`
      }
    ];
    return (
      <div className="flex w-full flex-row rounded-md bg-lightest-grey p-1">
        {items.map((menuItem, i) => (
          <NavItem key={i} label={menuItem.label} to={menuItem.to} />
        ))}
      </div>
    );
  };

  return (
    <TalentDataProvider talent_id={id}>
      <Section
        id="admin_talent"
        className="flex h-full flex-col gap-2.5 rounded-2xl bg-alice-blue p-2.5"
      >
        <Switch>
          <Route path="/admin/talent/:id/projects">
            <div className="flex min-h-0 flex-1 flex-col gap-2.5">
              <UpperNavBar
                to={`/admin/talent/${id}`}
                label="Back to Talent Overview"
              />
              <div className="min-h-0 flex-1">
                <Projects data={data} />
              </div>
            </div>
          </Route>
          <Route path="*">
            <div className="flex min-h-0 flex-1 flex-col gap-2.5">
              <TalentUpperNavBar
                data={data}
                talentProfileCompletion={talentProfileCompletion}
              />
              <div className="flex min-h-0 flex-1">
                <div className="flex h-full w-full flex-row gap-2.5 rounded-xl">
                  <div className="min-w-3xs flex w-73 max-w-xs flex-1">
                    <TalentDetailPane data={data} setData={setData} user={id} />
                  </div>
                  <div className="min-w-3xl flex h-full flex-1 flex-col gap-2.5 rounded-xl bg-white p-2.5">
                    <CenterNavBar />
                    <div
                      className="scrollbar flex-1 overflow-y-auto rounded-xl"
                      id="talent-detail-tab"
                    >
                      <Switch>
                        <Route exact path="/admin/talent/:id">
                          <Overview
                            data={data}
                            setData={setData}
                            talentQualifications={talentQualifications}
                          />
                        </Route>
                        <Route path="/admin/talent/:id/overview">
                          <Overview
                            data={data}
                            setData={setData}
                            talentQualifications={talentQualifications}
                          />
                        </Route>
                        <Route path="/admin/talent/:id/notes">
                          <Notes setModal={setModal} />
                        </Route>
                        <Route path="/admin/talent/:id/attachments">
                          <Attachments data={data} setData={setData} />
                        </Route>
                        <Route path="/admin/talent/:id/experiences">
                          <Experiences data={experiencesData} />
                        </Route>
                        <Route path="/admin/talent/:id/skills">
                          <Skills setModal={setModal} />
                        </Route>
                        <Route path="/admin/talent/:id/clockify">
                          <Clockify data={data} setData={setData} />
                        </Route>
                      </Switch>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Route>
        </Switch>
        {modal?.type === "delete" && (
          <DeleteTalentModal data={data} onClose={setModal} />
        )}
        {modal?.type === "invite" && (
          <InvitationForm data={data} setData={setData} onClose={setModal} />
        )}
        {modal?.type === "add_note" && (
          <AddNoteModal onClose={() => setModal()} />
        )}
        {modal?.type === "edit_note" && (
          <EditNoteModal {...modal.props} onClose={() => setModal()} />
        )}
      </Section>
    </TalentDataProvider>
  );
};

export default Page;

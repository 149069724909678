import { useState, useEffect } from "react";

/**
 * Custom hook that returns the current Tailwind CSS breakpoint
 * based on window width.
 *
 * Breakpoints:
 * - xs: < 640px (default Tailwind doesn't have xs, but added for convenience)
 * - sm: 640px
 * - md: 768px
 * - lg: 1024px
 * - xl: 1280px
 * - 2xl: 1536px
 *
 * @returns {Object} Object containing:
 *   - breakpoint: Current breakpoint name (xs, sm, md, lg, xl, 2xl)
 *   - isMobile: Boolean indicating if current breakpoint is xs or sm
 *   - isTablet: Boolean indicating if current breakpoint is md
 *   - isDesktop: Boolean indicating if current breakpoint is lg, xl, or 2xl
 *   - isAtLeast: Function to check if current breakpoint is at least the provided size
 *   - isAtMost: Function to check if current breakpoint is at most the provided size
 */
const useBreakpoint = () => {
  // Define breakpoint order for comparison functions
  const breakpointOrder = ["xs", "sm", "md", "lg", "xl", "2xl"];

  // Initialize with default breakpoint
  const [breakpoint, setBreakpoint] = useState("");

  useEffect(() => {
    // Set initial breakpoint on mount
    // Define breakpoint thresholds
    const breakpoints = {
      xs: 0,
      sm: 640,
      md: 768,
      lg: 1024,
      xl: 1280,
      "2xl": 1536
    };

    const handleResize = () => {
      const width = window.innerWidth;

      if (width >= breakpoints["2xl"]) {
        setBreakpoint("2xl");
      } else if (width >= breakpoints.xl) {
        setBreakpoint("xl");
      } else if (width >= breakpoints.lg) {
        setBreakpoint("lg");
      } else if (width >= breakpoints.md) {
        setBreakpoint("md");
      } else if (width >= breakpoints.sm) {
        setBreakpoint("sm");
      } else {
        setBreakpoint("xs");
      }
    };

    // Handle window resize with debounce to improve performance
    let timeoutId = null;
    const debouncedResize = () => {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(handleResize, 150);
    };

    window.addEventListener("resize", debouncedResize);

    // Set initial breakpoint
    handleResize();

    // Cleanup event listener on unmount
    return () => {
      window.removeEventListener("resize", debouncedResize);
      clearTimeout(timeoutId);
    };
  }, []);

  /**
   * Check if current breakpoint is at least the given size
   * @param {string} size - Breakpoint to compare with (xs, sm, md, lg, xl, 2xl)
   * @returns {boolean} - True if current breakpoint is equal to or larger than the given size
   */
  const isAtLeast = (size) => {
    if (!breakpoint || !breakpointOrder.includes(size)) return false;
    return breakpointOrder.indexOf(breakpoint) >= breakpointOrder.indexOf(size);
  };

  /**
   * Check if current breakpoint is at most the given size
   * @param {string} size - Breakpoint to compare with (xs, sm, md, lg, xl, 2xl)
   * @returns {boolean} - True if current breakpoint is equal to or smaller than the given size
   */
  const isAtMost = (size) => {
    if (!breakpoint || !breakpointOrder.includes(size)) return false;
    return breakpointOrder.indexOf(breakpoint) <= breakpointOrder.indexOf(size);
  };

  return {
    breakpoint,
    isMobile: ["xs", "sm"].includes(breakpoint),
    isTablet: breakpoint === "md",
    isDesktop: ["lg", "xl", "2xl"].includes(breakpoint),
    isAtLeast,
    isAtMost
  };
};

export default useBreakpoint;

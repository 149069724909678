import React from "react";
import experiences from "assets/experiences.svg";
import EmptyState from "../components/EmptyState";

const EmptyExperiences = () => {
  const description =
    "This talent hasn't shared their work experience yet. \
    Encourage them to update this section to highlight their professional \
    journey and achievements.";
  return (
    <EmptyState
      title="No experiences added yet"
      image={experiences}
      description={description}
    />
  );
};

export default EmptyExperiences;

import { useState, useEffect, useRef } from "react";
import { CheckboxMultiSelect } from "components_v2/CheckboxMultiSelect";
import { Dropdown } from "components/Form";
import DatePicker from "components/ListDatePicker";
import PhoneInput from "components/PhoneEditable";
import { Input } from "pages/talent/components";
import clsx from "clsx";
import { formatPhone } from "utils/str";

/**
 * A couple of the Edit field types had finicky handlers. This just ensures they
 * it defocus when there is an outside click.
 */
export const ElementWithClickOutsideHandler = ({
  onClickOutside,
  children
}) => {
  const wrapperRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        onClickOutside();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [onClickOutside]);

  return (
    <div ref={wrapperRef} className="w-full">
      {children}
    </div>
  );
};

/**
 * Given a certain type and value, render a field that allows editing that value.
 */
const EditField = ({
  options,
  isMulti,
  isNumber,
  isDate,
  isPhone,
  value,
  onChange,
  placeholder,
  setEditting,
  justifyLeft
}) => {
  const [localValue, setLocalValue] = useState(value);

  useEffect(() => {
    setLocalValue(value);
  }, [value]);

  if (options && isMulti) {
    return (
      <CheckboxMultiSelect
        options={options}
        onChange={onChange}
        onBlur={() => setEditting(false)}
        value={value}
      />
    );
  }

  if (options) {
    return (
      <ElementWithClickOutsideHandler onClickOutside={() => setEditting(false)}>
        <Dropdown
          options={options}
          value={value}
          customStyles={{
            menu: (provided) => ({
              ...provided,
              minWidth: "160px"
            })
          }}
          onChange={(e) => {
            if (e.value !== value) {
              onChange(e.value);
            }
            setEditting(false);
          }}
          className="text-sm"
        />
      </ElementWithClickOutsideHandler>
    );
  }

  if (isDate) {
    return (
      <DatePicker
        className={clsx(
          justifyLeft ? "text-left" : "text-right",
          "font-semibold"
        )}
        wrapperClassName="date-picker-w-full"
        placeholderText="Choose date"
        value={value}
        format="MMMM d, yyyy"
        monthly={false}
        onChange={onChange}
        onBlur={() => setEditting(false)}
      />
    );
  }

  if (isPhone) {
    return (
      <PhoneInput
        type="tel"
        height={6}
        inputClass="ml-2 text-electric-indigo"
        name="phone_number"
        text={formatPhone(value)}
        defaultValue={formatPhone(value)}
        onChange={onChange}
      />
    );
  }

  return (
    <div className="flex w-full">
      <Input
        autoFocus
        value={localValue ?? ""}
        onChange={(e) => setLocalValue(e.target.value)}
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            e.target.blur();
          }
        }}
        onBlur={() => {
          if (localValue !== value) {
            onChange(localValue);
          }
          setEditting(false);
        }}
        placeholder={placeholder}
        type={isNumber ? "number" : "text"}
        height={6}
      />
    </div>
  );
};

/**
 * An EditableField is a certain value that displays as text,
 * but then when clicked on becomes editable.
 *
 * This EditableField handles the display and the toggling between editting
 * and non-editting state. The EditField component above is the one that is
 * always in edit.
 */
export const EditableField = ({
  formatValueForDisplay,
  placeholder,
  editable = true,
  options,
  value,
  onChange,
  displayTextColor = "text-midnight",
  isBoolean,
  isNumber,
  isMulti,
  isDate,
  isPhone,
  justifyLeft
}) => {
  const [editting, setEditting] = useState(false);

  const displayValue = formatValueForDisplay(value);
  const displayContent = (
    <div
      onClick={() => {
        if (!editting && editable) setEditting(true);
      }}
      onBlur={() => setEditting(false)}
    >
      {displayValue ? (
        <div
          className={clsx(
            "font-semibold",
            displayTextColor,
            justifyLeft ? "text-left" : "text-right"
          )}
        >
          {displayValue}
        </div>
      ) : (
        placeholder && (
          <div
            className={clsx(
              "text-grey",
              justifyLeft ? "text-left" : "text-right"
            )}
          >
            {placeholder}
          </div>
        )
      )}
    </div>
  );

  if (editting) {
    return (
      <EditField
        options={options}
        placeholder={placeholder}
        value={value}
        isDate={isDate}
        isBoolean={isBoolean}
        isMulti={isMulti}
        isNumber={isNumber}
        isPhone={isPhone}
        onChange={onChange}
        setEditting={setEditting}
        justifyLeft={justifyLeft}
      />
    );
  }

  return displayContent;
};

import React from "react";

const DollarIcon = ({ fill = "#0E1539", size = "14" }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 14 14"
      fill="none"
    >
      <g clipPath="url(#clip0_1022_6225)">
        <path
          d="M12.2754 9.42595C12.0747 9.32079 11.8268 9.39828 11.7218 9.59896C10.8142 11.3315 9.03652 12.4078 7.08246 12.4078C4.2001 12.4078 1.8546 10.0656 1.84897 7.18453L2.17811 7.51367C2.2582 7.59376 2.36317 7.63382 2.46812 7.63382C2.57306 7.63382 2.67806 7.59379 2.75812 7.51367C2.9183 7.35349 2.9183 7.09381 2.75812 6.93363L1.72915 5.90468C1.569 5.7445 1.30929 5.7445 1.14911 5.90468L0.120135 6.93363C-0.0400449 7.09378 -0.0400449 7.35349 0.120135 7.51367C0.280287 7.67385 0.539998 7.67382 0.700178 7.51367L1.02863 7.18524C1.0315 8.79814 1.66085 10.3141 2.80165 11.4549C3.94509 12.5983 5.46537 13.2281 7.08246 13.2281C9.34269 13.2281 11.3988 11.9833 12.4484 9.97961C12.5535 9.77893 12.4761 9.53106 12.2754 9.42595Z"
          fill={fill}
        />
        <path
          d="M13.8798 6.4865C13.7197 6.32632 13.46 6.32632 13.2998 6.4865L12.9713 6.81495C12.9684 5.20203 12.3391 3.68609 11.1983 2.54525C10.0549 1.40182 8.53458 0.772095 6.9175 0.772095C4.65727 0.772095 2.60118 2.01684 1.55157 4.02056C1.44643 4.22121 1.52389 4.46911 1.72457 4.57422C1.92516 4.67933 2.17306 4.60186 2.2782 4.40121C3.18574 2.66868 4.96344 1.59241 6.9175 1.59241C9.79981 1.59241 12.1453 3.93456 12.151 6.81558L11.8219 6.48647C11.6617 6.32629 11.402 6.32629 11.2418 6.48647C11.0817 6.64665 11.0817 6.90634 11.2418 7.06652L12.2708 8.09549C12.3509 8.17558 12.4559 8.21564 12.5608 8.21564C12.6658 8.21564 12.7708 8.17561 12.8508 8.09549L13.8798 7.06652C14.04 6.90634 14.04 6.64665 13.8798 6.4865Z"
          fill={fill}
        />
        <path
          d="M7.56086 6.52904C7.18981 6.39787 6.77946 6.23947 6.54934 6.05898C6.47698 6.00227 6.44573 5.87238 6.47146 5.7358C6.48429 5.66766 6.54428 5.43991 6.77725 5.36972C7.1597 5.25455 7.42204 5.40732 7.51706 5.47836C7.69851 5.61396 7.95549 5.57685 8.09114 5.39543C8.22679 5.214 8.18966 4.95697 8.00823 4.82132C7.92216 4.75695 7.7065 4.61586 7.39735 4.54733V4.38037C7.39735 4.15386 7.21371 3.97021 6.98719 3.97021C6.76068 3.97021 6.57704 4.15386 6.57704 4.38037V4.57405C6.56489 4.57744 6.55286 4.58055 6.54064 4.58425C6.09144 4.71957 5.75604 5.1026 5.66534 5.58385C5.58186 6.02682 5.72659 6.4562 6.04307 6.70443C6.30382 6.90893 6.66434 7.08218 7.28748 7.30246C7.70234 7.44911 7.69334 7.78489 7.67021 7.92243C7.62334 8.2013 7.38873 8.50269 6.98446 8.50539C6.588 8.50818 6.46446 8.48841 6.15991 8.28919C5.97036 8.16516 5.71614 8.21828 5.59211 8.40786C5.46811 8.59743 5.52124 8.85162 5.71081 8.97562C6.03929 9.19052 6.28167 9.27769 6.57704 9.30949V9.49696C6.57704 9.72348 6.76068 9.90712 6.98719 9.90712C7.21371 9.90712 7.39735 9.72348 7.39735 9.49696V9.27009C7.62332 9.20788 7.82924 9.09424 8.00523 8.93253C8.25463 8.70339 8.42293 8.39296 8.47918 8.05841C8.59355 7.37826 8.2245 6.76362 7.56086 6.52904Z"
          fill={fill}
        />
      </g>
      <defs>
        <clipPath id="clip0_1022_6225">
          <rect width="14" height="14" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default DollarIcon;
